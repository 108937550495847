import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change, Info } from '@yukawa/chain-base-angular-domain';
import { Group } from '@yukawa/chain-main-angular-core';
import { PlainObject, StringKeys } from 'simplytyped';
import { IProfile, Profile, ProfileRoles, UserInfo, UserService } from '@hemro/lib/profile';
import { Company } from '@hemro/lib/domain';


export class CompanyManagerTableEntry extends QueryTableEntry<Profile, IProfile>
{
    static viewConfig: IProfile = {
        profileId: '',
        user     : {
            userId   : '',
            username : '',
            change   : {
                date : new Date(),
                user : '',
                notes: '',
            },
            firstName: '',
            lastName : '',
            groups: [],
            created  : {
                date : new Date(),
                user : '',
                notes: '',
            },
        },
        company  : {
            companyId: 0,
            info     : {
                id       : '',
                name     : '',
                desc     : '',
                shortName: '',
            },
            change   : {
                date : new Date(),
                user : '',
                notes: '',
            },
            created  : {
                date : new Date(),
                user : '',
                notes: '',
            },
        },
        account  : {
            roleContexts: [
                {
                    orgId: '',
                    roles: [''],
                },
            ],
        },
        groups   : [
            {
                name: 'USERS',
            },
        ],
    };

    public constructor(
        profile: IProfile = CompanyManagerTableEntry.viewConfig,
    )
    {
        super(
            profile instanceof Profile ? profile : new Profile({
                ...profile,
                profileId: profile.profileId || '',
            }),
            profile?.profileId,
            profile?.user?.username,
        );
    }

    public get viewConfig(): IProfile
    {
        return CompanyManagerTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'MANAGER.PROFILE.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = IProfile>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value   = item?.[key];
        let options = new Array<ISelectOption>();
        let label   = '';

        detail.entityName = 'User';

        switch (key) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    group: key,
                    ...detail,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    group: key,
                    ...detail,
                });
                return;
            case 'profileId':
                type            = 'text';
                detail.showInDetails = false;
                detail.showInTable   = false;
                break;
            case 'user':
                this.mapDetails<UserInfo>(details, value, 'userId', {
                    ...detail,
                    type         : 'text',
                    group        : key,
                    showInTable  : false,
                    showInDetails: false,
                });
                this.mapDetails<UserInfo>(details, value, 'username', {
                    ...detail,
                    type      : 'text',
                    group     : key,
                    tableGroup: false,
                });
                this.mapDetails<UserInfo>(details, value, 'firstName', {
                    ...detail,
                    type      : 'text',
                    group     : key,
                    canEdit   : true,
                    tableGroup: false,
                });
                this.mapDetails<UserInfo>(details, value, 'lastName', {
                    ...detail,
                    type      : 'text',
                    group     : key,
                    canEdit   : true,
                    tableGroup: false,
                });
                this.mapDetails<UserInfo>(details, value, 'change', {
                    ...detail,
                    group      : key + '.change',
                    showInTable: true,
                });
                this.mapDetails<UserInfo>(details, value, 'created', {
                    ...detail,
                    group      : key + '.created',
                    showInTable: false,
                });
                return;
            case 'company':
                type                 = 'select';
                detail.canEdit  = false;
                detail.required = false;
                detail.showInTable   = false;
                detail.showInDetails = false;
                detail.compareWith   = (o1: Company | number, o2: Company | number): boolean =>
                {
                    const id1 = typeof o1 === 'number' ? o1 : o1?.companyId;
                    const id2 = typeof o2 === 'number' ? o2 : o2?.companyId;

                    return id1 === id2;
                };
                label                = this.formatKey(key + '.name');
                break;
            case 'info':
                this.mapDetails<Info>(details, value, 'name', {
                    ...detail,
                    type        : 'text',
                    tableGroup  : true,
                    group       : 'company.' + key,
                    groupByField: true,
                });
                this.mapDetails<Info>(details, value, 'shortName', {
                    ...detail,
                    type : 'text',
                    tableGroup: true,
                    group: 'company.' + key,
                });
                this.mapDetails<Info>(details, value, 'desc', {
                    ...detail,
                    type : 'text',
                    tableGroup: true,
                    group: 'company.' + key,
                });
                return;
            case 'groups':
                type = 'multiselect';
                //detail.required    = true;
                detail.sortable    = false;
                //detail.validators  = [Validators.minLength(1)];
                detail.compareWith = (o1: Group | string, o2: Group | string): boolean =>
                {
                    const id1 = typeof o1 === 'string' ? o1 : o1?.name;
                    const id2 = typeof o2 === 'string' ? o2 : o2?.name;

                    return id1 === id2;
                };
                if (!value) {
                    value = (item as Profile).user.groups;
                }
                detail.showInTable = false;
                detail.canEdit = AppInjector.get(UserService).hasRole(...ProfileRoles.inviteUsers);
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: label || this.formatKey(level),
            value,
            options,
        }));
    }
}
