<button mat-icon-button [matMenuTriggerFor]="userActions" [matTooltip]="'HELP.TITLE' | transloco">
    <span class="relative">
        <mat-icon [svgIcon]="'heroicons_outline:question-mark-circle'"></mat-icon>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <a mat-menu-item [href]="faqsUrl" target="_blank">
        <mat-icon [svgIcon]="'heroicons_outline:faqs'"></mat-icon>
        <span transloco="HELP.FAQS"></span>
    </a>
    <mat-divider class="my-2"></mat-divider>
    <a mat-menu-item [href]="userGuideUrl" target="_blank">
        <mat-icon [svgIcon]="'heroicons_outline:file-alt'"></mat-icon>
        <span transloco="HELP.USER_GUIDE"></span>
    </a>
</mat-menu>
