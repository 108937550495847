import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Observable } from 'rxjs';
import { CloudRecipe } from './cloud-recipe.entity';
import { CloudRecipeFilter, CloudRecipeStatus } from '@hemro/lib/domain';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { DeviceFilter } from '@hemro/lib/domain';
import { Device } from '../device';

@Injectable({ providedIn: 'root' })
export class CloudRecipeService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, `${configService.getBaseUrl()}/admin-service/cloud-recipe`);
    }

    queryRecipes(filter: CloudRecipeFilter): Observable<QueryResult<CloudRecipe>>
    {
        return super.query<CloudRecipe>(this.formatServiceUrl('/query'), filter);
    }

    merge(recipe: CloudRecipe): Observable<CloudRecipe>
    {
        return this.http.post<CloudRecipe>(this.formatServiceUrl('/merge'), recipe);
    }

    create(recipe: CloudRecipe): Observable<CloudRecipe>
    {
        return this.http.post<CloudRecipe>(this.formatServiceUrl('/create'), recipe);
    }

    changeStatus(recipe: CloudRecipe, status: CloudRecipeStatus): Observable<CloudRecipe>
    {
        return this.http.post<CloudRecipe>(`${this.formatServiceUrl('/status')}?recipeId=${recipe.cloudRecipeId}&status=${status}`, {});
    }

    queryDevice(filter: DeviceFilter): Observable<QueryResult<Device>>
    {
        return super.query<Device>(this.formatServiceUrl('/query-devices'), filter);
    }
}
