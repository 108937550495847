import { Injectable } from '@angular/core';
import {
    APP_ENVIRONMENT_TOKEN,
    AppInjector,
    ConfigService,
    EventArgs,
    EventDelegate,
    ObjectModel,
} from '@yukawa/chain-base-angular-client';


export enum DataDashboardMessageTypes
{
    error   = 'error',
    message = 'message',
    token   = 'token'
}

export class DataDashboardMessageEventArgs<TType extends DataDashboardMessageTypes, TData = string> extends EventArgs
{
    constructor(
        public readonly eventType: TType,
        public readonly data?: TData,
    )
    {
        super();
    }
}

export type DataDashboardError = DataDashboardMessageEventArgs<DataDashboardMessageTypes.error>;
export type DataDashboardMessage = DataDashboardMessageEventArgs<DataDashboardMessageTypes.message>;
export type DataDashboardToken = DataDashboardMessageEventArgs<DataDashboardMessageTypes.token>;
export type DataDashboardMessageEvent = DataDashboardError | DataDashboardMessage | DataDashboardToken;

interface DataDashboardMessageEventData
{
    error?: string;
    message?: string;
    token?: string;
    type: DataDashboardMessageTypes;
}

@Injectable({ providedIn: 'root' })
export class DataDashboardMessageService extends ObjectModel
{
    readonly messageReceived = new EventDelegate<this, DataDashboardMessageEvent>(this);

    #listeningToWindowMessages = false;

    constructor(
        private readonly _configService: ConfigService,
    )
    {
        super();
    }

    get listenToMessages(): boolean
    {
        return this.#listeningToWindowMessages;
    }

    set listenToMessages(value: boolean)
    {
        if (value) {
            window.addEventListener('message', this.onWindowMessage);
            this.#listeningToWindowMessages = true;
        }
        else {
            window.removeEventListener('message', this.onWindowMessage);
            this.#listeningToWindowMessages = false;
        }
    }

    public sendMessage(
        contentWindow: WindowProxy,
        type: DataDashboardMessageTypes,
        data: string,
        targetOrigin: string = this._configService.getValue('dataDashboardUrl'),
    ): void
    {
        try {
            const message = {
                type,
            } as DataDashboardMessageEventData;
            message[type] = data;

            if (!AppInjector.get(APP_ENVIRONMENT_TOKEN).production) {
                console.log('Sending message to data dashboard', message);
            }
            console.debug('sendMessage:targetOrigin', targetOrigin);
            contentWindow.postMessage(
                message,
                new URL(targetOrigin).origin,
            );
        }
        catch (e) {
            console.error('Error sending message', e);
        }
    }

    protected onMessageReceived(args: DataDashboardMessageEvent): void
    {
        this.messageReceived.invoke(args);
    }

    private onWindowMessage = (event: MessageEvent): void =>
    {
        console.debug('DataDashboardMessage received', event);
        if (event.origin !== new URL(this._configService.getValue('dataDashboardUrl')).origin) {
            (AppInjector.get(APP_ENVIRONMENT_TOKEN).production ? console.log : console.warn)
            ('Invalid origin', event.origin);
            return;
        }
        
        const data: DataDashboardMessageEventData = event.data;

        let eventData: any;

        switch (data.type) {
            case DataDashboardMessageTypes.error:
                eventData = data.error;
                break;
            case DataDashboardMessageTypes.message:
                eventData = data.message;
                break;
            case DataDashboardMessageTypes.token:
                eventData = data.token;
                break;
            default:
                console.warn('Unknown message type', data.type);
                return;
        }
        if (!AppInjector.get(APP_ENVIRONMENT_TOKEN).production) {
            console.debug('Data dashboard message received', {
                origin: event.origin,
                type  : data.type,
                data  : data,
            });
        }
        this.onMessageReceived(new DataDashboardMessageEventArgs(
            data.type,
            eventData,
        ));
    };
}
