import { Injectable } from '@angular/core';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource } from '@yukawa/chain-base-angular-comp/query-table';
import { lastValueFrom } from 'rxjs';
import { CloudRecipeService } from './cloud-recipe.service';
import { CloudRecipeDeviceTableEntry } from './cloud-recipe-device-table-entry.model';
import { DeviceFilter } from '@hemro/lib/domain';
import { IDevice } from '../device';

@Injectable({
    providedIn: 'root',
})
export class CloudRecipeDeviceDatasource extends QueryTableDatasource<IDevice, IDevice, CloudRecipeDeviceTableEntry, DeviceFilter> {

    constructor() {
        super(CloudRecipeDeviceTableEntry);
    }

    public async read(filter?: DeviceFilter): Promise<Array<IDevice>> {
        this.query = await lastValueFrom(AppInjector.get(CloudRecipeService).queryDevice({
            ...this.defaultFilter,
            ...filter,
            loadStore: true
        }));
        return this.query.items.map(item => ({
            ...item,
            name: item.name || ' '
        } as IDevice));
    }

    public async create(entry: CloudRecipeDeviceTableEntry): Promise<CloudRecipeDeviceTableEntry> {
        throw new Error('Method not implemented.');
    }

    public update(entry: CloudRecipeDeviceTableEntry, entity?: Partial<IDevice> | undefined): Promise<CloudRecipeDeviceTableEntry> {
        throw new Error('Method not implemented.');
    }

    public delete(entry: CloudRecipeDeviceTableEntry): Promise<void> {
        throw new Error('Method not implemented.');
    }

    public getByID(id: string | number): Promise<CloudRecipeDeviceTableEntry> {
        throw new Error('Method not implemented.');
    }
}
