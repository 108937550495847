export interface GrinderModel {
    name: string;
    value: string;
}

export const DEFAULT_MODELS: GrinderModel[] = [
    {
        value: 'E65WGbS',
        name: 'E65W GbS',
    },
    {
        value: 'E65TGbS',
        name: 'E65T GbS',
    },
    {
        value: 'E80WGbS',
        name: 'E80W GbS',
    },
    {
        value: 'E80TGbS',
        name: 'E80T GbS',
    },
];
