import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { fadeOut } from '@hemro/lib/animations';
import { lastValueFrom, tap } from 'rxjs';


@Component({
    selector   : 'lib-video-background',
    templateUrl: './video-background.component.html',
    styleUrls  : ['./video-background.component.scss'],
    animations : [fadeOut],
})
export class VideoBackgroundComponent implements OnInit
{
    @ViewChild('video')
    public readonly video!: ElementRef<HTMLVideoElement>;

    @Output()
    loaded = new EventEmitter<void>();

    @Output()
    played = new EventEmitter<void>();

    @Input()
    public autoplay: boolean = false;

    @Input()
    public loop: boolean = false;

    @Input()
    public poster!: string | undefined;

    @Input()
    public videoClass!: string | undefined;

    @Input()
    public videoUrl!: string;
    public videoSrc!: SafeValue;

    private _videoLoaded: boolean = false;

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _domSanitizer: DomSanitizer,
        private readonly _httpClient: HttpClient,
    )
    {
    }

    @HostBinding('class')
    get classList(): object
    {
        return {
            'video-container': true,
        };
    }

    public get paused(): boolean
    {
        return this.video.nativeElement.paused;
    }

    public get seekable(): TimeRanges
    {
        return this.video.nativeElement.seekable;
    }

    public get videoLoaded(): boolean
    {
        return this._videoLoaded;
    }

    public get videoPosition(): number
    {
        return this.video?.nativeElement.currentTime ?? 0;
    }

    public set videoPosition(value: number)
    {
        this.video.nativeElement.currentTime = value;
    }

    public async ngOnInit(): Promise<void>
    {
        try {
            // To enable video seeking in cordova WebView, we load whole video
            // as blob and generate a blob url for the video component.
            await lastValueFrom(this._httpClient.get(this.videoUrl, { responseType: 'blob' })
                .pipe(tap(async (data) =>
                {
                    this.video.nativeElement.loop = this.loop;

                    this.video.nativeElement.addEventListener('loadeddata', async () =>
                    {
                        if (this.video.nativeElement.readyState === 4) {
                            await this.pause();
                            this.loaded.emit();
                            if (this.autoplay) {
                                await this.play();
                                setTimeout(async () =>
                                {
                                    await this.pause();
                                    this.videoPosition = 0;
                                    this._videoLoaded  = true;
                                    this._changeDetector.detectChanges();
                                    setTimeout(async () =>
                                    {
                                        this.video.nativeElement.addEventListener('play', () =>
                                        {
                                            this.played.emit();
                                        });

                                        await this.play();
                                    }, 300);
                                }, 500);
                            }
                            else {
                                this.video.nativeElement.addEventListener('play', () =>
                                {
                                    this._videoLoaded = true;
                                    this.played.emit();
                                });
                            }
                        }
                    });

                    this.videoSrc = this._domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
                    this._changeDetector.detectChanges();
                    this.video.nativeElement.load();
                    this._changeDetector.detectChanges();
                })));
        }
        catch (error) {
            this._videoLoaded = false;
            console.error(error);
        }
    }

    public async play(): Promise<void>
    {
        return this.video.nativeElement.play();
    }

    public async pause(): Promise<void>
    {
        return this.video.nativeElement.pause();
    }

}
