import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CompanyFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { CompanyDatasource } from './company-datasource.service';
import { CompanyTableEntry } from './company-table-entry.model';
import { Company } from './company.model';


export class CompanyTableStore extends QueryTableStore<Company, Company, CompanyTableEntry, CompanyFilter, CompanyDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: CompanyFilter)
    {
        super(paginator, sort, tableFilter, CompanyTableEntry, AppInjector.get(CompanyDatasource));
    }
}
