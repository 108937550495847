import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Observable } from 'rxjs';
import { RecipeThreshold } from './recipe-threshold.model';


@Injectable({ providedIn: 'root' })
export class RecipeThresholdService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, `${configService.getBaseUrl()}/admin-service/recipe-threshold`);
    }

    loadAll(): Observable<RecipeThreshold>
    {
        return this.http.get<RecipeThreshold>(`${this.formatServiceUrl()}/`);
    }

    put(recipeThreshold: RecipeThreshold): Observable<RecipeThreshold>
    {
        return this.http.post<RecipeThreshold>(this.formatServiceUrl('/put'), recipeThreshold);
    }
}
