import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class EventService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, `${configService.getBaseUrl()}/admin-service/event`);
    }

    uploadErrorTranslation(file: File): Observable<any>
    {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<any>(`${this.formatServiceUrl()}/error-translation/upload`, formData);
    }
}
