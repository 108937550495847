<!-- Loading bar -->
<hemro-loading-bar></hemro-loading-bar>

<div class="flex flex-auto justify-center w-full sm:p-4 md:p-8 bg-gray-200 dark:bg-card">

    <!-- Navigation -->
    <ng-container *ngIf="isScreenSmall">
        <hemro-vertical-navigation
            class="dark bg-gray-900 print:hidden"
            [mode]="'over'"
            [name]="'mainNavigation'"
            [navigation]="navigation.default"
            [opened]="false">
            <!-- Navigation header hook -->
            <ng-container hemroVerticalNavigationContentHeader>
                <!-- Logo -->
                <div class="flex items-center h-20 pt-6 px-8">
                    <mat-icon [svgIcon]="'hemro:logo'" class="icon-size-24"></mat-icon>
                </div>
            </ng-container>
        </hemro-vertical-navigation>
    </ng-container>

    <!-- Wrapper -->
    <div
        class="flex flex-col items-center flex-auto min-w-0 max-w-360 sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden">

        <!-- Header -->
        <div
            class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden">
            <ng-container *ngIf="!isScreenSmall">
                <!-- Logo -->
                <div class="flex items-center mx-2 lg:mr-8">
                    <div class="hidden lg:flex">
                        <!-- Light version -->
                        <mat-icon [svgIcon]="'hemro:logo'" class="dark:hidden icon-size-24"></mat-icon>
                        <!-- Dark version -->
                        <mat-icon [svgIcon]="'hemro:logo'" class="hidden dark:flex icon-size-24"></mat-icon>
                    </div>
                    <!-- Small version -->
                    <mat-icon [svgIcon]="'hemro:icon'" class="flex lg:hidden w-8"></mat-icon>
                </div>
                <!-- Horizontal navigation -->
                <hemro-horizontal-navigation
                    class="mr-2"
                    [name]="'mainNavigation'"
                    [navigation]="navigation.horizontal"></hemro-horizontal-navigation>
            </ng-container>
            <!-- Navigation toggle button -->
            <ng-container *ngIf="isScreenSmall">
                <button
                    class="mr-2"
                    mat-icon-button
                    (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                </button>
            </ng-container>
            <!-- Components -->
            <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
                <!--<languages></languages>-->
                <hemro-scheme></hemro-scheme>
                <hemro-fullscreen class="hidden md:block"></hemro-fullscreen>
                <search [appearance]="'bar'"></search>
                <!-- <shortcuts></shortcuts>
                -->
                <user></user>
            </div>
        </div>

        <!-- Content -->
        <div class="flex flex-col flex-auto w-full bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>

        <!-- Footer -->
        <footer class="h-16 sm:h-20 px-6 sm:px-8 bg-card dark:bg-default"></footer>

    </div>

</div>
