import { CloudRecipeStatus, RecipeMode } from '@hemro/lib/domain';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change } from '@yukawa/chain-base-angular-domain';
import { PlainObject, StringKeys } from 'simplytyped';
import { CloudRecipe } from './cloud-recipe.model';
import { CloudRecipeSlot as ICloudRecipeSlot } from './cloud-recipe-slot.model';
import { CloudRecipeSlot } from './cloud-recipe-slot.entity';
import { DEFAULT_MODELS } from './grinder.model';

export class CloudRecipeSlotTableEntry extends QueryTableEntry<CloudRecipeSlot, ICloudRecipeSlot> {
    static viewConfig: ICloudRecipeSlot = {
        slot: 0,
        slotId: 0,
        recipe: {
            model: '',
            name: '',
            type: '',
            grindTime: 0,
            grindWeight: 0,
            coarseness: 0,
            brewTime: 0,
            brewWeight: 0,
            brewTemperature: 0,
            brewRatio: 0,
            change: {
                date: new Date(),
                notes: '',
                user: '',
            },

            bean: '',
            cloudRecipeId: 0,
            companyId: 0,
            icon: '',
            mode: RecipeMode.Gbt,
            status: CloudRecipeStatus.DRAFT,
            created: {
                date: new Date(),
                notes: '',
                user: '',
            },
        },
        change: {
            date: new Date(),
            notes: '',
            user: '',
        },
    };

    public constructor(
        cloudRecipeSlot: ICloudRecipeSlot = CloudRecipeSlotTableEntry.viewConfig,
    ) {
        super(cloudRecipeSlot instanceof CloudRecipeSlot ? cloudRecipeSlot : new CloudRecipeSlot(cloudRecipeSlot),
            cloudRecipeSlot?.slotId,
            cloudRecipeSlot?.slot?.toString());

        if (cloudRecipeSlot.change) {
            cloudRecipeSlot.change.date = new Date(cloudRecipeSlot.change.date);
        }
    }

    public get viewConfig(): ICloudRecipeSlot {
        return CloudRecipeSlotTableEntry.viewConfig;
    }

    public override get rowClass(): string {
        return this.entity?.recipe?.status === CloudRecipeStatus.OBSOLETE ? 'obsolete' : '';
    }

    protected override get labelTranslationPrefix(): string {
        return 'RECIPE.';
    }

    public override init(): void {
        super.init();
    }

    protected override mapDetails<TKey = CloudRecipeSlot>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void {
        let type: EntryDetailType = 'text';
        let value = item?.[key];
        const options = new Array<ISelectOption>();
        detail.entityName = 'RecipeSlot';

        switch ((key as StringKeys<CloudRecipeSlot | CloudRecipe>) as string) {
            case 'slotId':
                type = 'text';
                detail.showInTable = false;
                break;
            case 'name':
                type = 'text';
                detail.showInTable = true;
                break;
            case 'slot':
                type = 'number';
                value = value + 1;
                detail.showInTable = true;
                break;
            case 'model':
                type = 'text';
                detail.showInTable = true;
                value = DEFAULT_MODELS.find(_item => _item.value === value)?.name ?? ' ';
                break;
            case 'brewRatio':
                type = 'text';
                detail.showInTable = !!value;
                value = value ? `1:${Number(value).toFixed(1)}` : '';
                break;
            case 'type':
                type = 'text';
                detail.showInTable = true;
                value = value?.split(/(?=[A-Z])/)?.join(' ') || ' ';
                break;
            case 'grindTime':
            case 'grindWeight':
            case 'brewTime':
            case 'brewWeight':
                type = 'number';
                value = value ? Number((value / 1000).toFixed(4)).toFixed(1) : ' ';
                detail.showInTable = true;
                break;
            case 'brewTemperature':
            case 'coarseness':
                type = 'number';
                value = value || ' ';
                detail.showInTable = true;
                break;
            case 'recipe':
                detail.showInTable = false;
                this.mapDetails<CloudRecipe>(details, value, 'model', {
                    ...detail,
                    key: 'model',
                    type: 'text',
                });
                this.mapDetails<CloudRecipe>(details, value, 'name', {
                    ...detail,
                    key: 'name',
                    type: 'text',
                });
                this.mapDetails<CloudRecipe>(details, value, 'type', {
                    ...detail,
                    key: 'type',
                    type: 'text',
                });
                this.mapDetails<CloudRecipe>(details, value, 'grindTime', {
                    ...detail,
                    key: 'grindTime',
                    type: 'number',
                });
                this.mapDetails<CloudRecipe>(details, value, 'grindWeight', {
                    ...detail,
                    key: 'grindWeight',
                    type: 'number',
                });
                this.mapDetails<CloudRecipe>(details, value, 'coarseness', {
                    ...detail,
                    key: 'coarseness',
                    type: 'number',
                });
                this.mapDetails<CloudRecipe>(details, value, 'brewTime', {
                    ...detail,
                    key: 'brewTime',
                    type: 'number',
                });
                this.mapDetails<CloudRecipe>(details, value, 'brewWeight', {
                    ...detail,
                    key: 'brewWeight',
                    type: 'number',
                });
                this.mapDetails<CloudRecipe>(details, value, 'brewTemperature', {
                    ...detail,
                    key: 'brewTemperature',
                    type: 'number',
                });
                this.mapDetails<CloudRecipe>(details, value, 'brewRatio', {
                    ...detail,
                    key: 'brewRatio',
                    type: 'text',
                });
                break;
            case 'change':
                detail.entityName = 'Change';
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'storeId':
                detail.showInTable = false;
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key: level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
