<div class="notification-container" [class.unread]="!notification.read">
    <mat-checkbox [checked]="notification.read" [disabled]="notification.read"
                  (change)="markRead(notification.notificationId)">
    </mat-checkbox>
    <div class="ml-4 leading-normal content" [class.expand]="opened"
         [innerHTML]="notificationContent + notificationDesc">
    </div>
    <button mat-icon-button class="expand-button icon-size-2" (click)="opened = !opened">
        <mat-icon fontIcon="keyboard_arrow_down" [class.opened]=opened></mat-icon>
    </button>
</div>
