import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { HttpClient } from '@angular/common/http';
import { ChatRequest, ChatResponse } from './message-body.model';
import { Observable } from 'rxjs';
import { AuthService } from '@hemro/lib/profile';
import { SessionService } from '@yukawa/chain-main-angular-session';

@Injectable({ providedIn: 'root' })
export class ChatService extends RestAspect {
    constructor(
        http: HttpClient,
        configService: ConfigService,
        private _authService: AuthService,
        private _sessionService: SessionService,
    ) {
        super(http, configService, `${configService.getBaseUrl()}/admin-service/chat`);
    }

    ask(message: ChatRequest, signal: AbortSignal): Observable<ChatResponse> {
        return new Observable((observer) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', this.formatServiceUrl('/ask'), true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            if (this._sessionService.auth.isAuthenticated) {
                xhr.setRequestHeader('Authorization', `Bearer ${this._authService?.accessToken}`);
            }
            xhr.responseType = 'json';

            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    observer.next(xhr.response);
                    observer.complete();
                } else {
                    observer.error(xhr.statusText);
                }
            };

            xhr.onerror = (): void => observer.error(xhr.response);
            xhr.onabort = (): void => observer.error('ABORTED');

            xhr.send(JSON.stringify(message));

            signal.addEventListener('abort', () => {
                xhr.abort();
            });

            return () => xhr.abort();
        });
    }
}
