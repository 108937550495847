import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvitationFilter } from '@hemro/lib/domain';
import { IProfile, Profile, Registration } from '@hemro/lib/profile';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { map, Observable } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Invitation } from './invitation.model';


type CompanyInvitation = Pick<Invitation, 'invitedEmail' | 'remarks' | 'groupNames' | 'company'>;
type RegionInvitation = Pick<Invitation, 'invitedEmail' | 'remarks' | 'region' | 'groupNames' | 'company'>;
type StoreInvitation = Pick<Invitation, 'invitedEmail' | 'remarks' | 'store' | 'groupNames' | 'company'>;

export interface CompanyManagerInvitation extends CompanyInvitation
{
    groupNames: ['COMP_ADMINS'] | Array<string>;
}

export interface RegionManagerInvitation extends RegionInvitation
{
    groupNames: ['REG_MAN'];
}

export interface StoreManagerInvitation extends StoreInvitation
{
    groupNames: ['STORE_MAN'];
}

export interface BaristaInvitation extends StoreInvitation
{
    groupNames: ['BARISTA'];
}

export type HemroInvitation =
    CompanyManagerInvitation
    | RegionManagerInvitation
    | StoreManagerInvitation
    | BaristaInvitation;

@Injectable({ providedIn: 'root' })
export class InvitationService extends RestAspect
{
    private _lastProfileResponse: Nullable<HttpResponse<IProfile>> = null;

    constructor(http: HttpClient, configService: ConfigService)
    {
        super(http, configService, configService.formatUrl('invitationUrl'));
    }

    public get lastProfileResponse(): Nullable<HttpResponse<IProfile>>
    {
        return this._lastProfileResponse;
    }

    public create(
        invitation: HemroInvitation,
    ): Observable<Invitation>
    {
        return this.http.post<Invitation>(this.formatServiceUrl('/create'), invitation);
    }

    public queryInvitation(
        filter: InvitationFilter,
    ): Observable<QueryResult<Invitation>>
    {
        return this.http.post<QueryResult<Invitation>>(this.formatServiceUrl('/query'), filter);
    }

    public cancel(invitation: Invitation): Observable<Invitation>
    {
        return this.http.post<Invitation>(this.formatServiceUrl('/cancel?key=' + invitation.invitationId), {});
    }

    public resend(invitation: Invitation): Observable<Invitation>
    {
        return this.http.post<Invitation>(this.formatServiceUrl('/resend?key=' + invitation.invitationId), {});
    }

    public refuse(token: string): Observable<void>
    {
        return this.http.post<void>(this.formatServiceUrl('/confirm/refuse'), {}, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            }),
        });
    }

    public accept(registration: Registration, token: string): Observable<Profile>
    {
        return this.http.post<Profile>(this.formatServiceUrl('/confirm/accept'), registration, {
            observe: 'response',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            }),
        }).pipe(
            map((response) =>
            {
                this._lastProfileResponse = response;
                return new Profile(response.body as IProfile);
            }),
        );
    }

    public profile(token: string): Observable<Nullable<Profile>>
    {
        return this.http.get<IProfile>(this.formatServiceUrl('/confirm/profile'), {
            observe: 'response',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            }),
        }).pipe(
            map((response) =>
            {
                this._lastProfileResponse = response;
                return response.body ? new Profile(response.body as IProfile) : null;
            }),
        );
    }

    public loadInvitationFromToken(token: string): Observable<Nullable<Invitation>>
    {
        return this.http.get<Invitation>(this.formatServiceUrl('/confirm/invitation'), {
            observe: 'response',
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + token,
            }),
        }).pipe(
            map(response => response.body),
        );
    }
}
