import { Injectable } from '@angular/core';
import { ManagerFilter } from '@hemro/lib/domain';
import { AppInjector, Exception } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { IManager, Manager, ManagerTableEntry } from '../manager';
import { StoreService } from './store.service';


@Injectable({
    providedIn: 'root',
})
export class StoreManagerDatasource extends QueryTableDatasource<Manager, IManager, ManagerTableEntry, ManagerFilter>
{
    constructor()
    {
        super(ManagerTableEntry);
    }

    public async read(filter?: ManagerFilter): Promise<Array<Manager>>
    {
        this.query = await firstValueFrom(AppInjector.get(StoreService).queryManager({
            ...this.defaultFilter,
            ...filter,
        })) as QueryResult<Manager>;
        return this.query.items;
    }

    public async create(entry: ManagerTableEntry): Promise<ManagerTableEntry>
    {
        const storeManager   = await lastValueFrom(AppInjector.get(StoreService).assignManager({
            managedId: entry.entity.managedId,
            profileId: entry.entity.profileId,
        })) as Manager;
        storeManager.profile = entry.entity.profile;
        entry                = this.newEntry(storeManager);
        this.entries.splice(0, 0, entry);
        return entry;
    }

    public async update(entry: ManagerTableEntry, store?: Partial<Manager>): Promise<ManagerTableEntry>
    {
        throw new Exception('Store managers cannot be updated.');
    }

    public async delete(entry: ManagerTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Store manager ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(StoreService).removeManager({
            managedId: entry.entity.managedId,
            profileId: entry.entity.profileId,
        }));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(managedId: number): Promise<ManagerTableEntry>
    {
        return this.newEntry((await firstValueFrom(AppInjector.get(StoreService).queryManager({
            managedId,
        }).pipe(
            map((query) =>
            {
                if (query.items.length === 0) {
                    throw new QueryTableDatasourceException(`Store manager ${managedId} does not exist.`);
                }
                return query.items[0];
            }),
        ))) as Manager);
    }
}
