import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { StoreFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { StoreDatasource } from './store-datasource.service';
import { StoreTableEntry } from './store-table-entry.model';
import { Store as IStore, Store } from './store.model';


export class StoreTableStore extends QueryTableStore<Store, IStore, StoreTableEntry, StoreFilter, StoreDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: StoreFilter)
    {
        super(paginator, sort, tableFilter, StoreTableEntry, AppInjector.get(StoreDatasource));
    }
}
