import {Address, Change, ChangeFilter, Changed, Created, EntityFilter} from '@yukawa/chain-base-angular-domain';
import {Company, Region, Store} from './company';


/**
*	@deprecated
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.DashboardSettings <br/>
*/
export interface DashboardSettings extends  Changed, Created{
change?: Change;
created?: Change;

/**
*	@example 000002
*/
profileId?: string;
settings?: string;
}


/**
*	@deprecated
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.DashboardSettingsFilter <br/>
*/
export interface DashboardSettingsFilter extends  EntityFilter{
created?: ChangeFilter;
profileId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.Invitation <br/>
*/
export interface Invitation extends  Changed, Created{
change?: Change;
company?: Company;
created?: Change;
expiryDate?: string;

/**
*	@example ["STORE_MAN"]
*/
groupNames?: string[];
invitationId?: number;
invitedBy?: Profile;

/**
*	@example sample.invited@ngx.yukawa.de
*/
invitedEmail?: string;
region?: Region;

/**
*	@example remark: please join my company
*/
remarks?: string;
sendDate?: string;
status?: InvitationStatus;
store?: Store;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.InvitationFilter <br/>
*/
export interface InvitationFilter extends  EntityFilter{
companyId?: number;
internal?: boolean;
internalOrInvitedBy?: string;
invitationId?: number;
invitedByProfileId?: string;
invitedEmail?: string;
}

export enum InvitationStatus {
NEW = "NEW",
SENT = "SENT",
ACCEPTED = "ACCEPTED",
REFUSED = "REFUSED",
CANCELED = "CANCELED"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.Profile <br/>
*/
export interface Profile {
company?: Company;

/**
*	@example 000002
*/
profileId?: string;
skipNotifications?: string[];
user?: UserInfo;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.ProfileFilter <br/>
*/
export interface ProfileFilter extends  EntityFilter{
companyId?: number;

/**
*	@example true
*/
external?: boolean;
groups?: string[];
loadGroups?: boolean;
profileId?: string;
profileIds?: string[];
username?: string;
withoutSkipNotification?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.Registration <br/>
*/
export interface Registration {
address?: Address;

/**
*	@example Coffee Inc.
*/
companyName?: string;

/**
*	@example secret
*/
password?: string;
user?: UserInfo;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.profile.UserInfo <br/>
*/
export interface UserInfo extends  Changed, Created{
change?: Change;
created?: Change;

/**
*	@example Coffee
*/
firstName?: string;
groups?: any[];

/**
*	@example Drinker
*/
lastName?: string;

/**
*	@example 000002
*/
userId?: string;

/**
*	@example coffee.drinker@example.com
*/
username?: string;
}

