<form class="chat-input-container" [formGroup]="chatForm" (ngSubmit)="onSubmit()">
    <mat-icon class="text-primary" svgIcon="feather:user"></mat-icon>
    <mat-form-field class="chat-input">
        <input formControlName="query" matInput [placeholder]="'CHATBOT.PLACEHOLDER' | transloco"/>
    </mat-form-field>

    <button *ngIf="(isTyping$ | async) else submitButton"
            (click)="onStopTyping()"
            mat-icon-button class="send-button" type="button">
        <mat-icon fontIcon="stop" class="text-white text-xl"></mat-icon>
    </button>
</form>

<ng-template #submitButton>
    <button mat-icon-button class="send-button" type="submit">
        <mat-icon fontIcon="arrow_upward" class="text-white text-xl"></mat-icon>
    </button>
</ng-template>
