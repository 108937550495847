import { Injectable } from '@angular/core';
import { CompanyFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { lastValueFrom } from 'rxjs';
import { CompanyTableEntry } from './company-table-entry.model';
import { Company } from './company.model';
import { CompanyService } from './company.service';


@Injectable({
    providedIn: 'root',
})
export class CompanyDatasource extends QueryTableDatasource<Company, Company, CompanyTableEntry, CompanyFilter>
{
    constructor()
    {
        super(CompanyTableEntry);
    }

    public async read(filter?: CompanyFilter): Promise<Array<Company>>
    {
        this.query = await lastValueFrom(AppInjector.get(CompanyService).queryCompany({
            ...this.defaultFilter,
            ...filter,
        }));
        return this.query.items;
    }

    public async create(entry: CompanyTableEntry): Promise<CompanyTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(CompanyService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: CompanyTableEntry, company?: Partial<Company>): Promise<CompanyTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Company file ${entry.id} does not exist.`);
        }

        if (company) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(CompanyService).merge({
                ...company,
                companyId: entry.entity.companyId,
            } as Company)) as Company);
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: CompanyTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Company file ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(CompanyService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(companyId: number): Promise<CompanyTableEntry>
    {
        return this.newEntry((await lastValueFrom(AppInjector.get(CompanyService).loadByKey(companyId))));
    }
}
