import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProfileFilter } from '@hemro/lib/domain';
import { AppInjector, CachedRepoEntityService, ConfigService } from '@yukawa/chain-base-angular-client';
import { Session, SessionChangedEventArgs, SessionService } from '@yukawa/chain-main-angular-session';
import { Credentials } from '@yukawa/chain-security-angular-core';
import { firstValueFrom, map, Observable } from 'rxjs';
import { Nullable } from 'simplytyped';
import { Profile } from './profile.entity';
import { IProfile } from './profile.model';
import { User } from './user.model';
import { UserService } from './user.service';


@Injectable()
export class ProfileService extends CachedRepoEntityService<Profile, ProfileFilter, string>
{
    private _profile: Nullable<Profile>;
    private _lastResponse: Nullable<HttpResponse<User>> = null;

    public constructor(
        http: HttpClient,
        _configService: ConfigService,
        private readonly _sessionService: SessionService,
        private readonly _userService: UserService,
    )
    {
        super(http, _configService, 'profileUrl', entity => entity.key || entity.profileId, 0);

        _sessionService.sessionChanged.subscribe(async (session: Session, ea?: SessionChangedEventArgs) =>
        {
            if (ea?.session instanceof Session.SessionConnected) {
                await this.loadProfile();
            }
            else {
                this._profile = null;
            }
        });
    }

    public get lastResponse(): Nullable<HttpResponse<unknown>>
    {
        return this._lastResponse;
    }

    get profile(): Nullable<Profile>
    {
        return this._profile;
    }

    async init(): Promise<boolean>
    {
        return this.loadProfile();
    }

    async loadProfile(): Promise<boolean>
    {
        if (this._sessionService.auth.isAuthenticated) {
            try {
                this._profile = await firstValueFrom(this.queryUserProfile());
            }
            catch (e) {
                console.error(e);
            }
        }

        return this._profile != null;
    }

    getCode(username?: string): Observable<User>
    {
        const body: Credentials = { username: username };
        return this.http.post<User>(this.formatServiceUrl('/refreshPassword'), body);
    }

    queryUserProfile(): Observable<Profile>
    {
        return this.load(AppInjector.get(UserService).user?.userId as string);
    }

    signUp(email: string): Observable<User>
    {
        return this.http.post<User>(this.formatServiceUrl('/register'), {
            username: email,
        }, { observe: 'response' }).pipe(
            map((response) =>
            {
                this._lastResponse = response;
                return response.body as User;
            }),
        );
    }

    loadImage(profile: Nullable<Profile> = this._profile): void
    {

    }

    unregister(username: string): Observable<unknown>
    {
        let params = new HttpParams()
            .set('username', username);
        return this.http.delete(this.formatServiceUrl('/unregister'), { params });
    }

    protected override keyForEntityCache(json: Profile): string
    {
        return json.profileId;
    }

    protected createInstanceFrom(json: IProfile): Profile
    {
        return new Profile(json);
    }
}
