import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HemroLoadingInterceptor } from '@hemro/services/loading/loading.interceptor';


@NgModule({
    providers: [
        {
            provide : HTTP_INTERCEPTORS,
            useClass: HemroLoadingInterceptor,
            multi   : true,
        },
    ],
})
export class HemroLoadingModule
{
}
