import { Injectable } from '@angular/core';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource } from '@yukawa/chain-base-angular-comp/query-table';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { firstValueFrom } from 'rxjs';
import { ProfileFilter } from '@hemro/lib/domain';
import { IProfile, Profile, ProfileService, GroupService } from '@hemro/lib/profile';
import { CompanyManagerTableEntry } from './company-manager-table-entry.model';
import { Company } from './company.model';


@Injectable({
    providedIn: 'root',
})
export class CompanyManagerDatasource extends QueryTableDatasource<Profile, IProfile, CompanyManagerTableEntry, ProfileFilter> {
    private readonly _companyAdminGroup = 'COMP_ADMINS';

    constructor() {
        super(CompanyManagerTableEntry, {
            groups: ['COMP_ADMINS']
        });
    }

    public async read(profileFilter?: ProfileFilter): Promise<Array<Profile>> {
        this.query = await firstValueFrom(AppInjector.get(ProfileService).query({
            ...this.defaultFilter,
            ...profileFilter,
        })) as QueryResult<Profile>;
        return this.query.items || [];
    }

    public async create(entry: CompanyManagerTableEntry): Promise<CompanyManagerTableEntry> {
        return this.newEntry(entry.entity);
    }

    public async addCompanyManager(entry: CompanyManagerTableEntry, company: Company): Promise<CompanyManagerTableEntry> {
        const editResult = await AppInjector.get(GroupService).assign(entry.entity.user.userId, this._companyAdminGroup);
        entry = await this.create({ ...entry, entity: { ...entry.entity, company } as never } as never);
        return this.create(entry);
    }

    update(entry: CompanyManagerTableEntry, entity?: Partial<Profile> | undefined): Promise<CompanyManagerTableEntry> {
        throw new Error('Method not implemented.');
    }

    public async delete(entry: CompanyManagerTableEntry): Promise<void> {
        await AppInjector.get(GroupService).remove(entry.entity.user.userId, this._companyAdminGroup);
    }

    getByID(id: string | number): Promise<CompanyManagerTableEntry> {
        throw new Error('Method not implemented.');
    }
}
