import { TranslocoService } from '@ngneat/transloco';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { IQueryTableEntryDetail, ISelectOption } from '@yukawa/chain-base-angular-comp/query-table';
import { AutocompleteSearchFunction, Version } from '@yukawa/chain-base-angular-comp/shared';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { lastValueFrom, map, Subject } from 'rxjs';
import { Firmware } from './firmware.model';
import { FirmwareService } from './firmware.service';


export const firmwareAutocompleteSearch = (
    value: Firmware,
    detail: Partial<IQueryTableEntryDetail>): AutocompleteSearchFunction =>
{
    const subject               = new Subject<Array<ISelectOption<string, Firmware>>>();
    detail.autocompleteOptions$ = subject;
    return async (filter: string | null): Promise<void> =>
    {
        subject.next(await lastValueFrom(AppInjector.get(FirmwareService).queryFirmware({
            keyword : `*${filter}*`,
            orderDir: 'DESC',
            orderBy : 'version',
            pager   : {
                firstResult: 0,
                pageSize   : 10,
            },
        }).pipe(map((firmwares: QueryResult<Firmware>) =>
            firmwares.items.map((firmware: Firmware) =>
            {
                const versionText = new Version(firmware.versionText).toString();
                return ({
                    name       : value.versionText === versionText
                        ? `${versionText} (${AppInjector.get(TranslocoService).translate(
                            'FIRMWARE.CURRENT_FIRMWARE')})`
                        : versionText,
                    value      : versionText,
                    objectValue: versionText === value.versionText
                        ? value
                        : {
                            versionText: versionText,
                        } as Firmware,
                } as ISelectOption<string, Firmware>);
            })),
        )));
    };
};
