import {Change, ChangeFilter, Changed, Created, DataRange, EntityFilter} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.system.Firmware <br/>
*/
export interface Firmware extends  Changed, Created{
change?: Change;
created?: Change;

/**
*	@example https://syncdev.mahlkoenig.com/files/firmware/1.0.1.zip
*/
downloadUrl?: string;
nextVersion?: string;

/**
*	@example contains fancy new features and performance improvements
*/
notes?: string;

/**
*	@example RELEASED
*/
phase?: FirmwarePhase;

/**
*	@example generated by backend based on bundle version: xxx.yyy.zzz -> xxx * 1000000 + yyy * 1000 + zzz
*/
version?: number;
versionText?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.system.FirmwareFilter <br/>
*/
export interface FirmwareFilter extends  EntityFilter{
created?: ChangeFilter;
phase?: string;
phases?: FirmwarePhase[];
version?: number;
versionRange?: DataRange<number> ;
versionText?: string;
}

export enum FirmwarePhase {
UPLOADED = "UPLOADED",
TEST = "TEST",
RELEASED = "RELEASED",
OBSOLETE = "OBSOLETE"
}

