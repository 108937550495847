import { ProfileMockApi } from 'app/mock-api/common/profile/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { IconsMockApi } from 'app/mock-api/ui/icons/api';
import { CountryMockApi } from 'app/mock-api/common/country/api';


export const mockApiServices = [
//    AuthMockApi,
    ProfileMockApi,
    IconsMockApi,
    UserMockApi,
    CountryMockApi,
];
