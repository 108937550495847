import {AbstractResult, ChainEventBean, Change, Changed, EntityFilter, InstantRange} from '@yukawa/chain-base-angular-domain';
import {BrewerBinding, Device, DeviceSettings, DeviceStatus} from './device';
import {Firmware} from './system';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.BrewEvent <br/>
*/
export interface BrewEvent extends  GenericDeviceEvent<BrewInfo> {

/**
*	@example {"key":"value"}
*/
origEvent?: string;
payload?: BrewInfo | Object;
shotQuality?: ShotQuality;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.BrewEventFilter <br/>
*/
export interface BrewEventFilter extends  GenericDeviceEventFilter{

/**
*	values: [lm, scale]<br/>
*/
brewer?: string;
grinderId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.BrewInfo <br/>
*/
export interface BrewInfo {

/**
*	values: [lm, scale]<br/>
*/
brewer?: string;

/**
*	@example DoseA
*/
doseIndex?: string;

/**
*	values: [BrewRatioType, PulsesType, MassType]<br/>
*/
doseType?: string;

/**
*	@example 15600
*/
duration?: number;

/**
*	@example afsdfasdg-aegaseg-sadg-ageg
*/
grindEventUuid?: string;

/**
*	@example T12345
*/
grinderId?: string;

/**
*	@example Group1
*/
groupNumber?: string;

/**
*	@example 33300
*/
mass?: number;

/**
*	values: [Automatic, Manual]<br/>
*/
stopReason?: string;

/**
*	@example 2.5
*/
stopTarget?: number;

/**
*	@example ABR_Mode
*/
stopType?: string;

/**
*	@example 92.5
*/
temperature?: number;
validity?: boolean;

/**
*	@example 309
*/
volume?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.DeviceEvent <br/>
*/
export interface DeviceEvent extends  GenericDeviceEvent<{[key: string]: Object}> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.DeviceSettingsEntity <br/>
*/
export interface DeviceSettingsEntity {
cloudDate?: string;
deviceDate?: string;

/**
*	@example T12345
*/
deviceId?: string;
payload?: DeviceSettings;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.DeviceSettingsFilter <br/>
*/
export interface DeviceSettingsFilter extends  GenericDeviceEventFilter{}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.DeviceStatusEntity <br/>
*/
export interface DeviceStatusEntity {
cloudDate?: string;
deviceDate?: string;

/**
*	@example T12345
*/
deviceId?: string;
status?: DeviceStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.DeviceStatusFilter <br/>
*/
export interface DeviceStatusFilter extends  GenericDeviceEventFilter{}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ErrorEvent <br/>
*/
export interface ErrorEvent extends  GenericDeviceEvent<ErrorParams> {
cleared?: string;
device?: Device;
setTranslation?: undefined | ErrorTranslation;
translation?: ErrorTranslation;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ErrorEventFilter <br/>
*/
export interface ErrorEventFilter extends  GenericDeviceEventFilter{
cleared?: boolean;
clearedRange?: InstantRange;
errorType?: string;
storeIds?: number[];
withoutTranslation?: boolean;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ErrorParams <br/>
*/
export interface ErrorParams {

/**
*	@example get https://example.com/ returned 404 not found
*/
errorDetails?: string;

/**
*	@example SW_UPDATE_DOWNLOAD_FAILED
*/
errorType?: string;

/**
*	@example GRIND_COMPLETE
*/
relatedEventName?: string;

/**
*	@example afsdfasdg-aegaseg-sadg-ageg
*/
relatedEventUuid?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ErrorTranslation <br/>
*/
export interface ErrorTranslation extends  Changed{
change?: Change;
description?: string;

/**
*	@example HopperNotMounted
*/
errorType?: string;
header?: string;

/**
*	@example en_US
*/
language?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ErrorTranslationFilter <br/>
*/
export interface ErrorTranslationFilter extends  EntityFilter{
errorType?: string;
language?: string;
}

export enum FilterType {
SINGLE = "SINGLE",
DOUBLE = "DOUBLE"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.FirmwareEvent <br/>
*/
export interface FirmwareEvent extends  ChainEventBean<Firmware> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.GenericDeviceEvent <br/>
*/
export interface GenericDeviceEvent <T> {
cloudDate?: string;
deviceDate?: string;

/**
*	@example T12345
*/
deviceId?: string;

/**
*	@example afsdfasdg-aegaseg-sadg-ageg
*/
eventUuid?: string;

/**
*	@example GRIND_COMPLETE
*/
name?: string;
payload?: T;
setPayload?: undefined | T;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.GenericDeviceEventFilter <br/>
*/
export interface GenericDeviceEventFilter extends  EntityFilter{
cloudDateRange?: InstantRange;
companyId?: number;
deviceDate?: string;
deviceDateRange?: InstantRange;

/**
*	@example T12345
*/
deviceId?: string;

/**
*	@example ["T12345"]
*/
deviceIds?: string[];
eventUuid?: string;

/**
*	@example ["TEST-%"]
*/
excludeDeviceIds?: string[];

/**
*	@example ["GRIND_COMPLETE"]
*/
name?: string[];
username?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.GrindEvent <br/>
*/
export interface GrindEvent extends  GenericDeviceEvent<GrindParams> {}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.GrindEventCount <br/>
*/
export interface GrindEventCount {
count?: number;
deviceId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.GrindEventFilter <br/>
*/
export interface GrindEventFilter extends  GenericDeviceEventFilter{
deviceDate?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.GrindParams <br/>
*/
export interface GrindParams {

/**
*	@example 24000
*/
brewTimeRecipe?: number;
cloudRecipeId?: number;

/**
*	@example 120
*/
dddActual?: number;

/**
*	@example 122
*/
dddRecipe?: number;
debugData?: {[key: string]: Object};

/**
*	@example 4500
*/
durationActual?: number;

/**
*	@example 7200
*/
durationNoLoad?: number;

/**
*	@example 4700
*/
durationRecipe?: number;
filterType?: FilterType;

/**
*	@example BottomlessPortafilter
*/
recipeIcon?: string;

/**
*	values: [range[0, 5]]<br/>
*	@example 1
*/
recipeIndex?: number;

/**
*	@example true
*/
recipeM2mLinked?: boolean;

/**
*	@example Gbw
*/
recipeMode?: RecipeMode;

/**
*	@example SingleShot
*/
recipeType?: string;

/**
*	@example d51fca23-d1da-4742-a79b-93c40a347ba5
*/
recipeUuid?: string;

/**
*	@example 600
*/
sizeRecipe?: number;

/**
*	@example 500
*/
sizeTheoretic?: number;

/**
*	@example true
*/
successful?: boolean;
triggerMode?: TriggerMode;

/**
*	@example 14000
*/
weightActual?: number;

/**
*	@example 15000
*/
weightRecipe?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.IntervalResult <br/>
*/
export interface IntervalResult <T> extends  AbstractResult{

/**
*	@example 10000
*/
interval?: number;
payload?: T;
}

export enum RecipeMode {
Manual = "Manual",
Gbw = "Gbw",
Gbt = "Gbt"
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ShotHistory <br/>
*/
export interface ShotHistory {
binding?: BrewerBinding;
brew?: BrewEvent;
device?: Device;
grind?: GrindEvent;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ShotHistoryFilter <br/>
*/
export interface ShotHistoryFilter extends  EntityFilter{
brewerId?: string;
cloudDate?: InstantRange;
companyId?: number;
grinderId?: string;
storeId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.event.ShotQuality <br/>
*/
export interface ShotQuality {
brewTime?: ShotQualityLevel;
grindTime?: ShotQualityLevel;
grindWeight?: ShotQualityLevel;
overall?: ShotQualityLevel;
}

export enum ShotQualityLevel {
PERFECT = "PERFECT",
OK = "OK",
OFF_ABOVE = "OFF_ABOVE",
OFF_BELOW = "OFF_BELOW"
}

export enum TriggerMode {
StartButton = "StartButton",
PortafilterSwitch = "PortafilterSwitch",
PortafilterDetection = "PortafilterDetection"
}

