<div
    *ngIf="displayThemeButton"
    class="fixed flex items-center justify-center right-0 w-10 h-10 shadow-lg rounded-l-lg z-90 cursor-pointer bg-red-600 bg-opacity-90 print:hidden"
    style="top: 275px"
    (click)="settingsDrawer.toggle()">
    <mat-icon
        class="icon-size-5 text-white animate-spin-slow"
        [svgIcon]="'heroicons_solid:cog'"></mat-icon>
</div>

<hemro-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'settingsDrawer'"
    [position]="'right'"
    #settingsDrawer>

    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
            <mat-icon
                class="icon-size-7 text-current"
                [svgIcon]="'heroicons_solid:cog'"></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight" transloco="SETTINGS.THEME"></div>
            <button class="ml-auto" mat-icon-button (click)="reset()"
                    matTooltip="Reset to default settings">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:refresh'"></mat-icon>
            </button>
            <button
                class="ml-2"
                mat-icon-button
                (click)="settingsDrawer.close()">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col p-6">

            <!-- Theme -->
            <div class="text-md font-semibold text-secondary">THEME</div>
            <div class="grid grid-cols-2 sm:grid-cols-3 gap-3 mt-6">
                <ng-container *ngFor="let theme of config.themes">
                    <div
                        class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                        [class.ring-2]="config.theme === theme.id"
                        [ngClass]="theme.id"
                        (click)="setTheme(theme.id)">
                        <div
                            class="flex-0 w-3 h-3 rounded-full bg-primary"
                        ></div>
                        <div
                            class="ml-2.5 font-medium leading-5 truncate"
                            [class.text-secondary]="config.theme !== theme.id">
                            {{theme.name}}
                        </div>
                    </div>
                </ng-container>
            </div>

<!--            <hr class="my-8">-->

<!--            &lt;!&ndash; Scheme &ndash;&gt;-->
<!--            <div class="text-md font-semibold text-secondary">SCHEME</div>-->
<!--            <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">-->
<!--                &lt;!&ndash; Auto &ndash;&gt;-->
<!--                <div-->
<!--                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover dark:ring-white"-->
<!--                    [class.ring-2]="config.scheme === 'auto'"-->
<!--                    matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query."-->
<!--                    (click)="setScheme('auto')">-->
<!--                    <div class="flex items-center rounded-full overflow-hidden">-->
<!--                        <mat-icon-->
<!--                            class="icon-size-5"-->
<!--                            [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>-->
<!--                    </div>-->
<!--                    <div-->
<!--                        class="flex items-center ml-2 font-medium leading-5"-->
<!--                        [class.text-secondary]="config.scheme !== 'auto'">-->
<!--                        Auto-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; Dark &ndash;&gt;-->
<!--                <div-->
<!--                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover dark:ring-white"-->
<!--                    [class.ring-2]="config.scheme === 'dark'"-->
<!--                    (click)="setScheme('dark')">-->
<!--                    <div class="flex items-center rounded-full overflow-hidden">-->
<!--                        <mat-icon-->
<!--                            class="icon-size-5"-->
<!--                            [svgIcon]="'heroicons_solid:moon'"></mat-icon>-->
<!--                    </div>-->
<!--                    <div-->
<!--                        class="flex items-center ml-2 font-medium leading-5"-->
<!--                        [class.text-secondary]="config.scheme !== 'dark'">-->
<!--                        Dark-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; Light &ndash;&gt;-->
<!--                <div-->
<!--                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover dark:ring-white"-->
<!--                    [class.ring-2]="config.scheme === 'light'"-->
<!--                    (click)="setScheme('light')">-->
<!--                    <div class="flex items-center rounded-full overflow-hidden">-->
<!--                        <mat-icon-->
<!--                            class="icon-size-5"-->
<!--                            [svgIcon]="'heroicons_solid:sun'"></mat-icon>-->
<!--                    </div>-->
<!--                    <div-->
<!--                        class="flex items-center ml-2 font-medium leading-5"-->
<!--                        [class.text-secondary]="config.scheme !== 'light'">-->
<!--                        Light-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            <hr class="my-8">

            <!-- Layout -->
            <div class="text-md font-semibold text-secondary">LAYOUT</div>
            <div class="grid grid-cols-3 gap-3 mt-6">

                <!-- Empty -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('empty')">
                    <div
                        class="flex flex-col h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'empty'">
                        <div class="flex flex-col flex-auto bg-gray-50 dark:bg-gray-900"></div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'empty'">
                        Empty
                    </div>
                </div>

                <!-- Classic -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('classic')">
                    <div
                        class="flex h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'classic'">
                        <div class="w-8 bg-gray-100 dark:bg-gray-800">
                            <div class="mt-3 mx-1.5 space-y-1">
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="flex items-center justify-end h-full mr-1.5">
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'classic'">
                        Classic
                    </div>
                </div>

                <!-- Classy -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('classy')">
                    <div
                        class="flex h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'classy'">
                        <div class="w-8 bg-gray-100 dark:bg-gray-800">
                            <div class="flex items-center mt-1 mx-1">
                                <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1 h-1 ml-auto rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1 h-1 ml-0.5 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                            <div class="w-4 h-4 mt-2.5 mx-auto rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="mt-2 mx-1 space-y-1">
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="flex items-center justify-end h-full mr-2">
                                    <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'classy'">
                        Classy
                    </div>
                </div>

                <!-- Compact -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('compact')">
                    <div
                        class="flex h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'compact'">
                        <div class="w-5 bg-gray-100 dark:bg-gray-800">
                            <div class="w-3 h-3 mt-2 mx-auto rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            <div class="flex flex-col items-center w-full mt-2 space-y-1">
                                <div class="w-3 h-2.5 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-3 h-2.5 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-3 h-2.5 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="flex items-center justify-end h-full mr-1.5">
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'compact'">
                        Compact
                    </div>
                </div>

                <!-- Dense -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('dense')">
                    <div
                        class="flex h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'dense'">
                        <div class="w-4 bg-gray-100 dark:bg-gray-800">
                            <div class="w-2 h-2 mt-2 mx-auto rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            <div class="flex flex-col items-center w-full mt-2 space-y-1">
                                <div class="w-2 h-2 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-2 h-2 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-2 h-2 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="flex items-center justify-end h-full mr-1.5">
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'dense'">
                        Dense
                    </div>
                </div>

                <!-- Futuristic -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('futuristic')">
                    <div
                        class="flex h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'futuristic'">
                        <div class="w-8 bg-gray-100 dark:bg-gray-800">
                            <div class="flex flex-col flex-auto h-full py-3 px-1.5 space-y-1">
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                                <div class="flex-auto"></div>
                                <div class="h-1 rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="flex items-center justify-end h-full mr-1.5">
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'futuristic'">
                        Futuristic
                    </div>
                </div>

                <!-- Thin -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('thin')">
                    <div
                        class="flex h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'thin'">
                        <div class="w-3 bg-gray-100 dark:bg-gray-800">
                            <div class="w-1.5 h-1.5 mt-2 mx-auto rounded-sm bg-gray-300 dark:bg-gray-700"></div>
                            <div class="flex flex-col items-center w-full mt-2 space-y-1">
                                <div class="w-1.5 h-1.5 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1.5 h-1.5 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1.5 h-1.5 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1.5 h-1.5 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1.5 h-1.5 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto border-l">
                            <div class="h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="flex items-center justify-end h-full mr-1.5">
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'thin'">
                        Thin
                    </div>
                </div>

                <div class="col-span-2"></div>

                <!-- Centered -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('centered')">
                    <div
                        class="flex h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'centered'">
                        <div class="flex flex-col flex-auto my-1 mx-2 border rounded-md overflow-hidden">
                            <div class="flex items-center h-3 bg-gray-100 dark:bg-gray-800">
                                <div class="flex ml-1.5">
                                    <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-3 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-3 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-3 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                                <div class="flex items-center justify-end ml-auto mr-1.5">
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 ml-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'centered'">
                        Centered
                    </div>
                </div>

                <!-- Enterprise -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('enterprise')">
                    <div
                        class="flex flex-col h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'enterprise'">
                        <div class="flex items-center h-3 px-2 bg-gray-100 dark:bg-gray-800">
                            <div class="w-2 h-2 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="flex items-center justify-end ml-auto space-x-1">
                                <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div
                            class="flex items-center h-3 px-2 border-t border-b space-x-1 bg-gray-100 dark:bg-gray-800">
                            <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                        </div>
                        <div class="flex flex-col flex-auto my-1 mx-2 border rounded overflow-hidden">
                            <div class="flex flex-auto bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'enterprise'">
                        Enterprise
                    </div>
                </div>

                <!-- Material -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('material')">
                    <div
                        class="flex flex-col h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'material'">
                        <div class="flex flex-col flex-auto my-1 mx-2 border rounded overflow-hidden">
                            <div class="flex items-center h-4 px-2 bg-gray-100 dark:bg-gray-800">
                                <div class="w-2 h-2 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="flex items-center justify-end ml-auto space-x-1">
                                    <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                    <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                </div>
                            </div>
                            <div class="flex items-center h-2 px-2 space-x-1 bg-gray-100 dark:bg-gray-800">
                                <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                            <div class="flex flex-auto border-t bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'material'">
                        Material
                    </div>
                </div>

                <!-- Modern -->
                <div
                    class="flex flex-col cursor-pointer"
                    (click)="setLayout('modern')">
                    <div
                        class="flex flex-col h-20 rounded-md overflow-hidden border-2 hover:opacity-80"
                        [class.border-primary]="config.layout === 'modern'">
                        <div class="flex items-center h-4 px-2 border-b bg-gray-100 dark:bg-gray-800">
                            <div class="w-2 h-2 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            <div class="flex items-center h-3 ml-2 space-x-1">
                                <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-3 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                            <div class="flex items-center justify-end ml-auto space-x-1">
                                <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                                <div class="w-1 h-1 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                            </div>
                        </div>
                        <div class="flex flex-col flex-auto">
                            <div class="flex flex-auto bg-gray-50 dark:bg-gray-900"></div>
                        </div>
                    </div>
                    <div
                        class="mt-2 text-md font-medium text-center text-secondary"
                        [class.text-primary]="config.layout === 'modern'">
                        Modern
                    </div>
                </div>

            </div>

        </div>

    </div>

</hemro-drawer>
