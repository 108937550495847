import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmPopupComponent } from './confirm-popup.component';


@NgModule({
    declarations: [
        ConfirmPopupComponent,
    ],

    exports: [
        ConfirmPopupComponent,
    ],

    imports: [
        CommonModule,
        MatButtonModule,
    ],
})
export class ConfirmPopupModule
{
}
