import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JsonDocumentComponent } from './json-document.component';


@NgModule({
    declarations: [
        JsonDocumentComponent,
    ],

    exports: [
        JsonDocumentComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class JsonDocumentModule
{
}
