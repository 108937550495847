import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslocoModule } from '@ngneat/transloco';
import { FooterComponent } from 'app/layout/common/footer/footer.component';


@NgModule({
    declarations: [
        FooterComponent,
    ],
    imports     : [
        CommonModule,
        MatButtonModule,
        MatToolbarModule,
        TranslocoModule,
    ],
    exports     : [
        FooterComponent,
        FooterComponent,
    ],
})
export class FooterModule
{
}
