import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManagerFilter, ManagerKey, RegionFilter, RegionManager } from '@hemro/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { IManagerService } from '../manager';
import { Region } from './region.entity';


@Injectable({ providedIn: 'root' })
export class RegionService extends RestAspect implements IManagerService<RegionManager>
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('regionUrl'));
    }

    loadByKey(regionId: number): Observable<Region>
    {
        return this.http.get<Region>(`${this.formatServiceUrl()}/?key=${regionId}`);
    }

    delete(region: Region): Observable<Region>
    {
        return this.http.delete<Region>(`${this.formatServiceUrl()}/?key=` + region.regionId);
    }

    create(region: Region): Observable<Region>
    {
        return this.http.post<Region>(this.formatServiceUrl('/create'), region.toJson());
    }

    merge(region: Region): Observable<Region>
    {
        return this.http.post<Region>(this.formatServiceUrl('/merge'), region.toJson());
    }

    put(region: Region): Observable<Region>
    {
        return this.http.post<Region>(this.formatServiceUrl('/put'), region.toJson());
    }

    queryRegion(filter: RegionFilter): Observable<QueryResult<Region>>
    {
        return super.query<Region>(this.formatServiceUrl('/query'), filter);
    }

    update(region: Region): Observable<Region>
    {
        return this.http.post<Region>(this.formatServiceUrl('/update'), region.toJson());
    }

    //region IManagerService<RegionManager> Members

    assignManager(key: ManagerKey): Observable<RegionManager>
    {
        return this.http.post<RegionManager>(this.formatServiceUrl('/manager/assign'), key);
    }

    queryManager(filter: ManagerFilter): Observable<QueryResult<RegionManager>>
    {
        return this.http.post<QueryResult<RegionManager>>(this.formatServiceUrl('/manager/query'), filter);
    }

    removeManager(key: ManagerKey): Observable<RegionManager>
    {
        return this.http.post<RegionManager>(this.formatServiceUrl('/manager/remove'), key);
    }

    //endregion
}
