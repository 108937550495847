import { Entity } from '@yukawa/chain-base-angular-client';
import { Change, Info } from '@yukawa/chain-base-angular-domain';
import { Company } from '../company';
import { Region as IRegion } from './region.model';


export class Region extends Entity<IRegion> implements IRegion
{
    public change!: Required<Change>;
    public companyId!: number;
    public created!: Required<Change>;
    public info!: Required<Info>;
    public regionId!: number;

    public company!: Company;

    public constructor(initialData: IRegion)
    {
        super(initialData);

        if (!this.company) {
            this.company = null as never;
        }
    }

    //region Entity<IRegion> implementation

    public get key(): string
    {
        return String(this.regionId);
    }

    public override toJson(): IRegion
    {
        return this.toJsonWithKeys(
            [
                'companyId',
                'info',
                'regionId',
                'company'
            ],
            {},
        );
    }

    public override updateFromJson(data: IRegion): void
    {
        this.setFromJson(data, [
            'change',
            'companyId',
            'created',
            'info',
            'regionId',
            'company'
        ], undefined, {
            created: (created: Change) =>
            {
                if (created) {
                    created.date = new Date(created.date as never);
                }
                return created;
            },
            change : (change: Change) =>
            {
                if (change) {
                    change.date = new Date(change.date as never);
                }
                return change;
            },
        });
    }

    //endregion

    public override toString(): string
    {
        return this.info.name;
    }
}
