import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChatFacadeService } from '@hemro/lib/admin';
import { combineLatest, map, Observable } from 'rxjs';

@Component({
    selector: 'chat-input',
    templateUrl: './chat-input.component.html',
    styleUrls: ['./chat-input.component.scss']
})
export class ChatInputComponent {
    @Output() send = new EventEmitter<string>();

    chatForm: FormGroup;

    constructor(private _chatFacadeService: ChatFacadeService) {
        this.chatForm = new FormGroup({
            query: new FormControl()
        });
    }

    get isTyping$(): Observable<boolean> {
        return combineLatest([
            this._chatFacadeService.isTyping$,
            this._chatFacadeService.loading$
        ]).pipe(
            map(([isTyping, loading]) => isTyping || loading)
        );
    }

    onSubmit(): void {
        if (!this.chatForm.value.query
            || this._chatFacadeService.isTyping
            || this._chatFacadeService.loading) {
            return;
        }
        this.send.emit(this.chatForm.value.query);
        this.chatForm.reset();
    }

    onStopTyping(): void {
        this._chatFacadeService.stopTyping();
    }
}
