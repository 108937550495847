import { HemroRoles, UserService } from '@hemro/lib/profile';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change, Info } from '@yukawa/chain-base-angular-domain';
import { map } from 'rxjs';
import { PlainObject, StringKeys } from 'simplytyped';
import { Company, CompanyService } from '../company';
import { RegionDatasource } from './region-datasource.service';
import { Region } from './region.entity';
import { Region as IRegion } from './region.model';


export class RegionTableEntry extends QueryTableEntry<Region, IRegion>
{
    static viewConfig: IRegion = {
        regionId : 0,
        info     : {
            id       : '',
            name     : '',
            shortName: '',
            desc     : '',
        },
        company  : {} as Company,
        companyId: 0,
        created  : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change   : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };

    public constructor(
        region: IRegion = RegionTableEntry.viewConfig,
    )
    {
        super(
            region instanceof Region ? region : new Region(region),
            region?.regionId,
            region?.info?.name,
        );

    }

    public get viewConfig(): IRegion
    {
        return RegionTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'REGION.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = IRegion>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'Region';
        const canEditAllCompanies = AppInjector.get(UserService).hasRole(HemroRoles.editAllCompanies);
        const companyColumnDisplayed = AppInjector.get(RegionDatasource).companyColumnDisplayed;

        switch ((key as StringKeys<IRegion> | StringKeys<Info>)) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'company':
                type            = 'select';
                detail.required = true;
                if (AppInjector.get(UserService).hasRole(HemroRoles.editAllRegions)) {
                    detail.canEdit = true;
                }
                detail.showInDetails = false;
                detail.showInTable   = false;
                detail.compareWith   = (o1: Company | number, o2: Company | number): boolean =>
                {
                    const id1 = typeof o1 === 'number' ? o1 : o1?.companyId;
                    const id2 = typeof o2 === 'number' ? o2 : o2?.companyId;

                    return id1 === id2;
                };
                detail.options$      = AppInjector.get(CompanyService).queryCompany({
                    orderBy : 'info.name',
                    orderDir: 'ASC',
                }).pipe(map(queryResult =>
                    queryResult.items.map(_item => ({
                        name : _item.info.name,
                        value: _item,
                    }))));
                value                = item?.['companyId'];
                break;
            case 'companyId':
                type                 = 'text';
                detail.showInDetails = false;
                value                = item?.['company']?.info?.name;
                detail.showInTable   = canEditAllCompanies && companyColumnDisplayed;
                break;
            case 'desc':
                type = 'multiline';
                break;
            case 'info':
                detail.entityName = 'Info';
                this.mapDetails<Info>(details, value, 'name', {
                    ...detail,
                    group       : key,
                    groupIndex  : 1,
                    groupByField: true,
                    class       : 'h4',
                    tableGroup  : true,
                    canEdit     : true,
                    required    : true,
                });
                this.mapDetails<Info>(details, value, 'shortName', {
                    ...detail,
                    group      : key,
                    groupIndex : 1,
                    tableGroup : false,
                    showInTable: false,
                    canEdit    : true,
                    required   : false,
                });
                this.mapDetails<Info>(details, value, 'desc', {
                    ...detail,
                    group          : key,
                    groupIndex     : 1,
                    tableGroup     : true,
                    canEdit        : true,
                    required       : false,
                    markdownSupport: false,
                });
                return;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
