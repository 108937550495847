import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { HemroFullscreenModule } from '@hemro/components/fullscreen';
import { HemroLoadingBarModule } from '@hemro/components/loading-bar';
import { HemroNavigationModule } from '@hemro/components/navigation';
import { UserGuideModule } from '@hemro/components/user-guide';
import { SchemeModule } from '@hemro/components/scheme';
import { SharedModule } from '@hemro/shared';
import { FooterModule } from 'app/layout/common/footer/footer.module';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { ModernLayoutComponent } from 'app/layout/layouts/horizontal/modern/modern.component';
import { NotificationModule } from 'app/layout/common/notification/notification.module';
import { NotificationFacadeService, NotificationPullingService } from '@hemro/lib/admin';

@NgModule({
    declarations: [
        ModernLayoutComponent,
    ],
    imports: [
        FooterModule,
        HemroFullscreenModule,
        HemroLoadingBarModule,
        HemroNavigationModule,
        SchemeModule,
        HttpClientModule,
        LanguagesModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        SearchModule,
        SharedModule,
        UserModule,
        SchemeModule,
        UserGuideModule,
        NotificationModule,
    ],
    exports: [
        ModernLayoutComponent,
    ],
    providers: [
        NotificationPullingService,
        NotificationFacadeService
    ]
})
export class ModernLayoutModule {
}
