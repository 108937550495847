import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterLink } from '@angular/router';
import { SharedModule } from '@hemro/shared';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { SettingsModule } from 'app/layout/common/settings/settings.module';
import { UserComponent } from 'app/layout/common/user/user.component';


@NgModule({
    declarations: [
        UserComponent,
    ],
    imports: [
        LanguagesModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatSnackBarModule,
        SettingsModule,
        SharedModule,
        SharedModule,
        TranslocoModule,
        RouterLink,
    ],
    exports     : [
        UserComponent,
    ],
})
export class UserModule
{
}
