import { Entity } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { Region } from '../region';
import { Store } from '../store';
import { BrewerBinding, Device as IDevice, DeviceStatusEntity } from './device.model';
import { Company } from '../company';


export class Device extends Entity<IDevice> implements IDevice
{
    public assemblyDate!: string;
    public bindingCode!: string;
    public bindings!: BrewerBinding[];
    public change!: Required<Change>;
    public companyId!: number;
    public created!: Required<Change>;
    public deviceId!: string;
    public firmwareVersion!: number;
    public fixedFirmware!: string;
    public hmiHwProductId!: string;
    public lastOnline!: Date;
    public name!: string;
    public productionDate!: string;
    public serial!: string;
    public status!: DeviceStatusEntity;
    public storeId!: number;
    public subType!: string;
    public testDevice!: boolean;
    public type!: string;
    public distributorId!: string;

    public store!: Store;
    public region!: Region;
    public company!: Company;

    public constructor(initialData: IDevice)
    {
        super(initialData);

        if (!this.store) {
            this.store = null as never;
        }
        if (!this.region) {
            this.region = null as never;
        }
        if (!this.distributorId) {
            this.distributorId = ' ' as string;
        }
    }

    public static formatVersion(num: number): string
    {
        const nStr = num.toString();

        // Check size of input
        if (nStr.length < 6) {
            console.warn(`The number ${num} has less than 6 digits, unable to format.`);
            return nStr;
        }

        try {
            // Extract parts of the number
            const thirdPart  = Number(nStr.slice(-4));
            const secondPart = Number(nStr.slice(-7, -4));
            const firstPart  = Number(nStr.slice(0, -7));
            return `${firstPart}.${secondPart}.${thirdPart}`;
        }
        catch (error) {
            console.warn(`Error occurred while formatting the number ${num}: ${(error as Error).message}`);
            return nStr;
        }
    }

    //device Entity<IDevice> implementation

    public get key(): string
    {
        return String(this.deviceId);
    }

    public override toJson(): IDevice
    {
        return this.toJsonWithKeys(
            [
                'companyId',
                'deviceId',
                'fixedFirmware',
                'name',
                'storeId',
                'testDevice',
                'company',
                'region',
                'distributorId'
            ],
            {},
        );
    }

    public override updateFromJson(data: IDevice): void
    {
        this.setFromJson(data, [
            'assemblyDate',
            'bindingCode',
            'bindings',
            'change',
            'companyId',
            'created',
            'deviceId',
            'fixedFirmware',
            'hmiHwProductId',
            'name',
            'productionDate',
            'serial',
            'status',
            'store',
            'storeId',
            'subType',
            'testDevice',
            'type',
            'company',
            'region',
            'distributorId'
        ], undefined, {
            created       : (created: Change) =>
            {
                if (created) {
                    created.date = new Date(created.date as never);
                }
                return created;
            },
            change        : (change: Change) =>
            {
                if (change) {
                    change.date = new Date(change.date as never);
                }
                return change;
            },
            store         : (store: Store) => new Store(store),
            testDevice    : (testDevice?: boolean) => !!testDevice,
            distributorId : (distributorId?: string) => distributorId ?? ' ',
        });
    }

    //enddevice

    public override toString(): string
    {
        return this.deviceId;
    }
}
