import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RegionRoles, UserService } from '@hemro/lib/profile';
import { RegionDetailsComponent } from 'app/modules/admin/region/comp/region-details/region-details.component';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class CanDeactivateRegionDetails implements CanDeactivate<RegionDetailsComponent>
{
    canDeactivate(
        component: RegionDetailsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        // Get the next route
        let nextRoute: ActivatedRouteSnapshot = nextState.root;
        while (nextRoute.firstChild) {
            nextRoute = nextRoute.firstChild;
        }

        if (!nextState.url.includes('/region')) {
            return true;
        }

        if (nextState.url.includes('/details')) {
            return true;
        }
        else {
            component.close();
            return true;
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class CanActivateRegion implements CanActivate
{
    constructor(
        private readonly _userService: UserService,
    )
    {
    }

    public canActivate(
        route?: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): boolean
    {
        return this._userService.hasRole(...RegionRoles.displayRegions);
    }
}
