import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HemroConfirmDialogModule } from '@hemro/lib/components';
import { YukawaAlertModule } from '@yukawa/chain-base-angular-comp/alert';
import { SharedModule as LibSharedModule } from '@yukawa/chain-base-angular-comp/shared';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HemroConfirmDialogModule,
        YukawaAlertModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LibSharedModule,
        HemroConfirmDialogModule,
        YukawaAlertModule,
    ],
})
export class SharedModule
{
}
