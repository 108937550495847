<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">

        <mat-icon>account_circle</mat-icon>

    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <div class="px-3 py-1">
        <span class="flex flex-col leading-none">
            <span transloco="AUTH.SIGNED_IN_AS"></span>
            <span class="mt-1.5 text-md font-bold">{{username}}</span>
            <span class="mt-1.5 text-md">{{userEmail}}</span>
        </span>

        <span class="mt-1.5 text-md font-medium flex flex-row" *ngIf="companyName">
            <mat-icon class="inline-block align-middle icon-size-5 mr-1.5">corporate_fare</mat-icon>
            {{companyName}}
        </span>
    </div>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="'profile/' + profileId">
        <mat-icon>account_circle</mat-icon>
        <span transloco="AUTH.PROFILE"></span>
    </button>
<!--    <mat-divider class="my-2"></mat-divider>-->
<!--    <button mat-menu-item (click)="toggleSettings()">-->
<!--        <mat-icon>settings</mat-icon>-->
<!--        <span transloco="SETTINGS.THEME"></span>-->
<!--    </button>-->

    <mat-divider class="my-2" *ngIf="!languages.hidden"></mat-divider>
    <languages [displayCaption]="true" #languages></languages>

    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()" name="logout">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span transloco="AUTH.LOGOUT.LOGOUT"></span>
    </button>
</mat-menu>
