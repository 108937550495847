import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { CloudRecipeDeviceTableEntry } from './cloud-recipe-device-table-entry.model';
import { CloudRecipeDeviceDatasource } from './cloud-recipe-device-datasource.service';
import { DeviceFilter } from '@hemro/lib/domain';
import { IDevice } from '../device';

export class CloudRecipeDeviceTableStore extends QueryTableStore<IDevice, IDevice, CloudRecipeDeviceTableEntry, DeviceFilter, CloudRecipeDeviceDatasource> {
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: DeviceFilter) {
        super(paginator, sort, tableFilter, CloudRecipeDeviceTableEntry, AppInjector.get(CloudRecipeDeviceDatasource));
    }
}
