<div class="flex flex-row space-x-4 w-full md:w-auto">
    <mat-form-field
        class="mb-2 transition-all"
        [ngClass]="{
                    'w-40': selectedCompany,
                    'w-25': !selectedCompany,
                    'animate-pulse': companies.length === 0
                }">
        <mat-label transloco="COMPANY.FILTER"></mat-label>
        <input type="text" matInput readonly [placeholder]="'COMPANY.FILTER' | transloco"
               [value]="selectedCompany?.info?.name || null"
               cdkOverlayOrigin #trigger="cdkOverlayOrigin"
               (click)="showSearchAutocomplete = !showSearchAutocomplete; setInputFocused()"/>
    </mat-form-field>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="showSearchAutocomplete"
        cdkConnectedOverlayWidth="20%"
        [cdkConnectedOverlayPositions]="positions"
        (backdropClick)="toggleAutoCompletePanel()"
        (overlayOutsideClick)="showSearchAutocomplete = false">
        <form class="w-full overflow-hidden flex flex-col mat-elevation-z2 rounded-bl-md rounded-br-md max-h-[65vh]">
            <mat-form-field class="transition-all w-full search-company">
                <mat-icon class="icon-size-5" [svgIcon]="'hemro:search-icon'" matPrefix></mat-icon>
                <input matInput
                       #search
                       [formControl]="searchControl"
                       [placeholder]="'COMPANY.SEARCH' | transloco">
            </mat-form-field>

            <div class="overflow-y-auto w-full">
                <ng-container *ngIf="companies.length > 0; else noEntries">
                    <mat-option
                        *ngIf="this.selectedCompany"
                        [value]="null" transloco="BUTTON.CLEAR_FILTER"
                        class="text-base"
                        (click)="onSelectionChange(null)">
                    </mat-option>
                    <mat-option *ngFor="let _company of companies;trackBy:trackByFn" [value]="_company"
                                [id]="_company.companyId.toString()"
                                (click)="onSelectionChange(_company)"
                                class="text-base">
                        {{ _company.info.name }}
                    </mat-option>
                </ng-container>
            </div>

            <button *ngIf="loadMoreVisible"
                    (click)="loadMoreCompanies()"
                    type="button"
                    class="flex justify-between items-center w-full py-2 px-4 border-t-2">
                <span transloco="BUTTON.LOAD_MORE"></span>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
        </form>

        <ng-template #noEntries>
            <mat-option [disabled]="true">
                {{ 'QUERY_TABLE.NO_ENTRIES' | transloco }}
            </mat-option>
        </ng-template>
    </ng-template>
</div>
