import { Injectable } from '@angular/core';
import { StoreFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { StoreTableEntry } from './store-table-entry.model';
import { Store } from './store.entity';
import { Store as IStore } from './store.model';
import { StoreService } from './store.service';


@Injectable({
    providedIn: 'root',
})
export class StoreDatasource extends QueryTableDatasource<Store, IStore, StoreTableEntry, StoreFilter>
{
    companyColumnDisplayed: boolean = true;

    constructor()
    {
        super(StoreTableEntry, {
            loadRegion: true,
            loadCompany: true
        });
    }

    public async read(filter?: StoreFilter): Promise<Array<Store>>
    {
        this.query = await firstValueFrom(AppInjector.get(StoreService).queryStore({
            ...this.defaultFilter,
            ...filter,
        }));
        return this.query.items;
    }

    public async create(entry: StoreTableEntry): Promise<StoreTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(StoreService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: StoreTableEntry, store?: Partial<Store>): Promise<StoreTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Store ${entry.id} does not exist.`);
        }

        if (store) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(StoreService).merge(new Store({
                ...store,
                storeId: entry.entity.storeId,
            } as Store))));
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: StoreTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Store ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(StoreService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(storeId: number): Promise<StoreTableEntry>
    {
        return this.newEntry((await firstValueFrom(AppInjector.get(StoreService).queryStore({
            ...this.defaultFilter,
            storeId,
        }).pipe(
            map((query) =>
            {
                if (query.items.length === 0) {
                    throw new QueryTableDatasourceException(`Store ${storeId} does not exist.`);
                }
                return query.items[0];
            }),
        ))));
    }
}
