import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HemroDrawerComponent } from '@hemro/components/drawer/drawer.component';


@NgModule({
    declarations: [
        HemroDrawerComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        HemroDrawerComponent
    ]
})
export class HemroDrawerModule
{
}
