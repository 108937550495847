import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
    selector: '[typewriter]'
})
export class TypewriterDirective implements OnInit {
    @Input() message: string;
    @Input() typingSpeed: number = 100;
    @Output() typingStatus = new EventEmitter<boolean>();
    @Output() keepScrollDown = new EventEmitter<boolean>();
    @Output() stopTyping = new EventEmitter<string>();

    private cursorElement: HTMLElement;
    private typingInterval: any;
    private isTyping: boolean = true;
    private currentText: string = '';

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit(): void {
        if (this.message) {
            this.createCursor();
            this.typeText(this.message, this.typingSpeed);
        }
    }

    createCursor(): void {
        this.cursorElement = this.renderer.createElement('span');
        this.renderer.addClass(this.cursorElement, 'typewriter-cursor');
        this.renderer.setProperty(this.cursorElement, 'innerHTML', '|');
        this.renderer.appendChild(this.el.nativeElement, this.cursorElement);
    }

    typeText(text: string, speed: number): void {
        const words = text.split(' ');
        let wordIndex = 0;

        this.typingStatus.emit(true);

        this.typingInterval = setInterval(() => {
            if (!this.isTyping) {
                clearInterval(this.typingInterval);
                return;
            }

            if (wordIndex < words.length) {
                this.currentText += (wordIndex > 0 ? ' ' : '') + words[wordIndex];
                this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.currentText);
                this.renderer.appendChild(this.el.nativeElement, this.cursorElement);
                this.keepScrollDown.emit(true);
                wordIndex++;
            } else {
                clearInterval(this.typingInterval);
                this.typingStatus.emit(false);
                this.keepScrollDown.emit(false);
                this.renderer.setStyle(this.cursorElement, 'display', 'none');
            }
        }, speed);
    }


    onStopTyping(): void {
        this.isTyping = false;
        if (this.typingInterval) {
            clearInterval(this.typingInterval);
        }
        this.typingStatus.emit(false);
        this.stopTyping.emit(this.currentText);
        this.renderer.setStyle(this.cursorElement, 'display', 'none');
    }
}
