import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { RecipeRoles, UserService } from '@hemro/lib/profile';
import { SessionService } from '@yukawa/chain-main-angular-session';

@Injectable({
    providedIn: 'root',
})
export class CanActivateRecipe implements CanActivate
{
    constructor(
        private readonly _userService: UserService,
        private readonly _sessionService: SessionService,
    )
    {
    }

    public canActivate(
        route?: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): boolean
    {
        return RecipeRoles.displayRecipes.some(role => !!this._sessionService.session?.user?.hasRole(role));
    }
}
