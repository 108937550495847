import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Observable } from 'rxjs';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Notification, NotificationFilter } from './notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService extends RestAspect {
    constructor(
        http: HttpClient,
        configService: ConfigService,
    ) {
        super(http, configService, `${configService.getBaseUrl()}/admin-service/notification`);
    }

    queryNotifications(filter: NotificationFilter): Observable<QueryResult<Notification>>
    {
        return this.http.post<QueryResult<Notification>>(`${this.formatServiceUrl()}/query`, filter);
    }

    markAsRead(notificationIds: number[]): Observable<Notification>
    {
        return this.http.post<Notification>(`${this.formatServiceUrl()}/markRead`, { notificationIds });
    }

    unreadCount(): Observable<number>
    {
        return this.http.get<number>(`${this.formatServiceUrl()}/unreadCount`);
    }
}
