<!-- Loading bar -->
<hemro-loading-bar></hemro-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <hemro-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container hemroVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 pl-4 pr-8">
                <mat-icon [svgIcon]="'hemro:logo'" class="icon-size-24"></mat-icon>
            </div>
        </ng-container>
    </hemro-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-12 lg:h-14 xl:h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-hemro-darkerBlue print:hidden overflow-hidden">

        <!--<ng-container [ngTemplateOutlet]="logoBackground"
                      [ngTemplateOutletContext]="{ className: 'opacity-20 contrast-125', y: '-68%' }"></ng-container>-->

        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <!-- Light version -->
                    <mat-icon [svgIcon]="'hemro:logo'" class="dark:hidden icon-size-18"></mat-icon>
                    <!-- Dark version -->
                    <mat-icon [svgIcon]="'hemro:logo'" class="hidden dark:flex icon-size-18"></mat-icon>
                </div>
                <mat-icon [svgIcon]="'hemro:icon'" class="flex lg:hidden w-8"></mat-icon>
            </div>
            <!-- Horizontal navigation -->
            <hemro-horizontal-navigation
                class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></hemro-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--<languages></languages>-->
            <!--<hemro-scheme></hemro-scheme>-->
            <button
                class="relative"
                mat-icon-button
                (click)="toggleNotifications()">
                <mat-icon [svgIcon]="'heroicons_outline:notification'"></mat-icon>
                <span class="notification-badge" *ngIf="unreadCount$ | async as count"
                      [ngClass]="!!count ? 'visible' : 'invisible'">
                   {{count}}
                </span>
            </button>
            <hermo-user-guide></hermo-user-guide>
            <hemro-fullscreen class="hidden md:block"></hemro-fullscreen>
            <!-- <shortcuts></shortcuts> -->

            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-row flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
        <notifications></notifications>
    </div>

    <!-- Footer -->
    <footer class="sm:h-8 lg:h-10 px-4 md:px-6 bg-card dark:bg-transparent"></footer>

</div>

