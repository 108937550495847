import { Injectable } from '@angular/core';
import { InvitationFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { InvitationTableEntry } from './invitation-table-entry.model';
import { Invitation } from './invitation.model';
import { InvitationService } from './invitation.service';


@Injectable({
    providedIn: 'root',
})
export class InvitationDatasource extends QueryTableDatasource<Invitation, Invitation, InvitationTableEntry, InvitationFilter>
{
    constructor()
    {
        super(InvitationTableEntry);
    }

    public async read(filter?: InvitationFilter): Promise<Array<Invitation>>
    {
        this.query = await firstValueFrom(AppInjector.get(InvitationService).queryInvitation({
            ...this.defaultFilter,
            ...filter,
        }));
        return this.query.items.map(invitation => ({
                ...invitation,
                store: invitation.store ?? {},
                region: invitation.region ?? {}
            })
        );
    }

    public async create(entry: InvitationTableEntry): Promise<InvitationTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(InvitationService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: InvitationTableEntry, invitation?: Partial<Invitation>): Promise<InvitationTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Invitation ${entry.id} does not exist.`);
        }

        /*if (invitation) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(InvitationService).merge(new Invitation({
                ...invitation,
                invitationId: entry.entity.invitationId,
            } as Invitation))) as Invitation);
        }*/

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: InvitationTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Invitation ${entry.id} does not exist.`);
        }

        //await lastValueFrom(AppInjector.get(InvitationService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(invitationId: number): Promise<InvitationTableEntry>
    {
        return this.newEntry((await firstValueFrom(AppInjector.get(InvitationService).queryInvitation({
            invitationId,
        }).pipe(
            map((query) =>
            {
                if (query.items.length === 0) {
                    throw new QueryTableDatasourceException(`Invitation ${invitationId} does not exist.`);
                }
                return query.items[0];
            }),
        ))));
    }
}
