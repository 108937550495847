import {Change, ChangeFilter, Changed, Created, EntityFilter} from '@yukawa/chain-base-angular-domain';
import {RecipeMode} from './event';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.recipe.CloudRecipe <br/>
*/
export interface CloudRecipe extends  Changed, Created{

/**
*	@example Tasty
*/
bean?: string;

/**
*	@example 2.5
*/
brewRatio?: number;

/**
*	@example 9200
*/
brewTemperature?: number;

/**
*	@example 14000
*/
brewTime?: number;

/**
*	@example 14000
*/
brewWeight?: number;
change?: Change;
cloudRecipeId?: number;

/**
*	@example 23
*/
coarseness?: number;
companyId?: number;
created?: Change;

/**
*	@example 4500
*/
grindTime?: number;

/**
*	@example 14000
*/
grindWeight?: number;

/**
*	values: [SingleCup, SingleCupLong, DoubleCup, DoubleCupLong]<br/>
*/
icon?: string;

/**
*	@example Gbt
*/
mode?: RecipeMode;

/**
*	values: [E65WGbS, E65TGbS, E80WGbS, E80TGbS]<br/>
*	@example E65TGbS
*/
model?: string;

/**
*	@example Trial Recipe
*/
name?: string;
status?: CloudRecipeStatus;

/**
*	values: [SingleShot, SingleShotLong, DoubleShot, DoubleShotLong]<br/>
*/
type?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.recipe.CloudRecipeFilter <br/>
*/
export interface CloudRecipeFilter extends  EntityFilter{
cloudRecipeId?: number;
companyId?: number;
created?: ChangeFilter;

/**
*	values: [E65WGbS, E65TGbS, E80WGbS, E80TGbS]<br/>
*	@example E65TGbS
*/
model?: string;
status?: CloudRecipeStatus[];
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.recipe.CloudRecipeSlot <br/>
*/
export interface CloudRecipeSlot extends  Changed{
change?: Change;
recipe?: CloudRecipe;

/**
*	values: [range[0, 5]]<br/>
*	@example 2
*/
slot?: number;
slotId?: number;
storeId?: number;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.recipe.CloudRecipeSlotFilter <br/>
*/
export interface CloudRecipeSlotFilter extends  EntityFilter{
recipe?: CloudRecipeFilter;
slot?: number;
slotId?: number;
storeId?: number;
}

export enum CloudRecipeStatus {
DRAFT = "DRAFT",
PUBLISHED = "PUBLISHED",
OBSOLETE = "OBSOLETE"
}

