import { IProfile, UserInfo } from '@hemro/lib/profile';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change } from '@yukawa/chain-base-angular-domain';
import { PlainObject, StringKeys } from 'simplytyped';
import { Manager } from './manager.entity';
import { Manager as IManager } from './manager.model';


export class ManagerTableEntry extends QueryTableEntry<Manager, IManager>
{
    static viewConfig: IManager = {
        managedId: 0,
        profileId: '',
        profile  : {
            user: {
                userId  : '',
                username: '',
            } as UserInfo,
        } as IProfile,
        change   : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };
    override id: string = '';

    public constructor(
        manager: IManager = ManagerTableEntry.viewConfig,
    )
    {
        super(
            manager instanceof Manager ? manager : new Manager(manager),
            manager?.managedId, manager?.profile?.user?.username,
        );
        this.id = manager?.profile?.user?.userId ?? manager.profileId;
    }

    public get viewConfig(): IManager
    {
        return ManagerTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'MANAGER.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = IManager>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'Region';

        switch ((key as StringKeys<IManager> | StringKeys<IProfile>)) {
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'managedId':
            case 'profileId':
                return;
            case 'profile':
                this.mapDetails<IProfile>(details, value, 'user', {
                    ...detail,
                    group: key,
                });
                return;
            case 'user':
                this.mapDetails<UserInfo>(details, value, 'username', {
                    ...detail,
                    type      : 'text',
                    group     : 'profile.' + key,
                    tableGroup: false,
                });
                this.mapDetails<UserInfo>(details, value, 'firstName', {
                    ...detail,
                    type      : 'text',
                    group     : 'profile.' + key,
                    canEdit   : true,
                    tableGroup: false,
                });
                this.mapDetails<UserInfo>(details, value, 'lastName', {
                    ...detail,
                    type      : 'text',
                    group     : 'profile.' + key,
                    canEdit   : true,
                    tableGroup: false,
                });
                return;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
