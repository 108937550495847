import { Component, OnInit } from '@angular/core';
import { LibService } from './lib.service';


@Component({
    selector: 'lib-lib',
    template: `
        <p>
            lib works!
        </p>
    `,
    styles  : [],
})
export class LibComponent implements OnInit
{

    constructor(
        private libService: LibService,
    )
    {
    }

    ngOnInit(): void
    {
    }

}
