import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Country } from '../model';


@Injectable({
    providedIn: 'root'
})
export class CommonService {
    public constructor(private http: HttpClient)
    {
    }

    loadCountries(): Observable<Country[]>
    {
        return this.http.get<Country[]>('api/common/countries');
    }
}
