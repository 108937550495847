import { Validators } from '@angular/forms';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Address, Change, Person } from '@yukawa/chain-base-angular-domain';
import { cloneDeep } from 'lodash-es';
import { PlainObject, StringKeys } from 'simplytyped';
import { GroupService } from './group.service';
import { User } from './user.model';


export class UserTableEntry extends QueryTableEntry<User>
{
    static viewConfig: User = {
        userId       : '',
        username     : '',
        person       : {
            addresses  : [
                {
                    addressId  : '',
                    city       : '',
                    countryCode: '',
                    houseNumber: '',
                    street     : '',
                    type       : 'main',
                    zipCode    : '',
                },
            ],
            change     : {
                date : new Date(),
                notes: '',
                user : '',
            },
            companyName: '',
            created    : {
                date : new Date(),
                notes: '',
                user : '',
            },
            email      : '',
            firstName  : '',
            lang       : '',
            lastName   : '',
            mobile     : '',
            phoneNumber: '',
            role       : '',
            salutation : '',
            shortName  : '',
            title      : '',
            vatNumber  : '',
        },
        account      : {
            details     : {},
            credentials : {
                username: '',
                password: '',
                orgId   : '',
            },
            roleContexts: [
                {
                    orgId: '',
                    roles: [''],
                },
            ],
            roles       : [''],
            status      : {
                accountNonExpired    : true,
                accountNonLocked     : true,
                credentialsNonExpired: true,
                enabled              : true,
            },
        },
        defaultOrgId : 'yuk',
        groupContexts: [
            {
                groups: ['USERS'],
                orgId : '',
                userId: '',
            },
        ],
        details      : {},
        owner        : {
            groups: [],
            user  : '',
        },
        created      : {
            date : new Date(),
            notes: '',
            user : '',
        },
        change       : {
            date : new Date(),
            notes: '',
            user : '',
        },
    };

    public constructor(
        user: User = UserTableEntry.viewConfig,
    )
    {
        super(user, user?.userId, user?.username);

        if (user.person.addresses.length === 0) {
            user.person.addresses.push(cloneDeep(UserTableEntry.viewConfig.person.addresses[0]));
        }

        if (user.created) {
            user.created.date = new Date(user.created.date);
        }
        if (user.change) {
            user.change.date = new Date(user.change.date);
        }
    }

    public get viewConfig(): User
    {
        return UserTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string
    {
        return 'USER.';
    }

    public override init(): void
    {
        super.init();
    }

    protected override mapDetails<TKey = User>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void
    {
        let type: EntryDetailType;
        let value         = item?.[key];
        let options       = new Array<ISelectOption>();
        detail.entityName = 'User';

        switch ((key as StringKeys<User> | StringKeys<Person> | StringKeys<Address>)) {
            case 'account':
            case 'details':
            case 'owner':
                return;
            case 'change':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                });
                return;
            case'defaultOrgId':
                type = 'text';
                if (!value) {
                    value = 'ngx';
                }
                detail.canEdit  = true;
                detail.required = true;
                break;
            case 'groupContexts':
                type              = 'multiselect';
                detail.canEdit    = true;
                detail.required   = true;
                detail.sortable   = false;
                detail.validators = [Validators.minLength(1)];
                if (!value) {
                    value = ['USERS'];
                }
                else {
                    value = value[0].groups;
                }
                const groups = AppInjector.get(GroupService).groups;
                options      = groups.map(group => ({
                    name : group.info?.name as string,
                    value: group.name,
                }));
                break;
            case 'person':
                detail.entityName = 'Person';
                this.mapDetails<Person>(details, value, 'email', {
                    ...detail,
                    group      : 'person',
                    canEdit    : true,
                    required   : true,
                    showInTable: false,
                    validators : [Validators.email],
                });
                this.mapDetails<Person>(details, value, 'firstName', {
                    ...detail,
                    group   : 'person',
                    required: true,
                    canEdit : true,
                });
                this.mapDetails<Person>(details, value, 'lastName', {
                    ...detail,
                    group   : 'person',
                    required: true,
                    canEdit : true,
                });
                this.mapDetails<Person>(details, value, 'companyName', {
                    ...detail,
                    group  : 'person',
                    canEdit: true,
                });
                this.mapDetails<Person>(details, value, 'lang', {
                    ...detail,
                    group      : 'person',
                    canEdit    : true,
                    showInTable: false,
                });
                this.mapDetails<Person>(details, value, 'mobile', {
                    ...detail,
                    group      : 'person',
                    canEdit    : true,
                    showInTable: false,
                });
                this.mapDetails<Person>(details, value, 'phoneNumber', {
                    ...detail,
                    group      : 'person',
                    canEdit    : true,
                    showInTable: false,
                });
                detail.groupDirection = 'horizontal';
                detail.showInTable    = false;
                for (const _address of value.addresses) {
                    this.mapDetails<Address>(details, _address, 'street', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                        required  : false,
                    });
                    this.mapDetails<Address>(details, _address, 'houseNumber', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                        required  : false,
                    });
                    this.mapDetails<Address>(details, _address, 'zipCode', {
                        ...detail,
                        group       : key + '.addresses',
                        groupIndex  : 1,
                        groupByField: true,
                        tableGroup  : true,
                        canEdit     : true,
                        required    : false,
                    });
                    this.mapDetails<Address>(details, _address, 'city', {
                        ...detail,
                        group       : key + '.addresses',
                        groupIndex  : 1,
                        groupByField: true,
                        tableGroup  : true,
                        canEdit     : true,
                        required    : false,
                    });
                    this.mapDetails<Address>(details, _address, 'countryCode', {
                        ...detail,
                        group     : key + '.addresses',
                        groupIndex: 1,
                        tableGroup: true,
                        canEdit   : true,
                        required  : false,
                    });
                }
                return;
            case 'userId':
                type = 'text';
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key  : level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
