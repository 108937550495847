import { Component, OnInit } from '@angular/core';
import { LibService } from '@hemro/lib';
import { ConfigService } from '@yukawa/chain-base-angular-client';


@Component({
    selector   : 'app-angular-home',
    templateUrl: './angular-home.component.html',
    styleUrls  : ['./angular-home.component.scss'],
})
export class AngularHomeComponent implements OnInit
{
    title = 'admin';

    constructor(
        public readonly libService: LibService,
        public readonly configService: ConfigService,
    )
    {
    }

    ngOnInit(): void
    {
    }
}
