import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { HemroFullscreenModule } from '@hemro/components/fullscreen';
import { HemroLoadingBarModule } from '@hemro/components/loading-bar';
import { HemroNavigationModule } from '@hemro/components/navigation';
import { SchemeModule } from '@hemro/components/scheme';
import { SharedModule } from '@hemro/shared';
import { FooterModule } from 'app/layout/common/footer/footer.module';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { EnterpriseLayoutComponent } from 'app/layout/layouts/horizontal/enterprise/enterprise.component';


@NgModule({
    declarations: [
        EnterpriseLayoutComponent,
    ],
    imports: [
        FooterModule,
        HemroFullscreenModule,
        HemroLoadingBarModule,
        HemroNavigationModule,
        HttpClientModule,
        LanguagesModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        SchemeModule,
        SearchModule,
        SharedModule,
        UserModule,
    ],
    exports     : [
        EnterpriseLayoutComponent,
    ],
})
export class EnterpriseLayoutModule
{
}
