import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Profile, ProfileFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { CompanyManagerDatasource } from './company-manager-datasource.service';
import { IProfile, ProfileService } from '@hemro/lib/profile';
import { CompanyManagerTableEntry } from './company-manager-table-entry.model';
import { Observable } from 'rxjs';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';


export class CompanyManagerTableStore extends QueryTableStore<Profile, IProfile, CompanyManagerTableEntry, ProfileFilter, CompanyManagerDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: ProfileFilter)
    {
        super(paginator, sort, tableFilter, CompanyManagerTableEntry, AppInjector.get(CompanyManagerDatasource));
    }

    protected override queryEntries(): Observable<QueryResult<CompanyManagerTableEntry>>
    {
        const filter = this.findFilter();

        AppInjector.get(ProfileService).removeQueryCache(filter);

        return fromPromise(this.datasource.init(filter)) as Observable<QueryResult<CompanyManagerTableEntry>>;
    }
}
