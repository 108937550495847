import { ListKeyManagerOption } from '@angular/cdk/a11y';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CarouselSlide } from './carousel-slide';


@Component({
    selector   : 'app-carousel-slide',
    templateUrl: './carousel-slide.component.html',
    styleUrls  : ['./carousel-slide.component.scss'],
})
export class CarouselSlideComponent
    implements ListKeyManagerOption, CarouselSlide, OnInit
{
    @Input() public image: SafeStyle = null as never;
    @Input() public overlayColor     = '#00000040';
    @Input() public hideOverlay      = false;
    @Input() public disabled         = false; // implements ListKeyManagerOption

    @ViewChild(TemplateRef) public templateRef: TemplateRef<any> = null as never;

    constructor(public sanitizer: DomSanitizer)
    {
    }

    public ngOnInit(): void
    {
        if (this.image) {
            this.image = this.sanitizer.bypassSecurityTrustStyle(`url("${this.image}")`);
        }
    }
}
