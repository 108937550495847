import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HemroNavigationService, HemroVerticalNavigationComponent } from '@hemro/components/navigation';
import { HemroMediaWatcherService } from '@hemro/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NotificationsComponent } from 'app/layout/common/notification/notifications.component';
import { NotificationPullingService } from '@hemro/lib/admin';
import { ConfigService } from '@yukawa/chain-base-angular-client';


@Component({
    selector     : 'modern-layout',
    templateUrl  : './modern.component.html',
    styleUrls    : ['./modern.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModernLayoutComponent implements OnInit, OnDestroy
{
    @ViewChild(NotificationsComponent) notificationsComponent: NotificationsComponent;
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    unreadCount$: Observable<string>;


    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _hemroMediaWatcherService: HemroMediaWatcherService,
        private _hemroNavigationService: HemroNavigationService,
        private _notificationPullingService: NotificationPullingService,
        private _configService: ConfigService,
    )
    {
        this.unreadCount$ = this._notificationPullingService.unreadCount$;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) =>
            {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._hemroMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) =>
            {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        const durationInterval = Number(this._configService.getValue('notificationIntervalDurationMs'));
        this._notificationPullingService.startInterval(durationInterval);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this._notificationPullingService.stopInterval();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._hemroNavigationService.getComponent<HemroVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    toggleNotifications(): void {
        this.notificationsComponent.toggleDrawer();
    }
}
