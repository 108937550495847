import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { HemroNavigationService } from '@hemro/components/navigation/navigation.service';
import { HemroNavigationItem } from '@hemro/components/navigation/navigation.types';
import { hemroAnimations } from '@hemro/lib/animations';
import { HemroMediaWatcherService } from '@hemro/services/media-watcher';
import { HemroUtilsService } from '@hemro/services/utils/utils.service';
import { distinctUntilChanged, map, ReplaySubject, Subject, takeUntil } from 'rxjs';


@Component({
    selector       : 'hemro-horizontal-navigation',
    templateUrl    : './horizontal.component.html',
    styleUrls      : ['./horizontal.component.scss'],
    animations     : hemroAnimations,
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'hemroHorizontalNavigation',
})
export class HemroHorizontalNavigationComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() name: string;
    @Input() navigation: HemroNavigationItem[];

    onRefreshed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _hemroNavigationService: HemroNavigationService,
        private _hemroUtilsService: HemroUtilsService,
        private _hemroMmediaWatcherService: HemroMediaWatcherService,
    )
    {
        this.name = this._hemroUtilsService.randomId();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Navigation
        if ('navigation' in changes) {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Make sure the name input is not an empty string
        if (this.name === '') {
            this.name = this._hemroUtilsService.randomId();
        }

        // Register the navigation component
        this._hemroNavigationService.registerComponent(this.name, this);

        this._hemroMmediaWatcherService.onMediaChange$.pipe(
            takeUntil(this._unsubscribeAll),
            map(({ matchingAliases }) => matchingAliases.includes('md') && matchingAliases.length <= 2),
            distinctUntilChanged(),
        ).subscribe((isLg: boolean) =>
        {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Deregister the navigation component from the registry
        this._hemroNavigationService.deregisterComponent(this.name);

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Refresh the component to apply the changes
     */
    refresh(): void
    {
        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Execute the observable
        this.onRefreshed.next(true);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }
}
