import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProfileFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ProfileDatasource } from './profile-datasource.service';
import { ProfileTableEntry } from './profile-table-entry.model';
import { Profile } from './profile.entity';
import { IProfile } from './profile.model';
import { ProfileService } from './profile.service';


export class ProfileTableStore extends QueryTableStore<Profile, IProfile, ProfileTableEntry, ProfileFilter, ProfileDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: ProfileFilter)
    {
        super(paginator, sort, tableFilter, ProfileTableEntry, AppInjector.get(ProfileDatasource));
    }

    protected override queryEntries(): Observable<QueryResult<ProfileTableEntry>>
    {
        const filter = this.findFilter();

        //this.applyFilter(filter);

        AppInjector.get(ProfileService).removeQueryCache(filter);

        return fromPromise(this.datasource.init(filter)) as Observable<QueryResult<ProfileTableEntry>>;
    }

}
