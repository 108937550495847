import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChatFacadeService, MessageBody } from '@hemro/lib/admin';
import { combineLatest, distinctUntilChanged, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'chatbot',
    templateUrl: './chat-box.component.html',
    styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit, OnDestroy {
    @Input() hasFooter = false;
    @ViewChild('content') contentElement: ElementRef;

    opened = false;
    identify = (index: number, msg: MessageBody): string => msg.timeStamp;

    private _resetButtonVisible: boolean = false;
    #unsubscribeAll = new Subject<any>();

    get resetButtonVisible(): boolean {
        return this._resetButtonVisible;
    }

    get isTestInUsed$(): Observable<boolean> {
        return this._chatFacadeService.isTestInUsed$;
    }

    constructor(private _chatFacadeService: ChatFacadeService,
                private _cdr: ChangeDetectorRef) {
    }

    get messages$(): Observable<MessageBody[]> {
        return this._chatFacadeService.messages$;
    }

    get isEmpty$(): Observable<boolean> {
        return this._chatFacadeService.isEmpty$;
    }

    get loading$(): Observable<boolean> {
        return this._chatFacadeService.loading$;
    }

    ngOnInit(): void {
        combineLatest([
            this._chatFacadeService.isTyping$,
            this.isEmpty$,
            this.loading$
        ]).pipe(
            map(([isTyping, isEmpty, loading]) => !isTyping && !isEmpty && !loading),
            distinctUntilChanged(),
            takeUntil(this.#unsubscribeAll)
        ).subscribe((visible) => {
            this._resetButtonVisible = visible;
            this._cdr.detectChanges();
        });

        this._chatFacadeService.chatBoxOpened$.pipe(
            takeUntil(this.#unsubscribeAll)
        ).subscribe((opened: boolean) => {
            this.opened = opened;
        });
    }

    ngOnDestroy(): void {
        this.#unsubscribeAll.next(null);
        this.#unsubscribeAll.complete();
    }

    onSendMessage(message: string): void {
        this._chatFacadeService.sendMessage(message);
        this.onScrollDown(true);
    }

    onResetChat(): void {
        this._chatFacadeService.resetChat();
    }

    onScrollDown(scrollNeeded: boolean): void {
        if (scrollNeeded) {
            setTimeout(() => {
                this.contentElement.nativeElement.scrollTop = this.contentElement.nativeElement.scrollHeight;
            });
        }
    }

    onChangeChatBoxState(opened: boolean): void {
        this.opened = opened;
        this._chatFacadeService.setChatBoxState(opened);
    }

    onStopTestingChatbot(): void {
        this._chatFacadeService.stopTestingChatbot();
    }
}
