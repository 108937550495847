import { CommonModule } from '@angular/common';
import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { PlainObject } from 'simplytyped';
import { CarouselSlideComponent } from './carousel-slide/carousel-slide.component';
import { CarouselComponent } from './carousel.component';


@Injectable()
export class CarouselHammerConfig extends HammerGestureConfig
{
    override overrides: PlainObject = {
        pinch : { enable: false },
        rotate: { enable: false },
        swipe : { direction: Hammer.DIRECTION_HORIZONTAL },
    };

    override buildHammer(element: HTMLElement): HammerManager
    {
        const mc = new (window as any).Hammer(element) as HammerManager;

        for (const eventName in this.overrides) {
            if (eventName) {
                mc.get(eventName).set(this.overrides[eventName]);
            }
        }

        return mc;
    }
}

@NgModule({
    declarations: [CarouselComponent, CarouselSlideComponent],
    imports     : [CommonModule, MatButtonModule, MatIconModule, HammerModule],
    exports     : [CarouselComponent, CarouselSlideComponent],
})
export class CarouselModule
{
    static forRoot(): ModuleWithProviders<CarouselModule>
    {
        return {
            ngModule : CarouselModule,
            providers: [
                { provide: HAMMER_GESTURE_CONFIG, useClass: CarouselHammerConfig },
            ],
        };
    }
}
