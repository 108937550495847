import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { HemroRoles, UserService } from '@hemro/lib/profile';

@Injectable({
    providedIn: 'root',
})
export class CanActivateChatbot implements CanActivate
{
    constructor(
        private readonly _userService: UserService,
    )
    {
    }

    public canActivate(): boolean
    {
        return this._userService.hasRole(HemroRoles.admin);
    }
}
