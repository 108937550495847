import { IProfile, Profile } from '@hemro/lib/profile';
import { Entity } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { Manager as IManager } from './manager.model';


export class Manager extends Entity<IManager> implements IManager
{
    public change!: Required<Change>;
    public managedId!: number;
    public profile!: Profile;
    public profileId!: string;

    public constructor(initialData: IManager)
    {
        super(initialData);
    }

    //region Entity<IManager> implementation

    public get key(): string
    {
        return String(this.managedId);
    }

    public override toJson(): IManager
    {
        return this.toJsonWithKeys(
            [
                'change',
                'managedId',
                'profile',
                'profileId',
            ],
            {},
        );
    }

    public override updateFromJson(data: IManager): void
    {
        this.setFromJson(data, [
            'change',
            'managedId',
            'profile',
            'profileId',
        ], undefined, {
            change : (change: Change) =>
            {
                if (change) {
                    change.date = new Date(change.date as never);
                }
                return change;
            },
            profile: (profile: IProfile) => new Profile(profile),
        });
    }

    //endregion

    public override toString(): string
    {
        return `${this.profileId}`;
    }
}
