import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UserIconComponent } from './user-icon/user-icon.component';


@NgModule({
    declarations: [
        UserIconComponent,
    ],
    imports     : [
        CommonModule,
        MatIconModule,
    ],
    providers   : [],
    exports     : [
        UserIconComponent,
    ],
})
export class ProfileComponentsModule
{

}
