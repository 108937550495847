import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Firmware as IFirmware, FirmwareFilter } from '@hemro/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { SessionService } from '@yukawa/chain-main-angular-session';
import { Observable } from 'rxjs';
import { Firmware } from './firmware.model';


@Injectable({ providedIn: 'root' })
export class FirmwareService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
        private readonly sessionService: SessionService,
    )
    {
        super(http, configService, configService.formatUrl('firmwareUrl'));
    }

    loadByKey(firmwareId: string): Observable<Firmware>
    {
        return this.http.get<Firmware>(`${this.formatServiceUrl()}/?key=${firmwareId}`);
    }

    delete(firmware: Firmware): Observable<Firmware>
    {
        return this.http.delete<Firmware>(`${this.formatServiceUrl()}/?key=` + firmware.version);
    }

    create(firmware: Firmware): Observable<Firmware>
    {
        return this.http.post<Firmware>(this.formatServiceUrl('/create'), firmware);
    }

    merge(firmware: IFirmware): Observable<Firmware>
    {
        return this.http.post<Firmware>(this.formatServiceUrl('/merge'), firmware);
    }

    put(firmware: Firmware): Observable<Firmware>
    {
        return this.http.post<Firmware>(this.formatServiceUrl('/put'), firmware);
    }

    queryFirmware(filter: FirmwareFilter): Observable<QueryResult<Firmware>>
    {
        return super.query<Firmware>(this.formatServiceUrl('/query'), filter);
    }

    update(firmware: Firmware): Observable<Firmware>
    {
        return this.http.post<Firmware>(this.formatServiceUrl('/update'), firmware);
    }

    uploadBundle(firmware: Firmware, file: File, onProgress?: (progress: number) => void): Observable<void>
    {
        return new Observable<void>((subscriber) =>
        {
            const formData = new FormData();
            formData.append('file', file, file.name);

            const xhr       = new XMLHttpRequest();
            const authToken = this.sessionService.session?.user?.authToken.access_token;

            if (onProgress) {
                // Event listener for upload progress
                xhr.upload.addEventListener('progress', (event) =>
                {
                    if (event.lengthComputable) {
                        const progress = (event.loaded / event.total) * 100;
                        onProgress(progress);
                    }
                });
            }

            // Event listener for completion
            xhr.addEventListener('load', () =>
            {
                if (xhr.status === 200) {
                    subscriber.next();
                    subscriber.complete();
                }
                else {
                    subscriber.error(xhr.statusText);
                }
            });

            // Event listener for errors
            xhr.addEventListener('error', () =>
            {
                subscriber.error(xhr.statusText);
            });

            // Set up abort on cancel
            subscriber.add(() =>
            {
                xhr.abort();
            });

            // Open and send request
            xhr.open('POST', `${this.serviceUrl}/upload?versionText=${firmware.versionText}`);
            xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
            xhr.send(formData);
        });
    }

    downloadBundle(firmware: Firmware): Observable<Blob>
    {
        return this.http.get(`${this.serviceUrl}/download?versionText=${firmware.versionText}`, {
            responseType: 'blob',
        });
    }
}
