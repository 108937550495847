import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { countries as countriesData } from 'app/mock-api/common/country/data';
import { MockApiService } from '@yukawa/chain-base-angular-client';

@Injectable({
    providedIn: 'root'
})
export class CountryMockApi
{
    private _countries: any = countriesData;

    /**
     * Constructor
     */
    constructor(private _hemroMockApiService: MockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Countries - GET
        // -----------------------------------------------------------------------------------------------------
        this._hemroMockApiService
            .onGet('api/common/countries')
            .reply(() => [200, cloneDeep(this._countries)]);
    }
}
