<!-- Loading bar -->
<hemro-loading-bar></hemro-loading-bar>

<!-- Navigation -->
<hemro-vertical-navigation
    class="dark bg-indigo-800 text-white print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.futuristic"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container hemroVerticalNavigationHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0">
            <mat-icon [svgIcon]="'hemro:logo'" class="icon-size-24"></mat-icon>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container hemroVerticalNavigationFooter>
        <!-- User -->
        <div class="flex items-center w-full px-6 py-8 border-t">
            <user></user>
            <div class="flex flex-col w-full ml-4 overflow-hidden">
                <div
                    class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-80">
                    {{profile.displayName}}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap text-sm overflow-ellipsis overflow-hidden leading-normal text-current opacity-50">
                    {{user.person.email}}
                </div>
            </div>
        </div>
    </ng-container>
</hemro-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            class="mr-2"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!--<languages></languages>-->
            <hemro-scheme></hemro-scheme>
            <hemro-fullscreen class="hidden md:block"></hemro-fullscreen>
            <!-- <shortcuts></shortcuts> -->

        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <footer class="sm:h-14 px-4 md:px-6 bg-card dark:bg-transparent"></footer>

</div>

