import { NgModule } from '@angular/core';
import { InitModule } from '@yukawa/chain-base-angular-client';
import { SessionModule } from '@yukawa/chain-main-angular-session';
import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';
import { UserService } from './user.service';


@NgModule({
    imports  : [
        InitModule,
        SessionModule,
    ],
    providers: [
        AuthService,
        ProfileService,
        UserService,
    ],
})
export class ProfileModule
{
}
