import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SchemeComponent } from '@hemro/components/scheme/scheme.component';


@NgModule({
    declarations: [
        SchemeComponent,
    ],
    imports     : [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports     : [
        SchemeComponent,
    ],
})
export class SchemeModule
{
}
