import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { HemroDrawerComponent } from '@hemro/components/drawer';
import { NotificationFacadeService, NotificationGroup } from '@hemro/lib/admin';
import { distinctUntilChanged, fromEvent, map, Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements AfterViewInit, OnDestroy {
    @ViewChild('notificationDrawer', { read: HemroDrawerComponent }) notificationDrawer: HemroDrawerComponent;
    @ViewChild('notificationContainer') notificationContainer: ElementRef;

    onlyShowUnread = false;

    get notificationLoading$(): Observable<boolean> {
        return this.notificationFacadeService.loading$;
    }

    #unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(public notificationFacadeService: NotificationFacadeService) {
    }

    ngAfterViewInit(): void {
        fromEvent(this.notificationContainer.nativeElement, 'scroll')
            .pipe(
                map(({ target }) => target.offsetHeight + target.scrollTop + 100 >= target.scrollHeight),
                distinctUntilChanged(),
                takeUntil(this.#unsubscribeAll),
            ).subscribe((isAtBottom) => {
            if (isAtBottom) {
                this.notificationFacadeService.loadMoreNotifications();
            }
        });
    }

    ngOnDestroy(): void {
        this.#unsubscribeAll.next();
        this.#unsubscribeAll.complete();
        this.notificationFacadeService.resetNotificationState(true);
    }


    toggleDrawer(): void {
        this.notificationDrawer.toggle();
        this.notificationFacadeService.loadNotifications();
    }

    showUnreadOnly(): void {
        this.notificationFacadeService.toggleUnreadOnly(this.onlyShowUnread);
    }

    markNotificationAsRead(notificationId: number): void {
        this.notificationFacadeService.markAsRead(notificationId);
    }

    trackByFn(index: number, item: NotificationGroup): any {
        return item.date || index;
    }

    openedChanged(opened: boolean): void {
        if (!opened) {
            this.notificationFacadeService.resetNotificationState();
        }
    }
}
