import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { LibComponent } from './lib.component';


@NgModule({
    declarations: [
        LibComponent,
    ],
    imports     : [
        HttpClientModule,
    ],
    exports     : [
        LibComponent,
    ],
})
export class LibModule
{
}
