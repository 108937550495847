<div class="space-y-8" *ngIf="reader" @fadeIn>

    <div class="space-y-3">

        <h3>{{ reader.title }}</h3>

        <p class="text-sm text-black leading-3.6" *ngFor="let _section of reader.sections" [innerHtml]="_section"></p>

    </div>

    <ng-container *ngFor="let _section of reader.list">
        <div class="space-y-3">

            <h3>{{ _section.title }}</h3>

            <p class="text-sm text-black leading-3.6" [innerHTML]="_section.content"></p>

        </div>
    </ng-container>
</div>
