import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNavTabComponent } from './user-nav-tab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
    declarations: [UserNavTabComponent],
    imports: [CommonModule, MatTabsModule, RouterModule, TranslocoModule],
    exports: [UserNavTabComponent]
})
export class UserNavTabModule {
}
