import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { expandCollapse } from '@hemro/lib/animations';
import { Translation, TranslocoService } from '@ngneat/transloco';


export interface IConfirmPopupOption<T = unknown>
{
    name: string;
    value: T;
    color?: ThemePalette;
    cssClass?: string;
}

export interface IConfirmPopupOptions<T = unknown>
{
    options: Array<IConfirmPopupOption<T>>;
}

@Component({
    selector   : 'lib-confirm-popup',
    templateUrl: './confirm-popup.component.html',
    styleUrls  : ['./confirm-popup.component.scss'],
    animations : [expandCollapse],
})
export class ConfirmPopupComponent implements OnInit
{
    public show = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: IConfirmPopupOptions,
        private readonly _dialogRef: MatDialogRef<ConfirmPopupComponent>,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _translocoService: TranslocoService,
    )
    {
        data.options.forEach((option) =>
        {
            const translation = this._translocoService.getTranslation().get(_translocoService.getActiveLang()) as Translation;
            if (translation.hasOwnProperty(option.name)) {
                option.name = this._translocoService.translate(option.name);
            }
        });
    }

    get options(): Array<IConfirmPopupOption>
    {
        return this.data.options;
    }

    ngOnInit(): void
    {
    }

    public close(value: unknown): void
    {
        this.show = false;
        this._changeDetector.detectChanges();
        setTimeout(() =>
        {
            this._dialogRef.close(value);
        }, 300);
    }
}
