import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HemroRoles, UserService } from '@hemro/lib/profile';
import { FirmwareDetailsComponent } from 'app/modules/admin/firmware/comp/firmware-details/firmware-details.component';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class CanDeactivateFirmwareDetails implements CanDeactivate<FirmwareDetailsComponent>
{
    canDeactivate(
        component: FirmwareDetailsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        // Get the next route
        let nextRoute: ActivatedRouteSnapshot = nextState.root;
        while (nextRoute.firstChild) {
            nextRoute = nextRoute.firstChild;
        }

        if (!nextState.url.includes('/firmware')) {
            return true;
        }

        if (nextState.url.includes('/details')) {
            return true;
        }
        else {
            component.close();
            return true;
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class CanActivateFirmware implements CanActivate
{
    constructor(
        private readonly _userService: UserService,
    )
    {
    }

    public canActivate(
        route?: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): boolean
    {
        return this._userService.hasRole(...[
            HemroRoles.editFirmware,
            HemroRoles.uploadFirmware,
        ]);
    }
}
