import { Injectable } from '@angular/core';
import { FirmwareFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { lastValueFrom } from 'rxjs';
import { FirmwareTableEntry } from './firmware-table-entry.model';
import { Firmware } from './firmware.model';
import { FirmwareService } from './firmware.service';


@Injectable({
    providedIn: 'root',
})
export class FirmwareDatasource extends QueryTableDatasource<Firmware, Firmware, FirmwareTableEntry, FirmwareFilter>
{
    constructor()
    {
        super(FirmwareTableEntry);
    }

    public async read(filter?: FirmwareFilter): Promise<Array<Firmware>>
    {
        this.query = await lastValueFrom(AppInjector.get(FirmwareService).queryFirmware({
            ...this.defaultFilter,
            ...filter,
        }));
        return this.query.items;
    }

    public async create(entry: FirmwareTableEntry): Promise<FirmwareTableEntry>
    {
        try {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(FirmwareService).create({
                ...entry.entity,
                notes: entry.entity.notes || null as never,
            })), entry.file);
            this.entries.splice(0, 0, entry);
            return await this.update(entry);
        }
        catch (e) {
            return entry;
        }
    }

    public async update(entry: FirmwareTableEntry, firmware?: Partial<Firmware>): Promise<FirmwareTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Firmware file ${entry.id} does not exist.`);
        }

        if (firmware) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(FirmwareService).merge({
                ...firmware,
                notes      : entry.entity.notes || null as never,
                version    : entry.entity.version,
                versionText: entry.entity.versionText,
            } as Firmware)) as Firmware, entry.file);
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: FirmwareTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Firmware file ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(FirmwareService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(firmwareId: string): Promise<FirmwareTableEntry>
    {
        return this.newEntry(await lastValueFrom(AppInjector.get(FirmwareService).loadByKey(firmwareId)));
    }
}
