import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HemroLoadingBarModule } from '@hemro/components/loading-bar';
import { SharedModule } from '@hemro/shared';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';


@NgModule({
    declarations: [
        EmptyLayoutComponent,
    ],
    imports     : [
        RouterModule,
        HemroLoadingBarModule,
        SharedModule,
    ],
    exports     : [
        EmptyLayoutComponent,
    ],
})
export class EmptyLayoutModule
{
}
