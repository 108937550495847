<div
    #carouselContainer
    class="carousel"
    tabindex="0"
    [style.max-width]="maxWidth"
    [style.height]="!maintainAspectRatio ? '100%' : 'auto'"
>
    <ul
        #carouselList
        class="carousel-list"
        role="listbox"
        [style.flex-direction]="orientation === 'rtl' ? 'row-reverse' : 'row'"
        [style.height]="!maintainAspectRatio ? '100%' : 'auto'"
    >
        <li
            #carouselSlide
            *ngFor="let slide of slidesList"
            class="carousel-slide"
            role="option"
            [style.padding-bottom]="maintainAspectRatio && proportion ? proportion + '%': '0px'"
            [style.height]="!maintainAspectRatio && slideHeight ? slideHeight : '0px'"
            (panleft)="onPan($event, carouselSlide)"
            (panright)="onPan($event, carouselSlide)"
            (panend)="onPanEnd($event, carouselSlide)"
            (pancancel)="onPanEnd($event, carouselSlide)"
        >
            <ng-container [ngTemplateOutlet]="slide.templateRef"></ng-container>
        </li>
    </ul>

    <button
        *ngIf="!hideArrows"
        mat-icon-button
        type="button"
        tabindex="-1"
        [color]="color"
        [disabled]="!loop && currentIndex == 0"
        (click)="previous()"
    >
        <mat-icon
            *ngIf="svgIconOverrides?.arrowBack; else: defaultArrowBack"
            [svgIcon]="svgIconOverrides.arrowBack"
        ></mat-icon>
        <ng-template #defaultArrowBack>
            <mat-icon>arrow_back</mat-icon>
        </ng-template>
    </button>
    <button
        *ngIf="!hideArrows"
        mat-icon-button
        type="button"
        tabindex="-1"
        [color]="color"
        [disabled]="!loop && currentIndex == slidesList.length - 1"
        (click)="next()"
    >
        <mat-icon
            *ngIf="svgIconOverrides?.arrowForward; else: defaultArrowForward"
            [svgIcon]="svgIconOverrides.arrowForward"
        ></mat-icon>
        <ng-template #defaultArrowForward>
            <mat-icon>arrow_forward</mat-icon>
        </ng-template>
    </button>

    <div
        *ngIf="!hideIndicators"
        class="carousel-indicators"
        tabindex="-1"
        [style.flex-direction]="orientation === 'rtl' ? 'row-reverse' : 'row'"
    >
        <button
            *ngFor="let slide of slidesList; let i = index"
            type="button"
            tabindex="-1"
            mat-mini-fab
            [color]="color"
            [disabled]="i == currentIndex"
            (click)="slideTo(i)"
            (focus)="carouselContainer.focus()"
        ></button>
    </div>
</div>
