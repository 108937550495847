import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManagerFilter, ManagerKey, RegionManager, StoreFilter, StoreManager } from '@hemro/lib/domain';
import { CompanyRoles, UserService } from '@hemro/lib/profile';
import { AppInjector, ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { IManagerService } from '../manager';
import { Store } from './store.entity';


@Injectable({ providedIn: 'root' })
export class StoreService extends RestAspect implements IManagerService<StoreManager>
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('storeUrl'));
    }

    loadByKey(storeId: number): Observable<Store>
    {
        return this.http.get<Store>(`${this.formatServiceUrl()}/?key=${storeId}`);
    }

    delete(store: Store): Observable<Store>
    {
        return this.http.delete<Store>(`${this.formatServiceUrl()}/?key=` + store.storeId);
    }

    create(store: Store): Observable<Store>
    {
        return this.http.post<Store>(this.formatServiceUrl('/create'), store.toJson());
    }

    merge(store: Store): Observable<Store>
    {
        return this.http.post<Store>(this.formatServiceUrl('/merge'), store.toJson());
    }

    put(store: Store): Observable<Store>
    {
        return this.http.post<Store>(this.formatServiceUrl('/put'), store.toJson());
    }

    queryStore(filter: StoreFilter): Observable<QueryResult<Store>>
    {
        return super.query<Store>(this.formatServiceUrl('/query-managed'), filter);
    }

    update(store: Store): Observable<Store>
    {
        return this.http.post<Store>(this.formatServiceUrl('/update'), store.toJson());
    }

    //region IManagerService<StoreManager>

    assignManager(key: ManagerKey): Observable<RegionManager>
    {
        return this.http.post<RegionManager>(this.formatServiceUrl('/manager/create'), key);
    }

    queryManager(filter: ManagerFilter): Observable<QueryResult<RegionManager>>
    {
        return this.http.post<QueryResult<RegionManager>>(this.formatServiceUrl('/manager/query'), filter);
    }

    removeManager(key: ManagerKey): Observable<RegionManager>
    {
        return this.http.delete<RegionManager>(this.formatServiceUrl(`/manager/delete?managedId=${key.managedId}&profileId=${key.profileId}`));
    }

    assignBarista(key: ManagerKey): Observable<RegionManager>
    {
        return this.http.post<RegionManager>(this.formatServiceUrl('/barista/create'), key);
    }

    queryBarista(filter: ManagerFilter): Observable<QueryResult<RegionManager>>
    {
        return this.http.post<QueryResult<RegionManager>>(this.formatServiceUrl('/barista/query'), filter);
    }

    removeBarista(key: ManagerKey): Observable<RegionManager>
    {
        return this.http.delete<RegionManager>(this.formatServiceUrl(`/barista/delete?managedId=${key.managedId}&profileId=${key.profileId}`));
    }

    //endregion
}
