import {ChainEventBean, Change, ChangeFilter, Changed, Created, EntityFilter} from '@yukawa/chain-base-angular-domain';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.chat.ChatEvent <br/>
*/
export interface ChatEvent extends  ChainEventBean<string> {
model?: ChatModel;
sessionId?: string;
txId?: string;
userId?: string;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.chat.ChatFact <br/>
*/
export interface ChatFact extends  Changed, Created{
answer?: string;
change?: Change;
chatFactId?: number;
created?: Change;
model?: ChatModel;
question?: string;
status?: ChatFactStatus;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.chat.ChatFactFilter <br/>
*/
export interface ChatFactFilter extends  EntityFilter{
chatFactId?: number;
created?: ChangeFilter;
model?: ChatModel;
status?: ChatFactStatus;
statuses?: ChatFactStatus[];
}

export enum ChatFactStatus {
NEW = "NEW",
CHANGED = "CHANGED",
TRAINED = "TRAINED",
DELETED = "DELETED"
}

export enum ChatModel {
LIVE = "LIVE",
STAGE = "STAGE"
}

