import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGuideComponent } from './user-guide.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    UserGuideComponent
  ],
    imports: [
      CommonModule,
      MatIconModule,
      MatButtonModule,
      MatTooltipModule,
      RouterLink,
      MatDividerModule,
      MatMenuModule,
      TranslocoModule,
    ],
  exports: [UserGuideComponent]
})
export class UserGuideModule { }
