import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { Change } from '@yukawa/chain-base-angular-domain';
import { PlainObject, StringKeys } from 'simplytyped';
import { ChatFact } from './chat-fact.model';
import { ChatFactStatus, ChatModel } from '@hemro/lib/domain';

export class ChatFactTableEntry extends QueryTableEntry<ChatFact> {
    static viewConfig: ChatFact = {
        chatFactId: 0,
        question: '',
        answer: '',
        model: ChatModel.STAGE,

        created: {
            date: new Date(),
            notes: '',
            user: '',
        },
        change: {
            date: new Date(),
            notes: '',
            user: '',
        },
        status: ChatFactStatus.NEW,
    };

    public constructor(
        chatFact: ChatFact = ChatFactTableEntry.viewConfig,
    ) {
        super(chatFact, chatFact?.chatFactId, chatFact?.chatFactId?.toString());

        if (chatFact.created) {
            chatFact.created.date = new Date(chatFact.created.date);
        }
        if (chatFact.change) {
            chatFact.change.date = new Date(chatFact.change.date);
        }

        if (!chatFact.status) {
            chatFact.status = ChatFactStatus.NEW;
        }

        if (!chatFact.model) {
            chatFact.model = ChatModel.STAGE;
        }
    }

    public get viewConfig(): ChatFact {
        return ChatFactTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string {
        return 'CHATBOT_MANAGEMENT.';
    }

    public override init(): void {
        super.init();
    }

    protected override mapDetails<TKey = ChatFact>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void {
        let type: EntryDetailType = 'text';
        const value = item?.[key];
        const options = new Array<ISelectOption>();
        detail.entityName = 'ChatFact';

        switch ((key as StringKeys<ChatFact>)) {
            case 'change':
                detail.entityName = 'Change';
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                    groupIndex: 1,
                    groupByField: true,
                    showInTable: true,
                    tableGroup: true,
                    canEdit: false,
                    required: true,
                    showInDetails: false,
                });
                this.mapDetails<Change>(details, value, 'user', {
                    ...detail,
                    group: key,
                    groupIndex: 1,
                    tableGroup: false,
                    showInTable: true,
                    canEdit: false,
                    required: false,
                    showInDetails: false,
                });
                return;
            case 'created':
                this.mapDetails<Change>(details, value, 'date', {
                    ...detail,
                    group: key,
                    showInTable: true,
                });
                return;
            case 'question':
            case 'answer':
                type = 'multiline';
                detail.canEdit = true;
                detail.showInTable = true;
                detail.required = true;
                detail.rows = 3;
                break;
            case 'model':
            case 'chatFactId':
                type = 'text';
                detail.showInTable = false;
                break;
            case 'status':
                detail.sortable = false;
                detail.showInTable = true;
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key: level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
