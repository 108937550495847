import { Injectable } from '@angular/core';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { UserFilter } from '@yukawa/chain-main-angular-core';
import { lastValueFrom, map } from 'rxjs';
import { UserTableEntry } from './user-table-entry.model';
import { User } from './user.model';
import { UserService } from './user.service';


@Injectable({
    providedIn: 'root',
})
export class UserDatasource extends QueryTableDatasource<User, User, UserTableEntry, UserFilter>
{
    constructor()
    {
        super(UserTableEntry);
    }

    public async read(filter?: UserFilter): Promise<Array<User>>
    {
        this.query = await lastValueFrom(AppInjector.get(UserService).queryUser({
            withGroupContexts: true,
            ...filter,
        }));
        return this.query.items;
    }

    public async create(entry: UserTableEntry): Promise<UserTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(UserService).saveUser({
            ...entry.entity,
            username: entry.entity.person.email,
        })));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: UserTableEntry, user?: Partial<User>): Promise<UserTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`User file ${entry.id} does not exist.`);
        }

        if (user) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(UserService).merge({
                ...user,
                username: entry.entity.username,
            } as User)) as User);

            if (user.account?.credentials) {
                await lastValueFrom(AppInjector.get(UserService).changePassword(user.account.credentials));
            }
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: UserTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`User ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(UserService).deleteUser(entry.entity.username));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(username: string): Promise<UserTableEntry>
    {
        return this.newEntry((await lastValueFrom(AppInjector.get(UserService).queryUser({
            username,
            withGroupContexts: true,
        }).pipe(
            map((query) =>
            {
                if (query.items.length === 0) {
                    throw new QueryTableDatasourceException(`User ${username} does not exist.`);
                }
                return query.items[0];
            }),
        ))));
    }
}
