import {
    EntryDetailType,
    IQueryTableEntryDetail,
    ISelectOption,
    QueryTableEntry,
} from '@yukawa/chain-base-angular-comp/query-table';
import { PlainObject, StringKeys } from 'simplytyped';
import { Store } from '../store';
import { Company } from '../company';
import { Region } from '../region';
import { IDevice } from '../device';

export class CloudRecipeDeviceTableEntry extends QueryTableEntry<IDevice> {
    static viewConfig: IDevice = {
        serial        : '',
        deviceId      : '',
        hmiHwProductId: '',
        status        : {
            status    : {
                bundleVersion: '',
                discHealth     : 0,
                discUsageTime  : 0,
                espSwVersion   : '',
                hmiSwVersion   : '',
                motorOnTime    : 0,
                standbyActive  : false,
                wifiConnectTime: 0,
                wifiOnTime     : 0,
            },
            deviceDate: '',
            deviceId  : '',
            cloudDate : '',
        },
        type          : '',
        subType       : '',
        name          : '',
        assemblyDate  : '',
        created       : {
            date : new Date(),
            notes: '',
            user : '',
        },
        store         : {} as Store,
        testDevice    : false,
        productionDate: '',
        companyId     : 0,
        storeId       : 0,
        bindings      : [
            {
                brewerId : '',
                brewer   : {
                    serial: '',
                },
                grinderId: '',
                device   : {
                    deviceId: '',
                },
                created  : {
                    date : new Date(),
                    notes: '',
                    user : '',
                },
            },
        ],
        bindingCode   : '',
        fixedFirmware : '',
        company       : {} as Company,
        region        : {} as Region,
        change        : {
            date : new Date(),
            notes: '',
            user : '',
        },
        distributorId : ''
    };

    public constructor(
        device: IDevice = CloudRecipeDeviceTableEntry.viewConfig,
    ) {
        super(
            device,
            device?.deviceId,
            device?.name,
        );
    }

    public get viewConfig(): IDevice {
        return CloudRecipeDeviceTableEntry.viewConfig;
    }

    protected override get labelTranslationPrefix(): string {
        return 'RECIPE.DEVICE.';
    }

    public override init(): void {
        super.init();
    }

    protected override mapDetails<TKey = IDevice>(
        details: Map<string, IQueryTableEntryDetail>,
        item: PlainObject,
        key: StringKeys<TKey>,
        detail: Partial<IQueryTableEntryDetail>,
    ): void {
        let type: EntryDetailType;
        let value = item?.[key];
        const options = new Array<ISelectOption>();
        detail.entityName = 'Device';

        switch ((key as StringKeys<IDevice>)) {
            case 'name':
            case 'deviceId':
                type = 'text';
                detail.showInTable = true;
                break;
            case 'store':
                type                = 'text';
                value               = value?.info?.name;
                detail.required     = true;
                detail.canEdit      = true;
                detail.label        = 'DEVICE.STORE.INFO.NAME';
                break;
            case 'hmiHwProductId':
            case 'serial':
            case 'type':
            case 'subType':
            case 'testDevice':
            case 'companyId':
            case 'storeId':
            case 'distributorId':
            case 'company':
            case 'change':
            case 'status':
            case 'created':
            case 'assemblyDate':
            case 'productionDate':
            case 'bindingCode':
                type = 'text';
                detail.showInTable = false;
                detail.showInDetails = false;
                break;
            default:
                super.mapDetails(details, item, key, detail);
                return;
        }

        let level = key;
        if (detail.group) {
            level = detail.group + '.' + key as never;
        }

        details.set(level, Object.assign(detail as Required<IQueryTableEntryDetail>, {
            key: level,
            type,
            label: this.formatKey(level),
            value,
            options,
        }));
    }
}
