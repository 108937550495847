import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CloudRecipeSlotFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { CloudRecipeSlotTableEntry } from './cloud-recipe-slot-table-entry.model';
import { CloudRecipeSlot as ICloudRecipeSlot } from './cloud-recipe-slot.model';
import { CloudRecipeSlot } from './cloud-recipe-slot.entity';
import { CloudRecipeSlotDatasource } from './cloud-recipe-slot-datasource.service';

export class CloudRecipeSlotTableStore extends QueryTableStore<CloudRecipeSlot, ICloudRecipeSlot, CloudRecipeSlotTableEntry, CloudRecipeSlotFilter, CloudRecipeSlotDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: CloudRecipeSlotFilter)
    {
        super(paginator, sort, tableFilter, CloudRecipeSlotTableEntry, AppInjector.get(CloudRecipeSlotDatasource));
    }

    protected override init(entries?: Array<CloudRecipeSlotTableEntry>): void {
        super.init(entries);
        if (this.displayedColumns.includes('change.date')) {
            const index = this.displayedColumns.indexOf('change.date');

            if (index !== -1) {
                this.displayedColumns.splice(index, 1);
            }

            this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'change.date');
        }
    }
}
