import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { InvitationFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { InvitationDatasource } from './invitation-datasource.service';
import { InvitationTableEntry } from './invitation-table-entry.model';
import { Invitation } from './invitation.model';


export class InvitationTableStore extends QueryTableStore<Invitation, Invitation, InvitationTableEntry, InvitationFilter, InvitationDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: InvitationFilter)
    {
        super(paginator, sort, tableFilter, InvitationTableEntry, AppInjector.get(InvitationDatasource));
    }
}
