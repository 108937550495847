import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { EditResult } from '@yukawa/chain-base-angular-domain';
import { Membership, UserGroups } from '@yukawa/chain-main-angular-core';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class MembershipService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('membershipUrl'));
    }

    assign(membership: Membership): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/assign'), membership);
    }

    remove(membership: Membership): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/remove'), membership);
    }

    replace(membership: Membership): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/replace'), membership);
    }

    assignUser(groups: UserGroups): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/user/assign'), groups);
    }

    removeUser(groups: UserGroups): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/user/remove'), groups);
    }

    replaceUser(groups: UserGroups): Observable<EditResult>
    {
        return this.http.post<EditResult>(this.formatServiceUrl('/user/replace'), groups);
    }
}
