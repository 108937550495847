import { CloudRecipe as ICloudRecipe, RecipeMode, CloudRecipeStatus } from '@hemro/lib/domain';
import { Change } from '@yukawa/chain-base-angular-domain';

export interface CloudRecipe extends ICloudRecipe
{
    change: Required<Change>;

    created: Required<Change>;

    bean?: string;

    brewTemperature?: number;

    brewTime?: number;

    brewWeight?: number;

    cloudRecipeId?: number;

    coarseness?: number;

    companyId?: number;

    grindTime?: number;

    grindWeight?: number;

    icon?: string;

    mode?: RecipeMode;

    model?: string;

    name?: string;

    status?: CloudRecipeStatus;

    type?: string;

    brewRatio?: number;
}

export enum RecipeType {
    SingleShot = 'SingleShot',
    DoubleShot = 'DoubleShot',
    SingleShotLong = 'SingleLongShot',
    DoubleShotLong = 'DoubleLongShot',
}

export enum RecipeIcon {
    DoubleCup = 'DoubleCup',
    SingleCup = 'SingleCup',
    SingleCupLong = 'SingleCup',
    DoubleCupLong = 'DoubleCup',
}
