<hemro-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999 h-full notification-drawer"
    fixed
    [mode]="'over'"
    [name]="'notificationDrawer'"
    [position]="'right'"
    [transparentOverlay]="true"
    (openedChanged)="openedChanged($event)"
    #notificationDrawer>

    <div class="flex flex-col w-full mb-4 overflow-hidden">
        <div class="flex flex-col h-20 min-h-20 w-full">
            <div class="flex flex-row items-center justify-between px-4 pt-4">
                <div class="text-2xl font-semibold tracking-tight" transloco="NOTIFICATION.TITLE"></div>
                <button
                    class="ml-2"
                    mat-icon-button
                    (click)="notificationDrawer.close()">
                    <mat-icon
                        class="text-current"
                        [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>
            <mat-slide-toggle class="ml-4" labelPosition="before"
                              [(ngModel)]="onlyShowUnread"
                              (change)="showUnreadOnly()">
                {{ 'NOTIFICATION.UNREAD_ONLY' | transloco }}
            </mat-slide-toggle>
        </div>


        <div class="flex-1 mt-4 flex flex-col gap-4 overflow-auto" #notificationContainer>
            <ng-container *ngIf="notificationFacadeService.isEmpty$ | async; else notifications">
                <div class="flex flex-col items-center justify-center h-full">
                    <mat-icon class="empty-icon" [svgIcon]="'heroicons_outline:bells'"></mat-icon>
                    <div [transloco]="onlyShowUnread ? 'NOTIFICATION.NO_UNREAD_NOTIFICATIONS' : 'NOTIFICATION.NO_NOTIFICATIONS'"
                         class="text-lg">
                    </div>
                </div>
            </ng-container>

            <div class="w-full flex items-center justify-center" *ngIf="notificationLoading$ | async">
                <mat-spinner [diameter]="30"></mat-spinner>
            </div>
        </div>
    </div>

</hemro-drawer>

<ng-template #notifications>
    <ng-container *ngIf="notificationFacadeService.notificationGroups$ | async as groups">
        <ng-container *ngFor="let group of groups; trackBy:trackByFn">
            <app-notification-group
                [group]="group"
                (markAsRead)="markNotificationAsRead($event)"
            ></app-notification-group>
        </ng-container>
    </ng-container>
</ng-template>
