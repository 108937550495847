<ng-container [ngSwitch]="nextScheme">
    <ng-container *ngSwitchCase="'dark'">
        <!-- Dark -->
        <ng-container *ngTemplateOutlet="buttonTemplate; context: {scheme: {
            'mode': 'dark',
            'icon': 'heroicons_solid:moon',
            'tooltip': 'Dark Mode'
        }}"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'light'">
        <!-- Light -->
        <ng-container *ngTemplateOutlet="buttonTemplate; context: {scheme: {
            'mode': 'light',
            'icon': 'heroicons_solid:sun',
            'tooltip': 'Light Mode'
        }}"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'auto'">
        <!-- Auto -->
        <ng-container *ngTemplateOutlet="buttonTemplate; context: {scheme: {
            'mode': 'auto',
            'icon': 'heroicons_solid:lightning-bolt',
            'tooltip': 'Auto Mode'
        }}"></ng-container>
    </ng-container>
</ng-container>

<ng-template #buttonTemplate let-scheme="scheme">
    <button
        mat-icon-button
        [matTooltip]="scheme.tooltip"
        (click)="setScheme($event, scheme.mode)">
        <mat-icon [svgIcon]="scheme.icon" aria-hidden="false"></mat-icon>
    </button>
</ng-template>

<div id="spotlight" class="spotlight" #spotlight></div>
