import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ProfileModule } from '@hemro/lib/profile';
import { APP_ENVIRONMENT_TOKEN, setEnvironment } from '@yukawa/chain-base-angular-client';
import { YukawaAlertModule } from '@yukawa/chain-base-angular-comp/alert';
import { YukawaCloseButtonModule } from '@yukawa/chain-base-angular-comp/close-button';
import { YukawaConsoleLogAlertsModule } from '@yukawa/chain-base-angular-comp/console-log-alerts';
import { QueryTableEntryFormModule, QueryTableModule } from '@yukawa/chain-base-angular-comp/query-table';
import { getUserLanguage, TranslocoCoreModule } from '@yukawa/chain-base-angular-comp/shared';
import { YukawaSplashScreenModule } from '@yukawa/chain-base-angular-comp/splash-screen';
import { IconsModule } from 'app/core/icons/icons.module';
import { environment } from 'environments';


setEnvironment(environment);

const primaryLang = {
    id   : 'en',
    label: 'English',
};

const allLanguages = [
    /*{
        id   : 'de',
        label: 'Deutsch',
    },*/
];

const availableLangs = [primaryLang];
let defaultLang: string;

if (!environment.production) {
    availableLangs.push(...allLanguages);
    defaultLang = getUserLanguage(availableLangs.map(lang => lang.id), primaryLang.id);
}
else {
    defaultLang = primaryLang.id;
}

@NgModule({
    imports  : [
        IconsModule,
        ProfileModule,
        TranslocoCoreModule.forRoot({
            availableLangs,
            defaultLang,
            fallbackLang        : 'en',
            reRenderOnLangChange: true,
            prodMode            : environment.production,
        }),
        YukawaAlertModule.forRoot({
            primary: 'heroicons_solid:check-circle',
            accent : 'heroicons_solid:check-circle',
            warn   : 'heroicons_solid:x-circle',
            basic: 'heroicons_solid:information-circle',
            info   : 'heroicons_solid:information-circle',
            success: 'heroicons_solid:check-circle',
            warning: 'heroicons_solid:exclamation',
            error  : 'heroicons_solid:x-circle',
            dismiss: 'heroicons_solid:x',
        }),
        YukawaConsoleLogAlertsModule.forRoot(
            !environment.production,
            'outline',
            ...environment.logTypes,
        ),
        YukawaSplashScreenModule.forRoot(true),
        QueryTableModule.forRoot(
            'hemro:system-plus-circle',
            'heroicons_outline:refresh',
        ),
        QueryTableEntryFormModule.forRoot(
            'feather:trash-2',
            'feather:x',
            'feather:save',
        ),
        YukawaCloseButtonModule.forRoot(
            'iconsmind:back',
            'close',
        ),
    ],
    providers: [
        {
            provide : APP_ENVIRONMENT_TOKEN,
            useValue: environment,
        },
    ],
})

export class CoreModule
{
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule,
    )
    {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
