import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { UserFilter } from '@yukawa/chain-main-angular-core';
import { UserDatasource } from './user-datasource.service';
import { UserTableEntry } from './user-table-entry.model';
import { User } from './user.model';


export class UserTableStore extends QueryTableStore<User, User, UserTableEntry, UserFilter, UserDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: UserFilter)
    {
        super(paginator, sort, tableFilter, UserTableEntry, AppInjector.get(UserDatasource));
    }
}
