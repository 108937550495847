import { Injectable } from '@angular/core';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { lastValueFrom } from 'rxjs';
import { ChatFact, ChatFactFilter } from './chat-fact.model';
import { ChatFactTableEntry } from './chat-fact-table-entry.model';
import { ChatFactService } from './chat-fact.service';


@Injectable({
    providedIn: 'root',
})
export class ChatFactDatasource extends QueryTableDatasource<ChatFact, ChatFact, ChatFactTableEntry, ChatFactFilter> {

    constructor() {
        super(ChatFactTableEntry);
    }

    public async read(filter?: ChatFactFilter): Promise<Array<ChatFact>> {
        this.query = await lastValueFrom(AppInjector.get(ChatFactService).queryFacts({
            ...this.defaultFilter,
            ...filter,
        }));
        return this.query.items;
    }

    public async create(entry: ChatFactTableEntry): Promise<ChatFactTableEntry> {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(ChatFactService).create({
            ...entry.entity,
        })));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: ChatFactTableEntry, chatFact?: Partial<ChatFact>): Promise<ChatFactTableEntry> {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Chat fact ${entry.id} does not exist.`);
        }

        if (chatFact) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(ChatFactService).merge({
                chatFactId: entry.id,
                ...chatFact,
            } as ChatFact)) as ChatFact);
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: ChatFactTableEntry): Promise<void> {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Chat fact ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(ChatFactService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(chatFactId: string): Promise<ChatFactTableEntry> {
        return this.newEntry(await lastValueFrom(AppInjector.get(ChatFactService).loadByKey(chatFactId)));
    }
}
