import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CloudRecipeFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { CloudRecipe as ICloudRecipe } from './cloud-recipe.model';
import { CloudRecipe } from './cloud-recipe.entity';
import { CloudRecipeTableEntry } from './cloud-recipe-table-entry.model';
import { CloudRecipeDatasource } from './cloud-recipe-datasource.service';


export class CloudRecipeTableStore extends QueryTableStore<CloudRecipe, ICloudRecipe, CloudRecipeTableEntry, CloudRecipeFilter, CloudRecipeDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: CloudRecipeFilter)
    {
        super(paginator, sort, tableFilter, CloudRecipeTableEntry, AppInjector.get(CloudRecipeDatasource));
    }
}
