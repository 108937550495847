import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { HemroDrawerModule } from '../../../../@hemro/components/drawer';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationGroupComponent } from './notification-group/notification-group.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
    declarations: [
        NotificationsComponent,
        NotificationItemComponent,
        NotificationGroupComponent
    ],
    exports: [
        NotificationsComponent
    ],
    imports: [
        CommonModule,
        HemroDrawerModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslocoModule,
        MatSlideToggleModule,
        FormsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
    ],
})
export class NotificationModule { }
