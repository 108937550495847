import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company as ICompany, CompanyFilter } from '@hemro/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { Company } from './company.model';


@Injectable({ providedIn: 'root' })
export class CompanyService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('companyUrl'));
    }

    loadByKey(companyId: number): Observable<Company>
    {
        return this.http.get<Company>(`${this.formatServiceUrl()}/?key=${companyId}`);
    }

    delete(company: Company): Observable<Company>
    {
        return this.http.delete<Company>(`${this.formatServiceUrl()}/?key=` + company.companyId);
    }

    create(company: Company): Observable<Company>
    {
        return this.http.post<Company>(this.formatServiceUrl('/create'), company);
    }

    merge(company: ICompany): Observable<Company>
    {
        return this.http.post<Company>(this.formatServiceUrl('/merge'), company);
    }

    put(company: Company): Observable<Company>
    {
        return this.http.post<Company>(this.formatServiceUrl('/put'), company);
    }

    queryCompany(filter: CompanyFilter): Observable<QueryResult<Company>>
    {
        return super.query<Company>(this.formatServiceUrl('/query'), filter);
    }

    update(company: Company): Observable<Company>
    {
        return this.http.post<Company>(this.formatServiceUrl('/update'), company);
    }
}
