import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap
} from 'rxjs';
import { isEqual } from 'lodash';
import { Store, StoreService } from '@hemro/lib/admin';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { StoreFilter } from '@hemro/lib/domain';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
    selector: 'app-store-search-autocomplete',
    templateUrl: './store-search-autocomplete.component.html',
    styleUrls: ['./store-search-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: StoreSearchAutocompleteComponent
        }
    ]
})
export class StoreSearchAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    private _companyId: number;
    private _regionId: number;

    get companyId(): number {
        return this._companyId;
    }
    @Input() set companyId(value: number) {
        this._companyId = value;
        const storeFilter = { ...this._storeFilter.value };
        this._storeFilter.next({
            ...storeFilter,
            companyId: value
        });
    }

    get regionId(): number {
        return this._regionId;
    }
    @Input() set regionId(value: number) {
        this._regionId = value;
        const regionFilter = { ...this._storeFilter.value };
        this._storeFilter.next({
            ...regionFilter,
            regionId: value
        });
    }

    @Input() set menuPositions(value: ConnectedPosition[]) {
        if (value) {
            this.positions = value;
        }
    }

    @Input() canBeEmpty = true;

    @ViewChild('search') searchInputRef: ElementRef;
    searchControl = new FormControl('');

    onChange = (store) => {};
    onTouched = () => {};
    disabled = false;

    canLoadMore = false;
    stores: Array<Store> = [];
    showSearchAutocomplete = false;

    private readonly _positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'top'
        },
    ];

    positions: ConnectedPosition[] = this._positions;

    get storeFilter$(): Observable<StoreFilter> {
        return this._storeFilter.asObservable();
    }

    get loadMoreVisible(): boolean {
        return this.stores.length > 0 && this.canLoadMore;
    }

    get selectedStore(): Store {
        return this._selectedStore;
    }

    private _selectedStore: Store;
    private _storeFilter = new BehaviorSubject<StoreFilter>({
        keyword: '**',
        orderBy: 'info.name',
        orderDir: 'ASC',
        companyId: this.companyId,
        regionId: this.regionId,
        pager: {
            firstResult: 0,
            pageSize: 5,
        }
    });
    private _unsubscribeAll: Subject<void> = new Subject();

    constructor(private readonly _storeService: StoreService) {
    }

    ngAfterViewInit(): void {
        if (this.searchInputRef) {
            setTimeout(() => {
                this.searchInputRef.nativeElement.focus();
            });
        }
    }

    writeValue(store: Store): void {
        this._selectedStore = store;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.disabled ? this.searchControl.disable() : this.searchControl.enable();
    }

    async ngOnInit(): Promise<void> {
        this.searchControl.valueChanges.pipe(
            startWith(''),
            filter(value => value !== null),
            debounceTime(300),
            tap((value: string | Store) => {
                const name = typeof value === 'string' ? value : value.info.name;
                this._storeFilter.next({
                    keyword: `*${name}*`,
                    companyId: this.companyId,
                    regionId: this.regionId,
                    orderBy: 'info.name',
                    orderDir: 'ASC',
                    pager: {
                        firstResult: 0,
                        pageSize: 5,
                    }
                });
            }),
            takeUntil(this._unsubscribeAll)
        ).subscribe();

        this.storeFilter$.pipe(
            distinctUntilChanged((s1, s2) => isEqual(s1, s2)),
            switchMap(storeFilter =>
                this._storeService.queryStore(storeFilter).pipe(
                    tap((regions: QueryResult<Store>) => {
                        this.canLoadMore = regions.hasMore;
                    }),
                    map((stores: QueryResult<Store>) => {
                        if (!storeFilter.pager.firstResult) {
                            this.stores = stores.items;
                        } else {
                            this.stores.push(...stores.items);
                        }
                        return stores.items;
                    })
                )),
            takeUntil(this._unsubscribeAll)
        ).subscribe();
    }

    onSelectionChange(store: Store): void {
        this.toggleAutoCompletePanel();
        this._selectedStore = store;
        this.onChange(this._selectedStore);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    trackByFn(index: number, store: Store): number {
        return store.storeId;
    }

    loadMoreStores(): void {
        const storeFilter = { ...this._storeFilter.value };
        this._storeFilter.next({
            ...storeFilter,
            pager: {
                ...storeFilter.pager,
                firstResult: storeFilter.pager.firstResult + storeFilter.pager.pageSize,
            }
        });
    }

    toggleAutoCompletePanel(): void {
        this.showSearchAutocomplete = !this.showSearchAutocomplete;
        if (this.showSearchAutocomplete) {
            this.setInputFocused();
        }
        this.searchControl.patchValue('');
    }

    setInputFocused(): void {
        setTimeout(() => {
            this.searchInputRef?.nativeElement?.focus();
        });
    }
}
