import { ModuleWithProviders, NgModule } from '@angular/core';
import { HEMRO_APP_CONFIG } from '@hemro/services/config/config.constants';
import { HemroConfigService } from '@hemro/services/config/config.service';
import { AppConfig } from 'app/core/config/app.config';


@NgModule()
export class HemroConfigModule
{
    /**
     * Constructor
     */
    constructor(private _hemroConfigService: HemroConfigService)
    {
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: AppConfig): ModuleWithProviders<HemroConfigModule>
    {
        return {
            ngModule : HemroConfigModule,
            providers: [
                {
                    provide : HEMRO_APP_CONFIG,
                    useValue: config,
                },
            ],
        };
    }
}
