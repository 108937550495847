import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HemroNavigationItem } from '@hemro/components/navigation';
import { ConfigService, mock, MockApiService, RegisterMock, RestAspect } from '@yukawa/chain-base-angular-client';
import { Navigation } from 'app/core/navigation/navigation.types';
import {
    compactNavigation,
    defaultNavigation,
    futuristicNavigation,
    horizontalNavigation,
} from 'app/mock-api/common/navigation/data';
import { cloneDeep } from 'lodash-es';
import { Observable, ReplaySubject, tap } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class NavigationService extends RestAspect
{
    private readonly _compactNavigation: HemroNavigationItem[]    = compactNavigation;
    private readonly _defaultNavigation: HemroNavigationItem[]    = defaultNavigation;
    private readonly _futuristicNavigation: HemroNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: HemroNavigationItem[] = horizontalNavigation;

    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        httpClient: HttpClient,
        private _configService: ConfigService)
    {
        super(httpClient, _configService);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    @mock<NavigationService>(function(register: RegisterMock)
    {
        register(['design', 'development', 'production'], (mockService: MockApiService) =>
        {
            // -----------------------------------------------------------------------------------------------------
            // @ Navigation - GET
            // -----------------------------------------------------------------------------------------------------
            mockService
                .onGet('api/common/navigation')
                .reply(() =>
                {
                    /**
                     * Fills navigation children using the default navigation
                     *
                     * @param navigation
                     */
                    const fillNavigation = (navigation: Array<HemroNavigationItem>): void =>
                    {
                        navigation.forEach((compactNavItem) =>
                        {
                            this._defaultNavigation.forEach((defaultNavItem) =>
                            {
                                if (defaultNavItem.id === compactNavItem.id) {
                                    compactNavItem.children = cloneDeep(defaultNavItem.children);
                                }
                            });
                        });
                    };

                    fillNavigation(this._compactNavigation);
                    fillNavigation(this._futuristicNavigation);
                    fillNavigation(this._horizontalNavigation);

                    // Return the response
                    return [
                        200,
                        {
                            compact   : cloneDeep(this._compactNavigation),
                            default   : cloneDeep(this._defaultNavigation),
                            futuristic: cloneDeep(this._futuristicNavigation),
                            horizontal: cloneDeep(this._horizontalNavigation),
                        },
                    ];
                });
        });
    })
    get(): Observable<Navigation>
    {
        return this.http.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) =>
            {
                this._navigation.next(navigation);
            }),
        );
    }
}
