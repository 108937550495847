<ng-container [ngSwitch]="linkToProfile">

    <a *ngSwitchCase="true" class="transition-none" (click)="avatarClick()">
        <ng-container *ngTemplateOutlet="userIconTemplate"></ng-container>
    </a>

    <ng-container *ngSwitchCase="false">
        <ng-container *ngTemplateOutlet="userIconTemplate"></ng-container>
    </ng-container>

</ng-container>


<div class="static h-full w-full" *ngIf="displayVerified && verified">
    <mat-icon class="icon-size-{{verifiedIconSize}}" svgIcon="hemro:verified" [ngStyle]="{
        'top.px': verifiedTop,
        'left.px': verifiedLeft
    }"></mat-icon>
</div>

<ng-template #userIconTemplate>
    <ng-container [ngSwitch]="showProfilePicture">
        <svg *ngSwitchCase="false" #svg></svg>

        <ng-container *ngSwitchCase="true">

            <ng-container [ngSwitch]="profilePicture !== null">

                <ng-container *ngSwitchCase="false">

                    <mat-icon
                        class="block m-auto text-gray-500" [ngStyle]="{
                     'width.px': size,
                     'height.px': size
                 }" svgIcon="account_circle"></mat-icon>

                </ng-container>

                <ng-container *ngSwitchCase="true">

                    <ng-container [ngSwitch]="swiperLazy">

                        <img *ngSwitchCase="true" [attr.data-src]="profilePicture" src="" alt="" @fadeIn
                             class="object-cover rounded-full swiper-lazy"
                             [ngStyle]="{
                     'width.px': size,
                     'height.px': size
                 }">
                        <img *ngSwitchCase="false" [src]="profilePicture" alt="" @fadeIn
                             class="object-cover rounded-full"
                             [ngStyle]="{
                     'width.px': size,
                     'height.px': size
                 }">

                    </ng-container>

                </ng-container>

            </ng-container>

        </ng-container>

    </ng-container>
</ng-template>
