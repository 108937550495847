import { Attribute, Component, HostBinding, OnInit } from '@angular/core';
import { HEMRO_VERSION } from '@hemro/version';
import { ConfigService } from '@yukawa/chain-base-angular-client';


@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss'],
})
export class FooterComponent implements OnInit
{
    readonly imprintUrl: string;
    readonly privacyPolicyUrl: string;
    readonly termsOfServiceUrl: string;

    constructor(
        private readonly _configService: ConfigService,
        @Attribute('class') public classNames: string,
    )
    {
        this.imprintUrl        = this._configService.getValue('imprintUrl');
        this.privacyPolicyUrl  = this._configService.getValue('privacyPolicyUrl');
        this.termsOfServiceUrl = this._configService.getValue('termsOfServiceUrl');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    @HostBinding('class')
    get class(): string
    {
        const classNames = [];
        classNames.push(
            'relative',
            'grid', 'grid-cols-2', 'sm:flex', 'sm:flex-0', 'items-center', 'justify-between',
            'gap-2', 'sm:gap-6',
            'w-full', 'pt-2', 'sm:pt-0',
            'z-49',
            'border-t',
            'print:hidden',
            'overflow-hidden',
        );
        classNames.push(...this.classNames?.split(' '));
        return classNames.join(' ');
    }

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    /**
     * Getter for version
     */
    get version(): string
    {
        return HEMRO_VERSION;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    openLink($event: MouseEvent, url: string): void
    {
        window.open(url, '_blank');
    }
}
