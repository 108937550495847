import { NgModule } from '@angular/core';
import { HemroMediaWatcherService } from '@hemro/services/media-watcher/media-watcher.service';
import { MEDIA_WATCHER_SERVICE } from '@yukawa/chain-base-angular-comp/shared';


@NgModule({
    providers: [
        HemroMediaWatcherService,
        {
            provide    : MEDIA_WATCHER_SERVICE,
            useExisting: HemroMediaWatcherService,
        },
    ],
})
export class HemroMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _hemroMediaWatcherService: HemroMediaWatcherService)
    {
    }
}
