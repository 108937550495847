/* tslint:disable:max-line-length */
import { HemroNavigationItem, NavigationItemType } from '@hemro/components/navigation';
import { CompanyRoles, ProfileService, UserService } from '@hemro/lib/profile';
import { HemroMediaWatcherService } from '@hemro/services/media-watcher';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { CanActivateCompany } from 'app/modules/admin/company/company.guards';
import { CanActivateData } from 'app/modules/admin/data/data.guards';
import { CanActivateDevice } from 'app/modules/admin/device/device.guards';
import { CanActivateFirmware } from 'app/modules/admin/firmware/firmware.guards';
import { CanActivateProfile } from 'app/modules/admin/profile/profile.guards';
import { CanActivateRegion } from 'app/modules/admin/region/region.guards';
import { CanActivateStore } from 'app/modules/admin/store/store.guards';
import { CanActivateRecipe } from 'app/modules/admin/recipe/recipe.guards';
import { environment } from 'environments';
import { Nullable } from 'simplytyped';
import { CanActivateChatbot } from 'app/modules/admin/chatbot/chatbot.guards';


abstract class NavigationItem implements HemroNavigationItem
{
    static #isScreenMedium: Nullable<boolean> = null;

    abstract icon: string;
    abstract id: string;
    abstract title: string;
    abstract type: NavigationItemType;

    constructor(protected readonly _hidden: () => boolean)
    {
        const hidden = this._hidden;
        this._hidden = (): boolean =>
        {
            NavigationItem.createMediaWatcher();
            return hidden();
        };
    }

    public static get isScreenMedium(): Nullable<boolean>
    {
        return this.#isScreenMedium;
    }

    static createMediaWatcher(): void
    {
        if (this.#isScreenMedium !== null) {
            return;
        }
        this.#isScreenMedium = false;
        AppInjector.get(HemroMediaWatcherService).onMediaChange$.subscribe(({ matchingAliases }) =>
        {
            this.#isScreenMedium = matchingAliases.includes('md') && (matchingAliases.length <= 2);
        });
    };
}

class EditCompanyNavigationItem extends NavigationItem
{
    id = 'company';

    title = 'COMPANY.TITLE';

    type: NavigationItemType = 'basic';

    icon = 'corporate_fare';

    get link(): string
    {
        const company = AppInjector.get(ProfileService).profile?.company;

        return company ? '/company/' + company.companyId : '/company';
    }

    hidden(): boolean
    {
        return this._hidden() || !(AppInjector.get(CanActivateCompany).canActivate()
            && AppInjector.get(UserService).hasRole(CompanyRoles.companyAdmin));
    }
}

class EditRegionNavigationItem extends NavigationItem
{
    id = 'region';

    title = 'REGION.TITLE';

    type: NavigationItemType = 'basic';

    icon = 'heroicons_outline:globe-alt';

    get link(): string
    {
        const company = AppInjector.get(ProfileService).profile?.company;

        return company ? '/company/regions/' + company.companyId : '/region';
    }

    hidden(): boolean
    {
        return this._hidden() || !(AppInjector.get(CanActivateRegion).canActivate()
            && AppInjector.get(UserService).hasRole(CompanyRoles.companyAdmin));
    }
}

const createSetupCompanyNavigationItems = (hidden: () => boolean): Array<HemroNavigationItem> =>
{
    const editCompanyNavigationItem = new EditCompanyNavigationItem(hidden);
    const editRegionNavigationItem  = new EditRegionNavigationItem(hidden);
    return [
        {
            id    : editCompanyNavigationItem.id,
            title : editCompanyNavigationItem.title,
            type  : editCompanyNavigationItem.type,
            icon  : editCompanyNavigationItem.icon,
            link  : '/company',
            hidden: (): boolean =>
                hidden() || !(AppInjector.get(CanActivateCompany).canActivate()
                    && !AppInjector.get(UserService).hasRole(CompanyRoles.companyAdmin)),
        },
        editCompanyNavigationItem,
        {
            id    : editRegionNavigationItem.id,
            title : editRegionNavigationItem.title,
            type  : editRegionNavigationItem.type,
            icon  : editRegionNavigationItem.icon,
            link  : '/region',
            hidden: (): boolean =>
                hidden() || !(AppInjector.get(CanActivateRegion).canActivate()
                    && !AppInjector.get(UserService).hasRole(CompanyRoles.companyAdmin)),
        },
        editRegionNavigationItem,
        {
            id    : 'store',
            title : 'STORE.TITLE',
            type  : 'basic',
            icon  : 'local_convenience_store',
            link  : '/store',
            hidden: (): boolean =>
                hidden() || !AppInjector.get(CanActivateStore).canActivate(),
        },
    ];
};

const navigation = new Array<HemroNavigationItem>(
    {
        id    : 'data',
        title : 'DATA.TITLE',
        type  : 'basic',
        icon  : 'heroicons_outline:presentation-chart-bar',
        link  : '/data',
        hidden: (): boolean => !AppInjector.get(CanActivateData).canActivate(),
    },
    {
        id      : 'setup',
        title   : 'SETUP.TITLE',
        type    : 'collapsable',
        icon    : 'heroicons_outline:cog',
        link    : '/data',
        hidden  : (): boolean => !NavigationItem.isScreenMedium || !AppInjector.get(CanActivateCompany).canActivate() &&
            !AppInjector.get(CanActivateRegion).canActivate() &&
            !AppInjector.get(CanActivateStore).canActivate() &&
            !AppInjector.get(CanActivateDevice).canActivate(),
        children: createSetupCompanyNavigationItems((): boolean => false),
    },
    ...createSetupCompanyNavigationItems((): boolean => NavigationItem.isScreenMedium),
    {
        id    : 'device',
        title : 'DEVICE.TITLE',
        type  : 'basic',
        icon  : 'heroicons_outline:device',
        link  : '/device',
        classes: { icon: 'size-md' },
        hidden: (): boolean =>
            NavigationItem.isScreenMedium || !AppInjector.get(CanActivateDevice).canActivate(),
    },
    {
        id    : 'profile',
        title : 'PROFILE.TITLE',
        type  : 'basic',
        icon  : 'heroicons_outline:users',
        link  : '/profile',
        hidden: (): boolean => !AppInjector.get(CanActivateProfile).canActivate(),
    },
    {
        id    : 'chatbot',
        title : 'CHATBOT_MANAGEMENT.TITLE',
        type  : 'basic',
        icon  : 'heroicons_outline:chat-round',
        link  : '/chatbot',
        hidden: (): boolean => !AppInjector.get(CanActivateChatbot).canActivate(),
    },
    {
        id    : 'firmware',
        title : 'FIRMWARE.TITLE',
        type  : 'basic',
        icon  : 'heroicons_outline:chip',
        link  : '/firmware',
        hidden: (): boolean =>
            !AppInjector.get(CanActivateFirmware).canActivate(),
    },
    {
        id    : 'recipe',
        title : 'RECIPE.TITLE',
        type  : 'basic',
        icon  : 'heroicons_outline:coffee',
        link  : '/recipe',
        hidden: (): boolean =>
            !AppInjector.get(CanActivateRecipe).canActivate(),
    },
    /*{
        id    : 'user',
        title : 'USER.TITLE',
        type  : 'basic',
        icon  : 'heroicons_outline:users',
        link  : '/user',
        hidden: (): boolean => !AppInjector.get(CanActivateUser).canActivate(),
    },
    /*,
    {
        id   : 'admin',
        title: 'Admin',
        type : 'basic',
        icon : 'admin_panel_settings',
        link : '/admin',
    },*/
);

if (!environment.production) {
}

export const defaultNavigation: HemroNavigationItem[]    = navigation;
export const compactNavigation: HemroNavigationItem[]    = navigation;
export const futuristicNavigation: HemroNavigationItem[] = navigation;
export const horizontalNavigation: HemroNavigationItem[] = navigation;
