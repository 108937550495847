import { Injectable } from '@angular/core';
import { RegionFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { RegionTableEntry } from './region-table-entry.model';
import { Region } from './region.entity';
import { Region as IRegion } from './region.model';
import { RegionService } from './region.service';


@Injectable({
    providedIn: 'root',
})
export class RegionDatasource extends QueryTableDatasource<Region, IRegion, RegionTableEntry, RegionFilter>
{
    companyColumnDisplayed: boolean = true;

    constructor()
    {
        super(RegionTableEntry);
    }

    public async read(filter?: RegionFilter): Promise<Array<Region>>
    {
        this.query = await firstValueFrom(AppInjector.get(RegionService).queryRegion({
            ...this.defaultFilter,
            ...filter,
            loadCompany: true,
        }));
        return this.query.items;
    }

    public async create(entry: RegionTableEntry): Promise<RegionTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(RegionService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: RegionTableEntry, region?: Partial<Region>): Promise<RegionTableEntry>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Region ${entry.id} does not exist.`);
        }

        if (region) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(RegionService).merge(new Region({
                ...region,
                regionId: entry.entity.regionId,
            } as Region))) as Region);
        }

        this.entries.splice(index, 1, entry);

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: RegionTableEntry): Promise<void>
    {
        const index = this.entries.findIndex(item => item.id === entry.id);
        if (index === -1) {
            throw new QueryTableDatasourceException(`Region ${entry.id} does not exist.`);
        }

        await lastValueFrom(AppInjector.get(RegionService).delete(entry.entity));

        this.entries.splice(index, 1);

        this.entryDeleted.emit(entry);
    }

    public async getByID(regionId: number): Promise<RegionTableEntry>
    {
        return this.newEntry((await firstValueFrom(AppInjector.get(RegionService).queryRegion({
            regionId,
        }).pipe(
            map((query) =>
            {
                if (query.items.length === 0) {
                    throw new QueryTableDatasourceException(`Region ${regionId} does not exist.`);
                }
                return query.items[0];
            }),
        ))));
    }
}
