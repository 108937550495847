<div class="chatbot" [class.has-footer]="hasFooter">
    <div class="start-chat" [class.hidden]="opened">
        <button class="w-full h-full text-left flex items-center gap-1"
                (click)="onChangeChatBoxState(true)">
            <mat-icon svgIcon="heroicons_outline:chat-round"
                      class="text-white">
            </mat-icon>
            {{ 'CHATBOT.START_CHAT' | transloco }}
        </button>
    </div>

    <div class="chat-box" [class.opened]="opened">
        <div class="chat-box__header">
            <div class="flex items-center gap-2">
                <mat-icon svgIcon="heroicons_outline:chat-round" class="text-white"></mat-icon>

                {{ 'CHATBOT.TITLE' | transloco }}
            </div>

            <div class="test-badge" *ngIf="isTestInUsed$ | async">
                {{ 'CHATBOT.TEST_MODE' | transloco }}
            </div>

            <button mat-icon-button>
                <mat-icon (click)="onChangeChatBoxState(false)" class="text-white">close</mat-icon>
            </button>
        </div>

        <div class="chat-box__body">
            <div class="chat-box__body-content" #content
                 [class.has-reset-button]="resetButtonVisible">
                <ng-container *ngIf="isEmpty$ | async; else conversation">
                    <div class="empty-chat">
                        <div class="empty-chat__icon">
                            <img src="assets/icons/favicon.svg" alt="logo" width="32" height="32" priority>
                        </div>

                        <div class="empty-chat__tooltip"
                             [class.mb-6]="isTestInUsed$ | async">
                            <b>{{ 'CHATBOT.TOOLTIP1' | transloco }}</b>
                            <div>{{ 'CHATBOT.TOOLTIP2' | transloco }}</div>
                        </div>
                    </div>
                    <div class="reset-chat" *ngIf="isTestInUsed$ | async">
                        <button mat-stroked-button (click)="onStopTestingChatbot()">
                            {{ 'CHATBOT.STOP_TEST_CHATBOT' | transloco }}
                        </button>
                    </div>
                </ng-container>
            </div>
            <chat-input (send)="onSendMessage($event)"></chat-input>
        </div>
    </div>
</div>

<ng-template #conversation>
    <chat-message
        *ngFor="let message of messages$ | async;trackBy: identify"
        [class.mine]="!message.isResponse"
        [message]="message"
        (keepScrollDown)="onScrollDown($event)">
    </chat-message>
    <chat-message *ngIf="loading$ | async" [isPlaceholder]="true"></chat-message>
    <div class="reset-chat" *ngIf="resetButtonVisible">
        <button mat-button (click)="onResetChat()">
            {{ 'CHATBOT.RESET_CHAT' | transloco }}
        </button>
        <button *ngIf="resetButtonVisible && isTestInUsed$ | async"
                mat-stroked-button (click)="onStopTestingChatbot()">
            {{ 'CHATBOT.STOP_TEST_CHATBOT' | transloco }}
        </button>
    </div>
</ng-template>
