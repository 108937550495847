<!-- Copyright -->
<span class="text row-start-2">Hemro International AG {{ currentYear }}</span>

<!-- Logo -->
<!--<mat-icon svgIcon="hemro:logo" class="text-hemro-black icon-size-24 ml-auto mr-auto"></mat-icon>-->

<div class="col-span-2 flex flex-wrap justify-center gap-x-4">

    <!-- Imprint -->
    <a class="link sm:ml-auto" (click)="openLink($event, imprintUrl)"
       transloco="BUTTON.IMPRINT"></a>

    <!-- Terms of Use -->
    <a class="link" (click)="openLink($event, termsOfServiceUrl)"
       transloco="AUTH.REGISTER.TERMS_OF_SERVICE"></a>

    <!-- Privacy Policy -->
    <a class="link" (click)="openLink($event, privacyPolicyUrl)"
       transloco="AUTH.REGISTER.PRIVACY_POLICY"></a>

</div>

<!-- Version -->
<span class="text ml-auto sm:ml-0 row-start-2">v {{ version }}</span>

<!-- background -->
<!--<ng-container [ngTemplateOutlet]="logoBackground" [ngTemplateOutletContext]="{ y: '-72%'}"></ng-container>-->


<!--<ng-template #logoBackground let-className='className' let-y="y">
    <img class="absolute left-0 top-0 right-0 bottom-0 -z-10 object-cover {{ className }}" alt=""
         [ngStyle]="{ transform: 'translate(0,' + y + ')'}"
         src="assets/images/Header_Hemro.jpeg"/>
</ng-template>-->
