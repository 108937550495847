import { Component } from '@angular/core';

@Component({
  selector: 'hermo-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent {
  userGuideUrl = 'https://downloads.mahlkoenig.de/Service/Mahlkoenig_Sync_App_Desktop_User_Guide.pdf';
  faqsUrl = 'https://downloads.mahlkoenig.de/Service/Mahlkoenig_Sync_FAQs.pdf';
}
