<ng-container *ngIf="!isPlaceholder; else placeholder">
    <div class="message-container">
        <div *ngIf="message.isResponse" class="icon">
            <img src="assets/icons/favicon.svg" alt="logo" width="32" height="32">
        </div>
        <div class="content" [class.mine]="!message.isResponse">
            <div class="message">
                <ng-container *ngIf="message.isResponse;else myMessage">
                    <span *ngIf="!message.isTyping" [innerHTML]="message?.payload | safeHtml"></span>
                    <span *ngIf="message.isTyping"
                          typewriter
                          [message]="message?.payload"
                          [typingSpeed]="100"
                          (typingStatus)="onTypingStatusChange($event)"
                          (stopTyping)="onStopTyping($event)"
                          (keepScrollDown)="keepScrollDown.emit($event)">
                </span>
                </ng-container>
            </div>

            <!--        <div class="reaction" *ngIf="message.isResponse && !message.isTyping">-->
            <!--            <button mat-icon-button [disableRipple]="true">-->
            <!--                <mat-icon svgIcon="heroicons_outline:like"></mat-icon>-->
            <!--            </button>-->
            <!--            <button mat-icon-button [disableRipple]="true">-->
            <!--                <mat-icon svgIcon="heroicons_outline:dislike"></mat-icon>-->
            <!--            </button>-->
            <!--            <button mat-icon-button [disableRipple]="true">-->
            <!--                <mat-icon svgIcon="heroicons_outline:refresh" class="icon-size-5"></mat-icon>-->
            <!--            </button>-->
            <!--        </div>-->

        </div>
    </div>

</ng-container>

<ng-template #myMessage>
    {{ message?.payload }}
</ng-template>

<ng-template #placeholder>
    <div class="flex items-center gap-x-4 px-1">
        <div class="icon">
            <img src="assets/icons/favicon.svg" alt="logo" width="32" height="32">
        </div>
        <div class="typing-dots">...</div>
    </div>
</ng-template>
