import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HemroModule } from '@hemro';
import { HemroConfigModule } from '@hemro/services/config';
import { MockApiModule, setAppInjector } from '@yukawa/chain-base-angular-client';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularHomeComponent } from './modules/landing/angular/angular-home.component';


@NgModule({
    declarations: [
        AppComponent,
        AngularHomeComponent,
    ],
    imports     : [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,

        HemroModule,
        HemroConfigModule.forRoot(appConfig),
        MockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        HttpClientModule,
    ],
    bootstrap   : [AppComponent],
})
export class AppModule
{
    constructor(injector: Injector)
    {
        setAppInjector(injector);
    }
}
