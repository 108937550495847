/* eslint-disable @typescript-eslint/member-ordering */
import { AppInjector, Entity } from '@yukawa/chain-base-angular-client';
import { Info } from '@yukawa/chain-base-angular-domain';
import { Group } from '@yukawa/chain-main-angular-core';
import { Account } from '@yukawa/chain-security-angular-core';
import { Company, IProfile as IProfile } from './profile.model';
import { UserInfo } from './user.model';
import { UserService } from './user.service';


export class Profile extends Entity<IProfile> implements IProfile
{
    public company!: Company;
    public profileId!: string;
    public user!: UserInfo;
    public imageUrl!: string;
    public image!: null;
    public verified!: boolean;

    public account!: Account;
    public groups!: Group[];

    constructor(initialData: IProfile)
    {
        super(initialData as IProfile);

        if (initialData?.user?.created) {
            initialData.user.created.date = new Date(initialData.user.created.date);
        }
        if (initialData?.user?.change) {
            initialData.user.change.date = new Date(initialData.user.change.date);
        }
    }

    //region Public Properties

    public get displayName(): string
    {
        return [this.user?.firstName, this.user?.lastName]
            .map(s => s?.trim())
            .filter(s => !!s).join(' ') || this.profileId;
    }

    public get key(): string
    {
        return this.profileId;
    }

    public get isAdmin(): boolean
    {
        return this.profileId === 'admin';
    }

    public get isCurrentUser(): boolean
    {
        return AppInjector.get(UserService).user?.username === this.profileId;
    }

    //endregion

    public updateFromJson(data: IProfile & { verifiedDate: Date }): void
    {
        this.setFromJson(data, [
            'company',
            'profileId',
            'user',
            'groups',
        ], undefined, {
            'company': (company: Company) => company?.companyId === 0 ? null : company,
            'groups' : (groups: Array<Group>) =>
                (groups
                    ? groups
                    : data.user?.groups || []).map(_group => new class implements Group
                {
                    name!: string;
                    info!: Required<Info>;

                    constructor(
                        group: Group,
                    )
                    {
                        Object.assign(this, group);
                    }

                    toString(): string
                    {
                        return this.info.name;
                    }
                }(_group)),
        })
        ;
    }

    public toJson(): IProfile
    {
        return this.toJsonWithKeys([
            'company',
            'profileId',
            'user',
            'account',
        ], {
            'profileId': () => this.profileId || undefined,
            'company'  : () => ({
                companyId: this.company?.companyId,
            } as Company),
        });
    }
}
