import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChatBoxComponent } from './comp/chatbox/chat-box.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChatInputComponent } from './comp/chat-input/chat-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { ChatMessageComponent } from './comp/chat-message/chat-message.component';
import { ChatFacadeService } from '@hemro/lib/admin';
import { SafeHtmlModule, TypewriterModule } from '@hemro/shared';



@NgModule({
    declarations: [
        ChatBoxComponent,
        ChatInputComponent,
        ChatMessageComponent
    ],
    exports: [
        ChatBoxComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslocoModule,
        NgOptimizedImage,
        TypewriterModule,
        SafeHtmlModule
    ],
    providers: [
        ChatFacadeService
    ]
})
export class ChatbotModule { }
