import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
    HemroHorizontalNavigationBasicItemComponent,
} from '@hemro/components/navigation/horizontal/components/basic/basic.component';
import {
    HemroHorizontalNavigationBranchItemComponent,
} from '@hemro/components/navigation/horizontal/components/branch/branch.component';
import {
    HemroHorizontalNavigationDividerItemComponent,
} from '@hemro/components/navigation/horizontal/components/divider/divider.component';
import {
    HemroHorizontalNavigationSpacerItemComponent,
} from '@hemro/components/navigation/horizontal/components/spacer/spacer.component';
import { HemroHorizontalNavigationComponent } from '@hemro/components/navigation/horizontal/horizontal.component';
import {
    HemroVerticalNavigationAsideItemComponent,
} from '@hemro/components/navigation/vertical/components/aside/aside.component';
import {
    HemroVerticalNavigationBasicItemComponent,
} from '@hemro/components/navigation/vertical/components/basic/basic.component';
import {
    HemroVerticalNavigationCollapsableItemComponent,
} from '@hemro/components/navigation/vertical/components/collapsable/collapsable.component';
import {
    HemroVerticalNavigationDividerItemComponent,
} from '@hemro/components/navigation/vertical/components/divider/divider.component';
import {
    HemroVerticalNavigationGroupItemComponent,
} from '@hemro/components/navigation/vertical/components/group/group.component';
import {
    HemroVerticalNavigationSpacerItemComponent,
} from '@hemro/components/navigation/vertical/components/spacer/spacer.component';
import { HemroVerticalNavigationComponent } from '@hemro/components/navigation/vertical/vertical.component';
import { HemroScrollbarModule } from '@hemro/directives/scrollbar/public-api';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
    declarations: [
        HemroHorizontalNavigationBasicItemComponent,
        HemroHorizontalNavigationBranchItemComponent,
        HemroHorizontalNavigationComponent,
        HemroHorizontalNavigationDividerItemComponent,
        HemroHorizontalNavigationSpacerItemComponent,
        HemroVerticalNavigationAsideItemComponent,
        HemroVerticalNavigationBasicItemComponent,
        HemroVerticalNavigationCollapsableItemComponent,
        HemroVerticalNavigationComponent,
        HemroVerticalNavigationDividerItemComponent,
        HemroVerticalNavigationGroupItemComponent,
        HemroVerticalNavigationSpacerItemComponent,
    ],
    imports     : [
        CommonModule,
        HemroScrollbarModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        RouterModule,
        TranslocoModule,
    ],
    exports     : [
        HemroHorizontalNavigationComponent,
        HemroVerticalNavigationComponent,
    ],
})
export class HemroNavigationModule
{
}
