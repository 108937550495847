import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RegionFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { RegionDatasource } from './region-datasource.service';
import { RegionTableEntry } from './region-table-entry.model';
import { Region as IRegion, Region } from './region.model';


export class RegionTableStore extends QueryTableStore<Region, IRegion, RegionTableEntry, RegionFilter, RegionDatasource>
{

    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: RegionFilter)
    {
        super(paginator, sort, tableFilter, RegionTableEntry, AppInjector.get(RegionDatasource));
    }
}
