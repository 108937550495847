import { Entity } from '@yukawa/chain-base-angular-client';
import { Change, Info } from '@yukawa/chain-base-angular-domain';
import { Company } from '../company';
import { Region } from '../region';
import { Region as IRegion } from '../region/region.model';
import { StoreTableEntry } from './store-table-entry.model';
import { Store as IStore } from './store.model';


export class Store extends Entity<IStore> implements IStore
{
    public change!: Required<Change>;
    public companyId!: number;
    public company!: Company;
    public created!: Required<Change>;
    public info!: Required<Info>;
    public region!: Region;
    public regionId!: number;
    public storeId!: number;

    public constructor(initialData: IStore)
    {
        super(initialData);
    }

    //store Entity<IStore> implementation

    public get key(): string
    {
        return String(this.storeId);
    }

    public override toJson(): IStore
    {
        return this.toJsonWithKeys(
            [
                'companyId',
                'info',
                'regionId',
                'storeId',
                'address',
                'company'
            ],
            {},
        );
    }

    public override updateFromJson(data: IStore): void
    {
        this.setFromJson(data, [
            'change',
            'companyId',
            'created',
            'info',
            'region',
            'regionId',
            'storeId',
            'address',
            'company'
        ], undefined, {
            created: (created: Change) =>
            {
                if (created) {
                    created.date = new Date(created.date as never);
                }
                return created;
            },
            change : (change: Change) =>
            {
                if (change) {
                    change.date = new Date(change.date as never);
                }
                return change;
            },
            region : (region: IRegion) => new Region(region),
            address: (address: IStore['address']) => address ?? StoreTableEntry.viewConfig.address,
        });
    }

    //endstore

    public override toString(): string
    {
        return this.info.name;
    }
}
