import { NgModule } from '@angular/core';
import { HemroUtilsService } from '@hemro/services/utils/utils.service';


@NgModule({
    providers: [
        HemroUtilsService,
    ],
})
export class HemroUtilsModule
{
    /**
     * Constructor
     */
    constructor(private _hemroUtilsService: HemroUtilsService)
    {
    }
}
