import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { Observable } from 'rxjs';
import { CloudRecipeSlotFilter } from '@hemro/lib/domain';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { CloudRecipeSlot } from './cloud-recipe-slot.entity';


@Injectable({ providedIn: 'root' })
export class CloudRecipeSlotService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, `${configService.getBaseUrl()}/admin-service/cloud-recipe-slot`);
    }

    queryRecipeSlots(filter: CloudRecipeSlotFilter): Observable<QueryResult<CloudRecipeSlot>>
    {
        return super.query<CloudRecipeSlot>(this.formatServiceUrl('/query'), filter);
    }

    delete(recipeSlot: CloudRecipeSlot): Observable<CloudRecipeSlot> {
        return this.http.delete<CloudRecipeSlot>(`${this.formatServiceUrl()}/?key=` + recipeSlot.slotId);
    }

    create(recipeSlot: Partial<CloudRecipeSlot>): Observable<CloudRecipeSlot> {
        return this.http.post<CloudRecipeSlot>(`${this.formatServiceUrl()}/create`, recipeSlot);
    }
}
