<div class="flex flex-row space-x-4 w-full md:w-auto">
    <mat-form-field class="mb-2 transition-all"
                    [ngClass]="{
                                'w-40': selectedStore,
                                'w-25': !selectedStore,
                                'animate-pulse': stores.length === 0
                            }">
        <mat-label transloco="STORE.FILTER"></mat-label>
        <input type="text" matInput readonly [placeholder]="'STORE.FILTER' | transloco"
               cdkOverlayOrigin #regionTrigger="cdkOverlayOrigin"
               (click)="showSearchAutocomplete = !showSearchAutocomplete;setInputFocused()"
               [value]="selectedStore?.info?.name || null"
        >
    </mat-form-field>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="regionTrigger"
        [cdkConnectedOverlayOpen]="showSearchAutocomplete"
        cdkConnectedOverlayWidth="20%"
        [cdkConnectedOverlayPositions]="positions"
        (backdropClick)="toggleAutoCompletePanel()"
        (overlayOutsideClick)="showSearchAutocomplete = false">
        <form class="w-full overflow-hidden flex flex-col mat-elevation-z2 rounded-bl-md rounded-br-md max-h-[65vh]">
            <mat-form-field class="transition-all w-full search-region">
                <mat-icon class="icon-size-5" [svgIcon]="'hemro:search-icon'" matPrefix></mat-icon>
                <input matInput
                       #search
                       [formControl]="searchControl"
                       [placeholder]="'STORE.FILTER' | transloco">
            </mat-form-field>

            <div class="overflow-y-auto w-full">
                <ng-container *ngIf="stores.length > 0; else noEntries">
                    <mat-option
                        *ngIf="this.selectedStore && canBeEmpty"
                        [value]="null" transloco="BUTTON.CLEAR_FILTER"
                        class="text-base"
                        (click)="onSelectionChange(null)">
                    </mat-option>
                    <mat-option *ngFor="let _store of stores;trackBy:trackByFn" [value]="_store"
                                [id]="_store.regionId.toString()"
                                (click)="onSelectionChange(_store)"
                                class="text-base">
                        {{ _store.info.name }}
                    </mat-option>
                </ng-container>
            </div>

            <button *ngIf="loadMoreVisible"
                    (click)="loadMoreStores()"
                    type="button"
                    class="flex justify-between items-center w-full py-2 px-4 border-t-2">
                <span transloco="BUTTON.LOAD_MORE"></span>
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

            <ng-template #noEntries>
                <mat-option [disabled]="true">
                    {{ 'QUERY_TABLE.NO_ENTRIES' | transloco }}
                </mat-option>
            </ng-template>
        </form>
    </ng-template>
</div>
