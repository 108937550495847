import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionSearchAutocompleteComponent } from './region-search-autocomplete.component';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
    declarations: [RegionSearchAutocompleteComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatOptionModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslocoModule,
        OverlayModule
    ],
    exports: [RegionSearchAutocompleteComponent]
})
export class RegionSearchAutocompleteModule {
}
