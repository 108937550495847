import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { ChatFactTableEntry } from './chat-fact-table-entry.model';
import { ChatFact, ChatFactFilter } from './chat-fact.model';
import { ChatFactDatasource } from './chat-fact-datasource.service';


export class ChatFactTableStore extends QueryTableStore<ChatFact, ChatFact, ChatFactTableEntry, ChatFactFilter, ChatFactDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: ChatFactFilter)
    {
        super(paginator, sort, tableFilter, ChatFactTableEntry, AppInjector.get(ChatFactDatasource));
    }
}
