import { AppInjector } from '@yukawa/chain-base-angular-client';
import { IQueryTableEntryDetail, ISelectOption } from '@yukawa/chain-base-angular-comp/query-table';
import { AutocompleteSearchFunction } from '@yukawa/chain-base-angular-comp/shared';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { firstValueFrom, map, Subject } from 'rxjs';
import { IProfile } from './profile.model';
import { ProfileService } from './profile.service';


export const profileAutocompleteSearch = (
    value: IProfile,
    detail: Partial<IQueryTableEntryDetail>): AutocompleteSearchFunction =>
{
    const subject               = new Subject<Array<ISelectOption<string, IProfile>>>();
    detail.autocompleteOptions$ = subject;
    return async (filter: string | null): Promise<void> =>
    {
        if (!filter) {
            subject.next([]);
            return;
        }
        const profileService = AppInjector.get(ProfileService);
        profileService.clearEntityCache();
        subject.next(await firstValueFrom(profileService.query({
            keyword: `*${filter}*`,
            pager  : {
                firstResult: 0,
                pageSize   : 10,
            },
        }).pipe(
            map((profiles: QueryResult<IProfile>) =>
            {
                const autocompleteOptions = profiles.items.map((profile: IProfile) => ({
                    name       : profile.user.username,
                    value      : profile.user.username,
                    objectValue: profile.profileId === value.profileId
                        ? value
                        : {
                            profileId: profile.profileId,
                            user: profile.user,
                            company: profile.company
                        } as IProfile,
                } as ISelectOption<string, IProfile>));

                // Display also unavailable profile items
                /*if (value && !autocompleteOptions.some(option => option.name === value.user.username)) {
                    autocompleteOptions.push({
                        name       : value.user.username,
                        value      : value.user.username,
                        objectValue: {
                            profileId: value.profileId,
                            user: value.user,
                        } as IProfile,
                    });
                }*/

                return autocompleteOptions;
            }),
        )));
    };
};
