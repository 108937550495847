import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DeviceFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { DeviceDatasource } from './device-datasource.service';
import { DeviceTableEntry } from './device-table-entry.model';
import { Device, Device as IDevice } from './device.model';
import { HemroRoles, UserService } from '@hemro/lib/profile';


export class DeviceTableStore extends QueryTableStore<Device, IDevice, DeviceTableEntry, DeviceFilter>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: DeviceFilter)
    {
        super(paginator, sort, tableFilter, DeviceTableEntry, AppInjector.get(DeviceDatasource));
    }

    protected override init(entries?: Array<DeviceTableEntry>): void {
        super.init(entries);
        this.displayedColumns.length = 0;

        if (this.headers.length > 0) {
            this.displayedColumns.push(
                'serial',
                'deviceId',
                'hmiHwProductId',
                'status.status.bundleVersion',
                'type',
                'subType',
                'name',
                'assemblyDate',
                'created.date',
                'status.cloudDate',
                'store',
                'bindings',
                'actions'
            );

            const canEditTestDevice = AppInjector.get(UserService).hasRole(...[
                HemroRoles.admin,
                HemroRoles.editTestDevices,
            ]);
            if (canEditTestDevice) {
                this.displayedColumns.splice(this.displayedColumns.indexOf('store'), 0, 'testDevice');
            }
            if (AppInjector.get(UserService).hasRole(HemroRoles.admin, HemroRoles.editDeviceDistributor)) {
                this.displayedColumns.splice(this.displayedColumns.indexOf('store') + 1, 0, 'distributorId');
            }
            if (!!this.headers.find(h => h.key === 'company')) {
                this.displayedColumns.splice(this.displayedColumns.indexOf('store'), 0, 'company');
            }
            if (!!this.headers.find(h => h.key === 'region')) {
                this.displayedColumns.splice(this.displayedColumns.indexOf('store'), 0, 'region');
            }
        }
    }
}
