import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ManagerFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { IManager, Manager, ManagerTableEntry } from '../manager';
import { RegionManagerDatasource } from './region-manager-datasource.service';


export class RegionManagerTableStore extends QueryTableStore<Manager, IManager, ManagerTableEntry, ManagerFilter, RegionManagerDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: ManagerFilter)
    {
        super(paginator, sort, tableFilter, ManagerTableEntry, AppInjector.get(RegionManagerDatasource));
    }
}
