import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ChatFacadeService, MessageBody } from '@hemro/lib/admin';
import { cloneDeep } from 'lodash';
import { marked } from 'marked';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { TypewriterDirective } from '@hemro/shared';

@Component({
    selector: 'chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnDestroy {
    @Input() isPlaceholder: boolean;
    @Output() keepScrollDown = new EventEmitter<boolean>();
    @ViewChild(TypewriterDirective, { static: false }) typewriter: TypewriterDirective;
    private _message: MessageBody;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _chatFacadeService: ChatFacadeService) {
        this._chatFacadeService.stopTyping$
            .pipe(
                distinctUntilChanged(),
                debounceTime(300),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this.typewriter) {
                    this.typewriter?.onStopTyping();
                }
            });
    }

    get message(): MessageBody {
        return this._message;
    }

    @Input()
    set message(message: MessageBody) {
        const isResponse = message.isResponse;
        this._message = cloneDeep({
            ...message,
            payload: isResponse ? marked(message.payload) : message.payload
        });
    }

    onTypingStatusChange(isTyping: boolean): void {
        this._message.isTyping = isTyping;
        this._chatFacadeService.isTyping = isTyping;
    }

    onStopTyping(currentText: string): void {
        this._message.payload = currentText;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
