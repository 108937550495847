import { Injectable } from '@angular/core';
import { CloudRecipeFilter, CloudRecipeStatus } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableDatasource, QueryTableDatasourceException } from '@yukawa/chain-base-angular-comp/query-table';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { CloudRecipeTableEntry } from './cloud-recipe-table-entry.model';
import { CloudRecipe as ICloudRecipe } from './cloud-recipe.model';
import { CloudRecipe } from './cloud-recipe.entity';
import { CloudRecipeService } from './cloud-recipe.service';

@Injectable({
    providedIn: 'root',
})
export class CloudRecipeDatasource extends QueryTableDatasource<CloudRecipe, ICloudRecipe, CloudRecipeTableEntry, CloudRecipeFilter>
{
    constructor()
    {
        super(CloudRecipeTableEntry);
    }

    public async read(filter?: CloudRecipeFilter): Promise<Array<CloudRecipe>>
    {
        this.query = await lastValueFrom(AppInjector.get(CloudRecipeService).queryRecipes({
            ...this.defaultFilter,
            ...filter,
        }));
        return this.query.items;
    }

    public async create(entry: CloudRecipeTableEntry): Promise<CloudRecipeTableEntry>
    {
        entry = this.newEntry(await lastValueFrom(AppInjector.get(CloudRecipeService).create(entry.entity)));
        this.entries.splice(0, 0, entry);
        return await this.update(entry);
    }

    public async update(entry: CloudRecipeTableEntry, cloudRecipe?: Partial<CloudRecipe>): Promise<CloudRecipeTableEntry>
    {
        if (cloudRecipe) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(CloudRecipeService).merge(new CloudRecipe({
                ...cloudRecipe,
                cloudRecipeId: entry.entity.cloudRecipeId,
            } as CloudRecipe))) as CloudRecipe);
        }

        this.entryUpdated.emit(entry);

        return entry;
    }

    public async delete(entry: CloudRecipeTableEntry): Promise<void>
    {
        throw new Error('Method not implemented.');
    }

    public async getByID(cloudRecipeId: number): Promise<CloudRecipeTableEntry>
    {
        return this.newEntry((await firstValueFrom(AppInjector.get(CloudRecipeService).queryRecipes({
            ...this.defaultFilter,
            cloudRecipeId,
        }).pipe(
            map((query) =>
            {
                if (query.items.length === 0) {
                    throw new QueryTableDatasourceException(`Cloud recipe ${cloudRecipeId} does not exist.`);
                }
                return query.items[0];
            }),
        ))));
    }

    public async changeStatus(entry: CloudRecipeTableEntry, status: CloudRecipeStatus): Promise<CloudRecipeTableEntry>
    {
        if (entry) {
            entry = this.newEntry(await lastValueFrom(AppInjector.get(CloudRecipeService).changeStatus(entry.entity, status)) as CloudRecipe);
        }

        this.entryUpdated.emit(entry);

        return entry;
    }
}
