<ng-container *ngIf="group">
    <div class="bg-transparent flex flex-col px-4">
        <div class="font-bold text-2xl mb-2">{{ group.date }}</div>
        <div class="flex flex-col gap-2">
            <ng-container *ngFor="let notification of group.notifications; trackBy:trackByFn">
                <app-notification-item
                    [notification]="notification"
                    (markAsRead)="markAsRead.emit($event)"
                ></app-notification-item>
            </ng-container>
        </div>
    </div>
</ng-container>
