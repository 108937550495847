import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@yukawa/chain-base-angular-client';


@Injectable({
    providedIn: 'root',
})
export class LibService
{

    constructor(
        public readonly configService: ConfigService,
        public readonly htto: HttpClient,
    )
    {
    }
}
