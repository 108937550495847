import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { Notification, NotificationFilter, NotificationGroup } from './notification.model';
import {
    BehaviorSubject,
    debounceTime,
    map,
    Observable,
    startWith,
    Subject,
    switchMap,
    tap,
    withLatestFrom
} from 'rxjs';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class NotificationFacadeService {
    private defaultFilter: NotificationFilter = {
        pager: {
            pageSize: 20,
            firstResult: 0,
        },
        orderDir: 'DESC',
        orderBy: 'created.date'
    };

    private _notifications = new BehaviorSubject<Notification[]>([]);
    private _notificationGroups = new BehaviorSubject<NotificationGroup[]>([]);
    private _loading = new BehaviorSubject<boolean>(false);
    private request$ = new Subject<boolean>();
    private _filter$ = new BehaviorSubject<NotificationFilter>(this.defaultFilter);
    private _hasMore = false;
    private filter$ = this._filter$.asObservable();

    constructor(private _notificationService: NotificationService) {
        this._init();
    }

    public get notificationGroups$() {
        return this._notificationGroups.asObservable();
    };

    public get loading$() {
        return this._loading.asObservable();
    }

    public get isEmpty$(): Observable<boolean> {
        return this.notificationGroups$.pipe(
            withLatestFrom(this.loading$),
            map(([notifications, loading]) => notifications.length === 0 && !loading),
            startWith(false),
        );
    }

    public loadNotifications(): void {
        this.request$.next(true);
    }

    public toggleUnreadOnly(unreadOnly: boolean): void {
        this._filter$.next(this._formatFilter({
            ...this.defaultFilter,
            read: !unreadOnly
        }));
        this._notifications.next([]);
        this._notificationGroups.next([]);
        this.request$.next(true);
    }

    public loadMoreNotifications(): void {
        if (!this._hasMore) {
            return;
        }
        this._filter$.next(this._formatFilter({
            ...this._filter$.value,
            pager: {
                pageSize: this.defaultFilter.pager?.pageSize || 20,
                firstResult: this._notifications.value.length || 0
            }
        }));
        this.request$.next(false);
    }

    public markAsRead(notificationId: number): void {
        this._notificationService.markAsRead([notificationId]).subscribe();
    }

    public resetNotificationState(resetToDefault = false): void {
        this._notifications.next([]);
        this._notificationGroups.next([]);
        const filter = resetToDefault
            ? this.defaultFilter
            : { ...this.defaultFilter, read: this._filter$.value.read };
        this._filter$.next(this._formatFilter(filter));
    }

    private _init(): void {
        this.request$
            .pipe(
                withLatestFrom(this.filter$),
                tap(() => this._loading.next(true)),
                debounceTime(300),
                switchMap(([_, filter]) =>
                    this._notificationService.queryNotifications(filter)),
                withLatestFrom(this.request$)
            )
            .subscribe(([notifications, refresh]) => {
                this._hasMore = notifications.hasMore;
                const result: Notification[] = [];
                if (refresh) {
                    result.push(...notifications.items);
                } else {
                    result.push(...this._notifications.value, ...notifications.items);
                }
                this._notifications.next(result);
                this._notificationGroups.next(this.groupNotificationsByDate(result));
                this._loading.next(false);
            });
    }

    private groupNotificationsByDate(notis: Notification[]): NotificationGroup[] {
        if (notis.length === 0) {
            return [];
        }
        const groups: Record<string, Notification[]> = notis.reduce((acc, notification) => {
            const date = moment(notification.created?.date).toDate();
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;

            if (!acc[formattedDate]) {
                acc[formattedDate] = [];
            }
            acc[formattedDate].push(notification);

            return acc;
        }, {} as Record<string, Notification[]>);

        return Object.entries(groups).map(([date, notifications]) => ({
            date,
            notifications
        }));
    };

    private _formatFilter(filter: NotificationFilter): NotificationFilter {
        const formatedFilter = { ...filter };
        if ('read' in formatedFilter && formatedFilter.read === false) {
            formatedFilter.read = false;
        } else {
            delete formatedFilter.read;
        }
        return formatedFilter;
    }
}
