import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { fadeIn } from '@hemro/lib/animations';
import { Profile, ProfileService, UserService } from '@hemro/lib/profile';
import * as d3 from 'd3';
import { Selection } from 'd3-selection';
import { Nullable } from 'simplytyped';

// import { Md5 } from 'ts-md5/dist/md5';

@Component({
    selector   : 'lib-user-icon',
    templateUrl: './user-icon.component.html',
    styleUrls  : ['./user-icon.component.scss'],
    animations : [fadeIn],
})
export class UserIconComponent implements OnInit, OnChanges
{
    @ViewChild('svg') svg: ElementRef<SVGElement>       = null as never;
    @Input() profile: Profile                           = null as never;
    @Input() size: number                               = 100;
    @Input() backgroundColor: string                    = '#9e9e9e';
    @Input() verified: boolean                          = false;
    @Input() lineHeight                                 = 12;
    @Input() font: string                               = 'Montserrat';
    @Input() fontSize: number                           = 10;
    @Input() fontWeight: 'regular' | 'bold'             = 'regular';
    @Input() showProfilePicture: boolean                = false;
    @Input() swiperLazy: boolean                        = false;
    @Input() contentId: number | undefined;
    @Input() displayVerified: boolean | undefined       = false;
    @Input() displayProfilePicture: boolean | undefined = true;
    @Input() linkToProfile: boolean | undefined         = true;

    @Output() avatarClicked = new EventEmitter<void>();

    private _verifiedTop: number              = 27;
    private _verifiedLeft: number             = 25;
    private _verifiedIconSize: number         = 4;
    private _profilePicture: Nullable<string> = null;
    private _initials: string                 = '';

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _ngZone: NgZone,
        private readonly _profileService: ProfileService,
        private readonly _userService: UserService,
        private readonly _router: Router,
    )
    {
    }

    get backgroundUrl(): string
    {
        return '';
        // TODO(video/comment): decide to use Gravatar
        /*const hash = '';
        const hash = this.email != null ? Md5.hashStr(this.email.trim().toLowerCase()) : Md5.hashStr('');
        return `https://www.gravatar.com/avatar/${hash}.png?default=blank&size=${this.size * 4}`;*/
    }

    /*get email(): string
    {
        return this.user?.email;
    }*/

    get profilePicture(): Nullable<string>
    {
        return this._profilePicture;
    }

    get initials(): string
    {
        return this._initials;
    }

    get words(): string[]
    {
        return [this.initials];
    }

    get targetWidth(): number
    {
        return Math.sqrt(this.measureWidth(this.initials.trim()) * this.lineHeight);
    }

    get radius(): number
    {
        return Math.max(this.size / 2, 4);
    }

    get verifiedTop(): number
    {
        const percentage = this.getScalePercentage();
        return Math.round(this._verifiedTop * (percentage / 100));
    }

    get verifiedLeft(): number
    {
        const percentage = this.getScalePercentage();
        return Math.round(this._verifiedLeft * (percentage / 100));
    }

    public get verifiedIconSize(): number
    {
        const percentage = this.getScalePercentage();
        return Math.round(this._verifiedIconSize * (percentage / 100));
    }

    private static generateUniqueId(): string
    {
        return Math.random().toString(36).substring(2);
    }

    measureWidth(text: string): number
    {
        const context = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D;
        return context.measureText(text).width;
    }

    async ngOnInit(): Promise<void>
    {
        if (!this.profile) {
            this.profile = this._profileService.profile as Profile;
        }

        if (!this.profile) {
            this.showProfilePicture = true;
            return;
        }

        await this._profileService.loadImage(this.profile);
        const image = this.profile?.image;

        this.verified = !!this.profile.verified;

        if (this.profile.profileId === 'init' ||
            this.profile.profileId === 'admin' ||
            (this.profile.user?.username.endsWith('hemrogroup.com') && !image)) {
            this.font               = 'Montserrat';
            this.backgroundColor    = 'black';
            this.fontWeight         = 'bold';
            this.lineHeight         = 7.4;
            this.fontSize           = 6;
            this.showProfilePicture = false;
            this._initials          = 'H';
        }
        else {
            const result = this.profile?.displayName != null ? this.profile.displayName.split(' ') : [' '];
            if (result.length > 1) {
                this._initials = `${result[0][0]}${result[1][0]}`.toUpperCase();
            }
            else if (result[0].length > 0) {
                if (/[A-Z]/.test(result[0]) && result[0].toUpperCase() !== result[0]) {
                    result[0] = result[0].replace(/[a-z]/g, '');
                }
                result[0]      = result[0].replace(/[^\wd]/g, '');
                this._initials = result[0][0].toUpperCase() + result[0].substring(1, 2);
            }

            this._initials = this._initials.trim();
        }

        if (this.displayProfilePicture && (this._initials.length === 0 || image)) {
            this.showProfilePicture = true;
        }

        if (this.showProfilePicture) {
            this._profilePicture = image ? this.profile?.imageUrl : null;
        }
        else {
            this._changeDetector.detectChanges();
            if (this._initials.length > 0) {
                this.drawCircularAvatar();
            }
        }
    }

    drawCircularAvatar(): void
    {
        if (this.showProfilePicture) {
            return;
        }

        const lines = this.generateLines();

        let textRadius = 0;
        for (let i = 0, n = lines.length; i < n; ++i) {
            const dy   = (Math.abs(i - n / 2 + 0.5) + 0.5) * this.lineHeight;
            const dx   = lines[i].width / 2;
            textRadius = Math.max(textRadius, Math.sqrt(dx ** 2 + dy ** 2));
        }

        let font = this.fontWeight !== 'regular' ? `${this.fontWeight} ` : '';
        font += `${this.fontSize}px ${this.font}`;

        const svg = d3
            .select(this.svg.nativeElement)
            .style('font', font)
            .attr('width', this.size)
            .attr('height', this.size)
            .attr('text-anchor', 'middle');

        const appendCircle = (
            selection: Selection<SVGClipPathElement, unknown, null, undefined>,
            size: number,
            radius: number): void =>
        {
            selection
                .append('circle')
                .attr('cx', size / 2)
                .attr('cy', size / 2)
                .attr('r', radius);
        };

        const id   = UserIconComponent.generateUniqueId();
        const defs = svg.append('defs');

        defs.append('clipPath').attr('id', `image-clip-${id}`).call(appendCircle, this.size, this.radius);

        svg
            .append('circle')
            .attr('cx', this.size / 2)
            .attr('cy', this.size / 2)
            .attr('r', this.radius)
            .attr('fill', this.backgroundColor);

        svg
            .append('text')
            .attr('transform', `translate(${this.size / 2},${this.size / 2}) scale(${this.radius / textRadius})`)
            .selectAll('tspan')
            .data(lines)
            .enter()
            .append('tspan')
            .attr('x', 0)
            .attr('y', (d, i) => (i - 1 / 2 + 0.8) * this.lineHeight)
            .attr('fill', 'white')
            .text(d => d.text);

        svg
            .append('image')
            .attr('xlink:href', this.backgroundUrl)
            .attr('width', this.size)
            .attr('height', this.size)
            .attr('x', 0)
            .attr('y', 0)
            .attr('clip-path', `url(#image-clip-${id})`);
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void>
    {
        if (changes['profile'] && changes['profile'].previousValue) {
            await this.ngOnInit();
        }
    }

    async avatarClick(): Promise<boolean>
    {
        this.avatarClicked.emit();
        let url = '/profile';
        if (this.profile.profileId !== this._userService.user?.username) {
            url += `/show/${this.profile.profileId}`;
        }
        if (this.contentId) {
            url += `/${this.contentId}`;
        }
        return this._ngZone.run(() => this._router.navigate([url]));
    }

    private generateLines(): any[]
    {
        let line;
        let lineWidth0 = Infinity;
        const result   = [];
        for (let i = 0, n = this.words.length; i < n; ++i) {
            const lineText1  = (line ? line.text + ' ' : '') + this.words[i] as string;
            const lineWidth1 = this.measureWidth(lineText1);
            if ((lineWidth0 + lineWidth1) / 2 < this.targetWidth) {
                line = { width: lineWidth0 = lineWidth1, text: lineText1 };
            }
            else {
                lineWidth0 = this.measureWidth(this.words[i]);
                line       = { width: lineWidth0, text: this.words[i] };
                result.push(line);
            }
        }
        return result;
    }

    private getScalePercentage(): number
    {
        return Math.round(this.size / 40 * 100);
    }
}
