import { Route } from '@angular/router';
import { AuthGuardService as AuthGuard, NoAuthGuardService as NoAuthGuard } from '@yukawa/chain-main-angular-session';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { LayoutRouteConfig } from 'app/layout/layout.types';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: (Route & { data?: LayoutRouteConfig })[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'company' },

    // Redirect signed in user to the '/user'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'user' },

    // Auth routes for guests
    {
        path            : '',
        canActivate     : [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component       : LayoutComponent,
        data            : {
            layout: 'empty',
            watermark: false,
        },
        children        : [
            {
                path        : 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule),
            },
            {
                path        : 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule),
            },
            {
                path        : 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule),
            },
            {
                path        : 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule),
            },
            {
                path        : 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule),
            },
            {
                path        : 'register',
                loadChildren: () => import('app/modules/auth/register/register.module').then(m => m.AuthRegisterModule),
            },
            {
                path        : 'invitation',
                data: {
                    invitation: true,
                },
                loadChildren: () => import('app/modules/auth/register/register.module').then(m => m.AuthRegisterModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path            : '',
        canActivate     : [AuthGuard],
        canActivateChild: [AuthGuard],
        component       : LayoutComponent,
        data            : {
            layout: 'empty',
            watermark: false,
        },
        children        : [
            {
                path        : 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule),
            },
            {
                path        : 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path     : '',
        component: LayoutComponent,
        data     : {
            layout: 'empty',
        },
        children : [
            {
                path        : 'home',
                loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule),
            },
        ],
    },

    // Admin routes
    {
        path            : '',
        canActivate     : [AuthGuard],
        canActivateChild: [AuthGuard],
        component       : LayoutComponent,
        resolve         : {
            initialData: InitialDataResolver,
        },
        data: {
            watermark: true
        },
        children        : [
            {
                path        : 'company',
                loadChildren: () => import('app/modules/admin/company/company.module').then(m => m.CompanyModule),
            },
            {
                path        : 'region',
                loadChildren: () => import('app/modules/admin/region/region.module').then(m => m.RegionModule),
            },
            {
                path        : 'store',
                loadChildren: () => import('app/modules/admin/store/store.module').then(m => m.StoreModule),
            },
            {
                path        : 'device',
                loadChildren: () => import('app/modules/admin/device/device.module').then(m => m.DeviceModule),
            },
            {
                path        : 'firmware',
                loadChildren: () => import('app/modules/admin/firmware/firmware.module').then(m => m.FirmwareModule),
            },
            {
                path        : 'data',
                data: { watermark: false },
                loadChildren: () => import('app/modules/admin/data/data.module').then(m => m.DataModule),
            },
            {
                path        : 'profile',
                loadChildren: () => import('app/modules/admin/profile/profile.module').then(m => m.ProfileModule),
            },
            {
                path        : 'user',
                loadChildren: () => import('app/modules/admin/user/user.module').then(m => m.UserModule),
            },
            {
                path        : 'recipe',
                loadChildren: () => import('app/modules/admin/recipe/recipe.module').then(m => m.RecipeModule),
            },
            {
                path        : 'chatbot',
                loadChildren: () => import('app/modules/admin/chatbot/chatbot.module').then(m => m.ChatbotModule),
            },
        ],
    },
];
