import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FirmwareFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { FirmwareDatasource } from './firmware-datasource.service';
import { FirmwareTableEntry } from './firmware-table-entry.model';
import { Firmware } from './firmware.model';


export class FirmwareTableStore extends QueryTableStore<Firmware, Firmware, FirmwareTableEntry, FirmwareFilter, FirmwareDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: FirmwareFilter)
    {
        super(paginator, sort, tableFilter, FirmwareTableEntry, AppInjector.get(FirmwareDatasource));
    }
}
