import { CloudRecipeSlot as ICloudRecipeSlot } from './cloud-recipe-slot.model';
import { Entity } from '@yukawa/chain-base-angular-client';
import { Change } from '@yukawa/chain-base-angular-domain';
import { CloudRecipe } from './cloud-recipe.model';

export class CloudRecipeSlot extends Entity<ICloudRecipeSlot> implements ICloudRecipeSlot {

    slot?: number;
    slotId?: number;
    storeId?: number;
    recipe?: CloudRecipe;
    public change!: Required<Change>;
    public created!: Required<Change>;

    public constructor(initialData: ICloudRecipeSlot)
    {
        super(initialData);
    }

    public get key(): string
    {
        return String(this.storeId);
    }

    public override toJson(): ICloudRecipeSlot
    {
        return this.toJsonWithKeys(
            [
                'slot',
                'slotId',
                'recipe',
                'storeId',
                'change',
            ],
            {},
        );
    }

    public override updateFromJson(data: ICloudRecipeSlot): void {
        this.setFromJson(data, [
            'slot',
            'slotId',
            'recipe',
            'storeId',
            'change',
        ], undefined, {
            change : (change: Change) =>
            {
                if (change) {
                    change.date = new Date(change.date as never);
                }
                return change;
            },
        });
    }

    public override toString(): string
    {
        return this.storeId?.toString() || '';
    }
}
