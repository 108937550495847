import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, removeEmpty, RestAspect } from '@yukawa/chain-base-angular-client';
import { EditResult, QueryResult } from '@yukawa/chain-base-angular-domain';
import { Group, GroupFilter } from '@yukawa/chain-main-angular-core';
import { SessionService, SessionStoreService } from '@yukawa/chain-main-angular-session';
import { lastValueFrom, Observable } from 'rxjs';
import { PlainObject } from 'simplytyped';


type GroupLoadTypes = 'all' | 'invitable' | 'assignable';

@Injectable({ providedIn: 'root' })
export class GroupService extends RestAspect
{
    readonly _groups = new Map<string, Array<Group>>();
    private readonly _userGroups = new Map<string, Array<Group>>();

    constructor(
        http: HttpClient,
        configService: ConfigService,
        private readonly _sessionService: SessionService,
        private readonly _sessionStoreService: SessionStoreService,
    )
    {
        super(http, configService, configService.formatUrl('groupsUrl'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get groups(): Array<Group>
    {
        return this._groups.get('all') || [];
    }

    get invitableGroups(): Array<Group>
    {
        return this._groups.get('invitable') || [];
    }

    get userGroups(): Map<string, Array<Group>>
    {
        return this._userGroups;
    }

// -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    insertGroup(group: Group): Observable<Group>
    {
        return this.http.post<any>(this.formatServiceUrl(), group);
    }

    editGroup(group: Group): Observable<Group>
    {
        return this.http.put<any>(this.formatServiceUrl(), group);
    }

    loadGroup(groupName: string): Observable<Group>
    {
        return this.http.get<any>(this.formatServiceUrl(`/${groupName}`));
    }

    deleteGroup(groupName: string): Observable<Group>
    {
        return this.http.delete<any>(this.formatServiceUrl(`/${groupName}`));
    }

    findGroup(
        type: GroupLoadTypes = 'all',
        userId?: string,
        firstResult?: number,
        pageSize?: number): Observable<Array<Group>>
    {
        const options = removeEmpty<PlainObject>({
            userId,
            firstResult,
            pageSize,
        });
        let path: string;
        switch (type) {
            case 'all':
                path = '/find';
                break;
            default:
                path = '/find-' + type;
                break;
        }

        if (Object.keys(options).length) {
            path = `${path}?${new URLSearchParams(options).toString()}`;
        }
        switch (type) {
            case 'all':
                return this.http.post<Array<Group>>(this.formatServiceUrl(path), {});
            default:
                return this.http.get<Array<Group>>(this.formatServiceUrl(path));
        }
    }

    queryGroup(filter: GroupFilter): Observable<QueryResult<Group>>
    {
        return this.query<any>(this.formatServiceUrl('/query'), filter);
    }

    /**
     * Loads all, invitable or available user groups.
     *
     * @param type
     * @param userId If set, only loads groups for the given user into the {@link userGroups} map. If not set, all groups are loaded.
     */
    async loadGroups(type: GroupLoadTypes = 'all', userId?: string): Promise<Array<Group>>
    {
        const findGroup = lastValueFrom(this.findGroup(type, userId));
        if (!userId) {
            let groups = this._groups.get(type);
            if (!groups) {
                groups = await findGroup;
                this._groups.set(type, groups);
            }
            else {
                groups.length = 0;
                groups?.push(...await findGroup);
            }
            return groups;
        }
        else if (!this._userGroups.get(userId)) {
            this._userGroups.set(userId, await findGroup);
        }

        return this._userGroups.get(userId) as Array<Group>;
    }

    async assign(userId: string, groupName: string): Promise<EditResult>
    {
        return lastValueFrom(this.http.post<EditResult>(
            this.formatServiceUrl('/assign') + `?userId=${userId}&groupName=${groupName}`,
            {},
        ));
    }

    async remove(userId: string, groupName: string): Promise<EditResult>
    {
        return lastValueFrom(this.http.delete<EditResult>(
            this.formatServiceUrl('/remove') + `?userId=${userId}&groupName=${groupName}`,
        ));
    }
}
