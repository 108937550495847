import {ChainEventBean, Change, ChangeFilter, Created, EntityFilter} from '@yukawa/chain-base-angular-domain';
import {Device, DeviceStatus} from './device';
import {ErrorEvent, FirmwareEvent, GenericDeviceEvent} from './event';
import {Firmware} from './system';


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.notification.NgxNotification <br/>
*/
export interface NgxNotification extends  Created{
created?: Change;

/**
*	@example {}
*/
details?: {[key: string]: Object};
notificationId?: number;

/**
*	@example 000002
*/
profileId?: string;
read?: boolean;
relatedDevice?: Device;

/**
*	@example T12345
*/
relatedDeviceId?: string;
relatedFirmware?: Firmware;

/**
*	@example 10000001
*/
relatedFirmwareVersion?: number;
type?: NotificationType;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.notification.NgxNotificationEvent <br/>
*/
export interface NgxNotificationEvent extends  ChainEventBean<NgxNotification> {
deviceStatusEvent?: GenericDeviceEvent<DeviceStatus> ;
errorEvent?: ErrorEvent;
firmwareEvent?: FirmwareEvent;
}


/**
*	@generator rest2ts
*	javaType: inc.yukawa.hemro.ngx.core.domain.notification.NgxNotificationFilter <br/>
*/
export interface NgxNotificationFilter extends  EntityFilter{
created?: ChangeFilter;
notificationId?: number;
notificationIds?: number[];
profileId?: string;
read?: boolean;
types?: NotificationType[];
}

export enum NotificationType {
FIRMWARE_RELEASED = "FIRMWARE_RELEASED",
BURR_LIFE_WARN = "BURR_LIFE_WARN",
BURR_LIFE_REACHED = "BURR_LIFE_REACHED",
GRINDER_ALERT = "GRINDER_ALERT"
}

