<div class="w-full h-full flex-grow flex flex-col nav-tab">
    <div class="z-20">
        <nav mat-tab-nav-bar [tabPanel]="tabPanel"
             class="flex flex-col sm:flex-row mb-4 gap-2 my-0 items-center justify-center">
            <div class="border-2 border-primary rounded-md bg-white">
                <ng-container *ngFor="let link of links;let even = even">
                    <ng-container *ngIf="canBeDisplayed(link.id)">
                        <a mat-tab-link
                           [active]="isActive(link.url, link.id)"
                           [routerLinkActive]="'test'"
                           [routerLinkActiveOptions]="{ exact: true }"
                           [class.even]="even"
                           [routerLink]="link.url"> {{ link.label | transloco }} </a>
                    </ng-container>
                </ng-container>
            </div>
        </nav>
    </div>
    <mat-tab-nav-panel #tabPanel>
        <div class="flex-grow flex profile">
        </div>
    </mat-tab-nav-panel>
</div>
