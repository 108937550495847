import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { HemroRoles, UserService } from '@hemro/lib/profile';


@Injectable({
    providedIn: 'root',
})
export class CanActivateData implements CanActivate
{
    constructor(
        private readonly _userService: UserService,
    )
    {
    }

    public canActivate(
        route?: ActivatedRouteSnapshot,
        state?: RouterStateSnapshot): boolean
    {
        return this._userService.hasRole(...[
            HemroRoles.admin,
            HemroRoles.editAllCharts,
            HemroRoles.readAlCharts,
            HemroRoles.editCompanyCharts,
            HemroRoles.readCompanyCharts,
        ]);
    }
}
