import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SchemeTypes } from '@hemro/components/scheme/scheme.types';
import { HemroConfigService } from '@hemro/services/config';
import { Subject, takeUntil } from 'rxjs';


@Component({
    selector   : 'hemro-scheme',
    templateUrl: './scheme.component.html',
    styleUrls  : ['./scheme.component.scss'],
})
export class SchemeComponent implements OnInit, OnDestroy
{
    @ViewChild('spotlight') spotlight: ElementRef<HTMLElement>;

    currentScheme: SchemeTypes;

    readonly #unsubscribeAll = new Subject<void>();

    constructor(
        private readonly _hemroConfigService: HemroConfigService,
    )
    {
    }

    get nextScheme(): SchemeTypes
    {
        const schemeTypes        = Object.values(SchemeTypes);
        const currentSchemeIndex = schemeTypes.indexOf(this.currentScheme);
        const nextSchemeIndex    = (currentSchemeIndex + 1) % schemeTypes.length;
        return schemeTypes[nextSchemeIndex];
    }

    ngOnInit(): void
    {
        this._hemroConfigService.config$.pipe(
            takeUntil(this.#unsubscribeAll),
        ).subscribe((config) =>
        {
            this.currentScheme = config.scheme;
        });
    }

    ngOnDestroy(): void
    {
        this.#unsubscribeAll.next();
        this.#unsubscribeAll.complete();
    }

    setScheme(
        $event: MouseEvent,
        scheme: SchemeTypes): void
    {
        if (scheme === SchemeTypes.auto) {
            this._hemroConfigService.config = { scheme };
            return;
        }

        const x = $event.clientX;
        const y = $event.clientY;

        this.animateSpotlightReveal(x, y, scheme);
    }

    private animateSpotlightReveal(
        x: number,
        y: number,
        scheme: SchemeTypes.light | SchemeTypes.dark): void
    {
        this.spotlight.nativeElement.classList.add('active');
        const style           = this.spotlight.nativeElement.style;
        style.width           = `${window.innerWidth * 4}px`;
        style.height          = `${window.innerHeight * 4}px`;
        style.left            = `${x}px`;
        style.top             = `${y}px`;
        style.backgroundColor = scheme === 'dark' ? 'black' : 'white';

        setTimeout(() =>
        {
            style.opacity                   = '0';
            this._hemroConfigService.config = { scheme };
            setTimeout(() =>
            {
                this.spotlight.nativeElement.classList.remove('active');
                style.width   = '0';
                style.height  = '0';
                style.opacity = '1';
            }, 750);
        }, 250);
    }
}
