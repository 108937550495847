import { NgModule } from '@angular/core';
import { HemroScrollbarDirective } from '@hemro/directives/scrollbar/scrollbar.directive';

@NgModule({
    declarations: [
        HemroScrollbarDirective
    ],
    exports     : [
        HemroScrollbarDirective
    ]
})
export class HemroScrollbarModule
{
}
