<div @expandCollapse class="space-y-4 pl-6 pr-6 pb-6 flex flex-col rounded-3xl mobile-safe-area-bottom" *ngIf="show">

    <div class="handle mt-2 rounded-full bg-gray-300 self-center"></div>

    <ng-container *ngFor="let _option of options">
        <ng-container *ngTemplateOutlet="buttonTemplate; context: { option: _option }"></ng-container>
    </ng-container>

</div>

<ng-template #buttonTemplate let-option='option'>

    <button mat-flat-button color="{{ option.color }}"
            class="block w-full max-h-12 h-12 rounded-full {{ option.cssClass }}"
            (click)="close(option.value)">

        <h3 [ngClass]="{ 'text-white': option.color === 'primary' }">{{ option.name }}</h3>

    </button>

</ng-template>
