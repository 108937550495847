import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { HemroLoadingService } from '@hemro/services/loading';
import { BehaviorSubject, debounce, Subject, takeUntil, timer } from 'rxjs';


@Component({
    selector       : 'hemro-loading-bar',
    templateUrl    : './loading-bar.component.html',
    styleUrls      : ['./loading-bar.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'hemroLoadingBar',
})
export class HemroLoadingBarComponent implements OnChanges, OnInit, OnDestroy
{
    @Input() autoMode: boolean = true;
    mode$                      = new BehaviorSubject<'determinate' | 'indeterminate'>('indeterminate');
    progress$                  = new BehaviorSubject<number>(0);
    show                       = new BehaviorSubject<boolean>(false);
    show$                      = this.show.pipe(debounce(() => timer(0)));
    private _unsubscribeAll    = new Subject<never>();

    /**
     * Constructor
     */
    constructor(private _hemroLoadingService: HemroLoadingService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void
    {
        // Auto mode
        if ('autoMode' in changes) {
            // Set the auto mode in the service
            this._hemroLoadingService.setAutoMode(coerceBooleanProperty(changes.autoMode.currentValue));
        }
    }

    /**
     * On init
     */
    ngOnInit(): void
    {

        // Subscribe to the service
        this._hemroLoadingService.mode$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) =>
            {
                this.mode$.next(value);
            });

        this._hemroLoadingService.progress$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) =>
            {
                this.progress$.next(value);
            });

        this._hemroLoadingService.show$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) =>
            {
                this.show.next(value);
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null as never);
        this._unsubscribeAll.complete();
    }
}
