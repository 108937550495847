import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationGroup, Notification } from '@hemro/lib/admin';

@Component({
    selector: 'app-notification-group',
    templateUrl: './notification-group.component.html',
    styleUrls: ['./notification-group.component.scss']
})
export class NotificationGroupComponent {
    @Input() group: NotificationGroup;
    @Output() markAsRead = new EventEmitter();

    trackByFn(index: number, item: Notification): any {
        return item.notificationId || index;
    }
}
