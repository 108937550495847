import { Injectable } from '@angular/core';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { ChatFact, ChatFactFilter } from './chat-fact.model';

@Injectable({ providedIn: 'root' })
export class ChatFactService extends RestAspect {
    constructor(
        http: HttpClient,
        configService: ConfigService,
    ) {
        super(http, configService, configService.formatUrl('chatbotUrl'));
    }

    queryFacts(filter: ChatFactFilter): Observable<QueryResult<ChatFact>> {
        return super.query<ChatFact>(this.formatServiceUrl('/fact/query'), filter);
    }

    loadByKey(chatFactId: string): Observable<ChatFact>
    {
        return this.http.get<ChatFact>(`${this.formatServiceUrl()}/fact/?key=${chatFactId}`);
    }

    delete(chatFact: ChatFact): Observable<ChatFact>
    {
        return this.http.delete<ChatFact>(`${this.formatServiceUrl()}/fact/?key=` + chatFact.chatFactId);
    }

    create(chatFact: ChatFact): Observable<ChatFact>
    {
        return this.http.post<ChatFact>(this.formatServiceUrl('/fact//create'), chatFact);
    }

    merge(chatFact: ChatFact): Observable<ChatFact>
    {
        return this.http.post<ChatFact>(this.formatServiceUrl('/fact/merge'), chatFact);
    }

    put(chatFact: ChatFact): Observable<ChatFact>
    {
        return this.http.post<ChatFact>(this.formatServiceUrl('/fact/put'), chatFact);
    }

    update(chatFact: ChatFact): Observable<ChatFact>
    {
        return this.http.post<ChatFact>(this.formatServiceUrl('/fact/update'), chatFact);
    }

    publish(): Observable<void> {
        return this.http.post<void>(this.formatServiceUrl('/publish'), {});
    }

    hasStageChanges(): Observable<boolean> {
        return this.http.get<boolean>(this.formatServiceUrl('/fact/hasStageChanges'));
    }

}
