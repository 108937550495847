interface ISectionList
{
    [sesction: string]: ISection;
}

export interface IJsonDocument
{
    [sesction: string]: string | ISectionList;

    title: string;

    list: ISectionList;
}

export interface ISection
{
    title: string;
    content: string;
}

export class JsonDocumentReader
{
    title: string;
    sections = new Array<string>();

    list = new Array<ISection>();

    public constructor(json: IJsonDocument)
    {
        this.title = json.title;

        for (const _section in json) {
            if (!_section.startsWith('section')) {
                continue;
            }
            this.sections.push(json[_section] as string);
        }

        for (const _section in json.list) {
            if (!_section.startsWith('section')) {
                continue;
            }
            this.list.push(json.list[_section] as ISection);
        }
    }
}
