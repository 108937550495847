import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ManagerFilter } from '@hemro/lib/domain';
import { AppInjector } from '@yukawa/chain-base-angular-client';
import { QueryTableStore } from '@yukawa/chain-base-angular-comp/query-table';
import { IManager, Manager, ManagerTableEntry } from '../manager';
import { BaristaDatasource } from './barista-datasource.model';


export class BaristaQueryStore extends QueryTableStore<Manager, IManager, ManagerTableEntry, ManagerFilter, BaristaDatasource>
{
    public constructor(
        paginator: MatPaginator,
        sort: MatSort,
        tableFilter: ManagerFilter)
    {
        super(paginator, sort, tableFilter, ManagerTableEntry, AppInjector.get(BaristaDatasource));
    }
}
