import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceFilter } from '@hemro/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';
import { Device } from './device.entity';


@Injectable({ providedIn: 'root' })
export class DeviceService extends RestAspect
{
    constructor(
        http: HttpClient,
        configService: ConfigService,
    )
    {
        super(http, configService, configService.formatUrl('deviceUrl'));
    }

    loadByKey(deviceId: string): Observable<Device>
    {
        return this.http.get<Device>(`${this.formatServiceUrl()}/?key=${deviceId}`);
    }

    delete(device: Device): Observable<Device>
    {
        return this.http.delete<Device>(`${this.formatServiceUrl()}/?key=` + device.deviceId);
    }

    create(device: Device): Observable<Device>
    {
        return this.http.post<Device>(this.formatServiceUrl('/create'), device.toJson());
    }

    merge(device: Device): Observable<Device>
    {
        return this.http.post<Device>(this.formatServiceUrl('/merge'), device.toJson());
    }

    put(device: Device): Observable<Device>
    {
        return this.http.post<Device>(this.formatServiceUrl('/put'), device.toJson());
    }

    queryDevice(filter: DeviceFilter): Observable<QueryResult<Device>>
    {
        return super.query<Device>(this.formatServiceUrl('/query'), filter);
    }

    update(device: Device): Observable<Device>
    {
        return this.http.post<Device>(this.formatServiceUrl('/update'), device.toJson());
    }

    updateDistributorId(deviceId: string, distributorId: string): Observable<Device>
    {
        return this.http.post<Device>(this.formatServiceUrl(`/updateDistributorId?deviceId=${deviceId}&distributorId=${distributorId}`), {});
    }
}
